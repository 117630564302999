import React, { Component } from "react";
import "../AddPatient/addpatient.css";
import MaleImg from "../../Image/mars 2.png";
import FemaleImg from "../../Image/femenine.png";
import calendar from "../../Image/calendar.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Info from "../../Image/info.png";
import moment from "moment";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, Paper, Grid } from '@material-ui/core';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';

import Tooltip from "@material-ui/core/Tooltip";
export class Patientdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toogleNext: false,
      checked: false,
      raceNames: [],
      EthnicityNames: [],
      NA: false,
      firstName: "",
      lastName: "",
      middleName: "",
      dob: null,
      race: "",
      ethnicity: "",
      gender: "",
      pregnant: "",
      occupation: "",
      sampleIdentifier: "",
      mobileNumber: "",
      dialCode: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      country: "",
      state: "VI",
      street: "",
      zipCode: "",
      county: "",
      countyTemp: [],
      Statename: [],
      Countryname: [],
      StateId: "",
      prioritizedGroup: "",
      firstTest: "",
      symptomatic: "",
      hospitalized: "",
      icu: "",
      symptomaticDate: "",
      testType: "",
      primaryInsuranceCompanyName: "",
      primarySubscriberName: "",
      primaryDob: null,
      primaryMemberId: "",
      primaryGroupId: "",
      secondaryInsuranceCompanyName: "",
      secondarySubscriberName: "",
      secondaryDob: null,
      secondaryMemberId: "",
      secondaryGroupId: "",
      mediCarepolicyId: "",
      mediCareSubscriberName: "",
      disableFlag: true,
      secondaryInfo: false,
      propsFlag: false,
      searchData: {},
      showCollect: false,
      showSuccess: false,
      doberrorDate: false,
      prierrorDate: false,
      SecerrorDate: false,

      //primaryinfor
      primaryInfo: "",

      cOneNA: false,
      cOneFirstName: "",
      cOneLastName: "",
      cOneHomeNumber: "",
      cOneMobileNumber: "",
      cOneemail: "",
      cTwoNA: false,
      cTwoFirstName: "",
      cTwoLastName: "",
      cTwoHomeNumber: "",
      cTwoMobileNumber: "",
      cTwoemail: "",
      expand_one: false,
      expand_two: false,
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      let data = {
        countryCode: this.props.location.state.country,
      };

      api.Get_StateByCode_List(data, (res) => {
        if (res.statusCode === 200) {
          this.setState({
            state: "",
          });
          let allEntries = Object.entries(res.info.states);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          this.setState({
            Statename: this.props.location.state.state == null || this.props.location.state.state == "" ? [] : sortedEntries,
          });
          sortedEntries.map((item) => {
            let stateTemp = [];
            if (this.props.location.state.state.includes(",")) {
              stateTemp = this.props.location.state.state.split(",");
            } else stateTemp[0] = this.props.location.state.state;

            if (item[1].stateCode === stateTemp[0]) {
              this.setState({
                StateId: item[1].id,
                state: item[1].stateCode + "," + item[0],
              });
            }
          });
        } else {
          // toast.error(res.errorMessage);
        }
      });
    }
    this.setState({
      patientId: this.props.location.state
        ? this.props.location.state.patientId
        : "",
      firstName: this.props.location.state
        ? this.props.location.state.firstName
        : "",
      lastName: this.props.location.state
        ? this.props.location.state.lastName
        : "",
      middleName: this.props.location.state
        ? this.props.location.state.middleName
        : "",
      dob: moment(this.props.location.state)
        ? moment(this.props.location.state.dob)
        : "",
      race: this.props.location.state ? this.props.location.state.race : "",
      ethnicity: this.props.location.state
        ? this.props.location.state.ethnicity
        : "",
      gender: this.props.location.state ? this.props.location.state.gender : "",
      pregnant: this.props.location.state
        ? this.props.location.state.pregnant
        : "",
      occupation: this.props.location.state
        ? this.props.location.state.occupation
        : "",
      sampleIdentifier: this.props.location.state
        ? this.props.location.state.sampleIdentifier
        : "",
      mobileNumber: this.props.location.state
        ? this.props.location.state.mobileNumber
        : "",
      phoneCode: this.props.location.state
        ? this.props.location.state.phoneCode
        : "",
      email: this.props.location.state ? this.props.location.state.email : "",

      cOneNA: this.props.location.state
        ? this.props.location.state.contactOneEmail != "N/A"
          ? false
          : true
        : false,
      cOneFirstName: this.props.location.state ? this.props.location.state.contactOneFirstName : "",
      cOneLastName: this.props.location.state ? this.props.location.state.contactOneLastName : "",
      cOneHomeNumber: this.props.location.state
        ? this.props.location.state.contactOneHomePhone
        : "",
      cOneMobileNumber: this.props.location.state
        ? this.props.location.state.contactOneMobileNumber
        : "",
      cOneemail: this.props.location.state ? this.props.location.state.contactOneEmail : "",
      cTwoNA: this.props.location.state
        ? this.props.location.state.contactTwoEmail != "N/A"
          ? false
          : true
        : false,
      cTwoFirstName: this.props.location.state ? this.props.location.state.contactTwoFirstName : "",
      cTwoLastName: this.props.location.state ? this.props.location.state.contactTwoLastName : "",
      cTwoHomeNumber: this.props.location.state
        ? this.props.location.state.contactTwoHomePhone
        : "",
      cTwoMobileNumber: this.props.location.state
        ? this.props.location.state.contactTwoMobileNumber
        : "",
      cTwoemail: this.props.location.state ? this.props.location.state.contactTwoEmail : "",
      addressOne: this.props.location.state
        ? this.props.location.state.addressOne
        : "",
      addressTwo: this.props.location.state
        ? this.props.location.state.addressTwo
        : "",
      country: this.props.location.state
        ? this.props.location.state.country
        : "",
      city: this.props.location.state.city
        ? this.props.location.state.city
        : this.props.location.state.county,
      // state: this.props.location.state ? this.props.location.state.state : "",
      // Statename: this.props.location.state
      //   ? [
      //       this.props.location.state.state
      //         ? this.props.location.state.state
      //         : "",
      //     ]
      //   : "",
      street: this.props.location.state ? this.props.location.state.street : "",
      zipCode: this.props.location.state
        ? this.props.location.state.zipCode
        : "",
      county: this.props.location.state ? this.props.location.state.county : "",

      prioritizedGroup: this.props.location.state
        ? this.props.location.state.prioritizedGroup
        : "",
      firstTest: this.props.location.state
        ? this.props.location.state.firstTest
        : "",
      symptomatic: this.props.location.state
        ? this.props.location.state.symptomatic
        : "",
      hospitalized: this.props.location.state
        ? this.props.location.state.hospitalized
        : "",
      icu: this.props.location.state ? this.props.location.state.icu : "",
      symptomaticDate: this.props.location.state
        ? this.props.location.state.symptomaticDate
        : "",
      testType: this.props.location.state
        ? this.props.location.state.testType
        : "",
      primaryInsuranceCompanyName: this.props.location.state
        ? this.props.location.state.primaryInsuranceCompanyName != null
          ? this.props.location.state.primaryInsuranceCompanyName
          : ""
        : "",
      primarySubscriberName: this.props.location.state
        ? this.props.location.state.primarySubscriberName != null
          ? this.props.location.state.primarySubscriberName
          : ""
        : "",
      primaryDob: this.props.location.state
        ? this.props.location.state.primaryDob != null
          ? moment(this.props.location.state.primaryDob)
          : null
        : null,
      primaryMemberId: this.props.location.state
        ? this.props.location.state.primaryMemberId != null
          ? this.props.location.state.primaryMemberId
          : ""
        : "",
      primaryGroupId: this.props.location.state
        ? this.props.location.state.primaryGroupId != null
          ? this.props.location.state.primaryGroupId
          : ""
        : "",
      secondaryInsuranceCompanyName: this.props.location.state
        ? this.props.location.state.secondaryInsuranceCompanyName != null
          ? this.props.location.state.secondaryInsuranceCompanyName
          : ""
        : "",
      secondarySubscriberName: this.props.location.state
        ? this.props.location.state.secondarySubscriberName != null
          ? this.props.location.state.secondarySubscriberName
          : ""
        : "",
      secondaryDob: this.props.location.state
        ? this.props.location.state.secondaryDob != null
          ? moment(this.props.location.state.secondaryDob)
          : null
        : null,
      secondaryMemberId: this.props.location.state
        ? this.props.location.state.secondaryMemberId != null
          ? this.props.location.state.secondaryMemberId
          : ""
        : "",
      secondaryGroupId: this.props.location.state
        ? this.props.location.state.secondaryGroupId != null
          ? this.props.location.state.secondaryGroupId
          : ""
        : "",
      mediCarepolicyId: this.props.location.state
        ? this.props.location.state.mediCarepolicyId != null
          ? this.props.location.state.mediCarepolicyId
          : ""
        : "",
      mediCareSubscriberName: this.props.location.state
        ? this.props.location.state.mediCareSubscriberName
        : "",
      secondaryInfo: this.props.location.state
        ? this.props.location.state.secondaryInfo
        : "",
      NA: this.props.location.state
        ? this.props.location.state.email != "N/A"
          ? false
          : true
        : false,

      primaryInfo:
        this.props.location.state.primaryMemberId != "" ||
          this.props.location.state.primaryGroupId != "" ||
          this.props.location.state.primaryDob != null ||
          this.props.location.state.primarySubscriberName != "" ||
          this.props.location.state.primaryInsuranceCompanyName != ""
          ? true
          : false,
    });
    console.log("error state", this.props.location.state.state);

    // country dropdown
    api.Get_Country_List((res) => {
      if (res.statusCode === 200) {
        this.setState({
          Countryname: res.info,
        });
      } else {
        toast.error(res.errorMessage);
      }
    });

    this.state.Countryname.map((item) => {
      console.log("redtest", item);
    });

    api.Get_Patient_Race((res) => {
      this.setState({
        raceNames: res.info.races,
        EthnicityNames: res.info.ethinicitys,
      });
    });
  }

  expandOneOnChange() {
    this.setState({ expand_one: !this.state.expand_one });
  }
  expandTwoOnChange() {
    this.setState({ expand_two: !this.state.expand_two });
  }

  NextFun() {
    const {
      firstName,
      lastName,
      middleName,
      mobileNumber,
      dialCode,
      email,
      addressOne,
      addressTwo,
      city,
      country,
      zipCode,
      county,
      occupation,
      primaryInsuranceCompanyName,
      primarySubscriberName,
      primaryMemberId,
      primaryGroupId,
      secondaryInsuranceCompanyName,
      secondarySubscriberName,
      secondaryMemberId,
      secondaryGroupId,
      mediCarepolicyId,
      ethnicity,
      gender,
      state,
      prioritizedGroup,
      dob,
      primaryDob,
      secondaryDob,
      race,
      secondaryInfo,
      NA,
    } = this.state;
    let arrFlag = 0;
    if (firstName === "") {
      arrFlag = 1;
      toast.error("Please enter First Name");
      this.setState({
        toogleNext: false,
      });
    } else if (lastName === "") {
      arrFlag = 1;
      toast.error("Please enter Last Name");
      this.setState({
        toogleNext: false,
      });
    } else if (dob === null) {
      arrFlag = 1;
      toast.error("Please select Date of Birth");
      this.setState({
        toogleNext: false,
      });
    } else if (
      dob == "Invalid Date" ||
      moment(dob).format("yyyy") <= "1899" == true ||
      dob > new Date() == true
    ) {
      arrFlag = 1;
      toast.error("Please enter valid DOB ");
      // return 0;
    }

    // else if (this.state.doberrorDate == true || dob == "Invalid date") {
    //   arrFlag = 1;
    //   toast.error("Please enter valid DOB");
    //   this.setState({
    //     toogleNext: false,
    //   });
    // } else if (dob != null && moment(dob).format("yyyy") <= "1899") {
    //   toast.error("Please enter valid DOB");
    //   return 0;
    // }
    else if (ethnicity == "" || ethnicity === null) {
      arrFlag = 1;
      toast.error("Please select Ethnicity");
      this.setState({
        toogleNext: false,
      });
    } else if (race == "" || race === null) {
      arrFlag = 1;
      toast.error("Please select Race");
      this.setState({
        toogleNext: false,
      });
    } else if (gender === "" || gender === null) {
      arrFlag = 1;
      toast.error("Please select Gender");
      this.setState({
        toogleNext: false,
      });
    } else if (
      mobileNumber.slice(dialCode.length) === "" ||
      mobileNumber === null
    ) {
      arrFlag = 1;
      toast.error("Please enter Phone number,email or select NA");
      this.setState({
        toogleNext: false,
      });
    } else if (mobileNumber.slice(this.state.dialCode.length).length < 8) {
      arrFlag = 1;
      toast.error("Please enter valid Phone number");
      this.setState({
        toogleNext: false,
      });
    } else if (email === "" || email === null) {
      if (NA == true) {
      } else if (NA == false) {
        arrFlag = 1;
        toast.error("Please enter Email");
        this.setState({
          toogleNext: false,
        });
      }
    } else if (addressOne === "" || addressOne === null) {
      arrFlag = 1;
      toast.error("Please enter Address 1");
      this.setState({
        toogleNext: false,
      });
    } else if (country === "" || country === null) {
      arrFlag = 1;
      toast.error("Please select country");
      this.setState({
        toogleNext: false,
      });
    } else if (zipCode === "" || zipCode === null) {
      arrFlag = 1;
      toast.error("Please enter the Zipcode");
      this.setState({
        toogleNext: false,
      });
    } else if (
      zipCode.length === 0 ||
      zipCode.length < 5 ||
      zipCode.length > 7
    ) {
      arrFlag = 1;
      toast.error("Please enter valid Zipcode");
      this.setState({
        toogleNext: false,
      });
    } else if (state === "" || state === null) {
      arrFlag = 1;
      toast.error("Please select a state");
      this.setState({
        toogleNext: false,
      });
    } else if (
      (city === "" || city === null) &&
      this.state.countyTemp.length === 0
    ) {
      arrFlag = 1;
      toast.error("Please enter City");
      this.setState({
        toogleNext: false,
      });
    }
    if (arrFlag == 0) {
      this.setState({
        toogleNext: true,
      });
    }
  }

  BackFun() {
    this.setState({
      toogleNext: false,
    });
  }
  setEthnicity(event) {
    this.setState({ ethnicity: event.target.value });
  }
  OpenAddPatient() {
    this.setState({ disableFlag: false });
    let data = {
      stateId: this.state.StateId,
    };

    api.Get_City_List(data, (res) => {
      if (res.statusCode === 200) {
        let allEntries = Object.entries(res.info.cities);
        let sortedEntries = allEntries.sort((a, b) =>
          a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
        );
        this.setState({
          countyTemp: sortedEntries,
        });
      } else {
        toast.error(res.errorMessage);
      }
    });
    if (this.props.location.state.state == null || this.props.location.state.state == "") {
      let data1 = {
        countryCode: this.props.location.state.country,
      };
      api.Get_StateByCode_List(data1, (res) => {
        if (res.statusCode === 200) {
          this.setState({
            state: "",
          });
          let allEntries = Object.entries(res.info.states);
          let sortedEntries = allEntries.sort((a, b) =>
            a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
          );
          this.setState({
            Statename: sortedEntries,
          });

          sortedEntries.map((item) => {
            let stateTemp = [];
            if (this.props.location.state.state.includes(",")) {
              stateTemp = this.props.location.state.state.split(",");
            } else stateTemp[0] = this.props.location.state.state;

            if (item[1].stateCode === stateTemp[0]) {
              this.setState({
                StateId: item[1].id,
                state: item[1].stateCode + "," + item[0],
              });
            }
          });

        } else {
        }
      })
    }
  }
  handleSubmitUpdate(e) {
    const {
      firstName,
      lastName,
      middleName,
      mobileNumber,
      email,
      addressOne,
      addressTwo,
      city,
      country,
      zipCode,
      county,
      occupation,
      primaryInsuranceCompanyName,
      primarySubscriberName,
      primaryMemberId,
      primaryGroupId,
      secondaryInsuranceCompanyName,
      secondarySubscriberName,
      secondaryMemberId,
      secondaryGroupId,
      mediCarepolicyId,
      ethnicity,
      gender,
      state,
      prioritizedGroup,
      dob,
      NA,
      cOneFirstName,
      cOneLastName,
      cOneHomeNumber,
      cOneMobileNumber,
      cOneemail,
      cTwoFirstName,
      cTwoLastName,
      cTwoHomeNumber,
      cTwoMobileNumber,
      cTwoemail,
      cOneNA,
      cTwoNA,
      primaryDob,
      secondaryDob,
      race,
      secondaryInfo,
    } = this.state;
    e.preventDefault();

    let arrFlag = 0;
    if (prioritizedGroup === "" || prioritizedGroup == null) {
      arrFlag = 1;
      toast.error("Please select the Prioritized Group ");
      this.setState({
        toogleNext: true,
      });
    }
    if (
      (prioritizedGroup != "" && occupation == "") ||
      (prioritizedGroup != "" && occupation == null)
    ) {
      arrFlag = 1;
      toast.error("Please enter occupation ");
      this.setState({
        toogleNext: true,
      });
      return;
    }

    if (primaryDob) {
      if (
        primaryDob == "Invalid Date" ||
        moment(primaryDob).format("yyyy") <= "1899" == true ||
        primaryDob > new Date() == true
      ) {
        arrFlag = 1;
        toast.error("Please enter valid Primary DOB ");
        return 0;
      }
    }
    if (secondaryDob) {
      if (
        secondaryDob == "Invalid Date" ||
        moment(secondaryDob).format("yyyy") <= "1899" == true ||
        secondaryDob > new Date() == true
      ) {
        arrFlag = 1;
        toast.error("Please enter valid Secondary DOB ");
        return 0;
      }

      //new
    }
    //new
    // else if (arrFlag == 0) {

    if (arrFlag == 0) {
      let splitState = state.split(",");
      let data = {
        patientId: this.state.patientId,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        middleName: middleName,
        mobileNumber:
          this.props.location.state.mobileNumber === mobileNumber
            ? this.props.location.state.mobileNumber
            : "+" + mobileNumber,
        email: NA == true ? "N/A" : email.trim(),

        contactOneFirstName: cOneFirstName.trim(),
        contactOneLastName: cOneLastName.trim(),
        contactOneMobileNumber: cOneMobileNumber != "" ? cOneMobileNumber : "",
        contactOneHomePhone: cOneHomeNumber != "" ? cOneHomeNumber : "",
        contactOneEmail: cOneNA == true ? "N/A" : cOneemail,
        contactTwoFirstName: cTwoFirstName.trim(),
        contactTwoLastName: cTwoLastName.trim(),
        contactTwoMobileNumber: cTwoMobileNumber != "" ? cTwoMobileNumber : "",
        contactTwoHomePhone: cTwoHomeNumber != "" ? cTwoHomeNumber : "",
        contactTwoEmail: cTwoNA == true ? "N/A" : cTwoemail,

        addressOne: addressOne.trim(),
        addressTwo: addressTwo,
        city: this.state.countyTemp.length === 0 ? city.trim() : county,
        country: country,
        phoneCode: "+" + this.state.dialCode,
        zipCode: zipCode.trim(),
        county:
          this.props.location.state.county === county
            ? this.props.location.state.county
            : county,
        occupation: occupation !== null ? occupation.trim() : null,
        primaryInsuranceCompanyName: primaryInsuranceCompanyName.trim(),
        primarySubscriberName: primarySubscriberName.trim(),
        primaryMemberId: primaryMemberId.trim(),
        primaryGroupId: primaryGroupId.trim(),
        secondaryInsuranceCompanyName: secondaryInsuranceCompanyName.trim(),
        secondarySubscriberName: secondarySubscriberName.trim(),
        secondaryMemberId: secondaryMemberId.trim(),
        secondaryGroupId: secondaryGroupId.trim(),
        mediCarepolicyId: mediCarepolicyId.trim(),
        secondaryInfo: secondaryInfo,
        //radio
        ethnicity: ethnicity,
        gender: gender,
        prioritizedGroup: prioritizedGroup,
        //select
        state: splitState[0].toString(),
        race: race,
        edit: true,
        //date
        // dob:
        //   this.state.dob === this.props.location.state.dob
        //     ? this.props.location.state.dob
        //     : this.state.dob.getFullYear() +
        //       "-" +
        //       ("0" + (this.state.dob.getMonth() + 1)).slice(-2) +
        //       "-" +
        //       ("0" + this.state.dob.getDate()).slice(-2),

        dob:
          this.state.dob == null
            ? null
            : moment(this.state.dob).format("yyyy-MM-DD"),
        primaryDob:
          // this.state.primaryDob == null
          //     ? this.props.location.state.primaryDob
          // :

          this.state.primaryDob == null
            ? null
            : moment(this.state.primaryDob).format("yyyy-MM-DD"),
        // secondaryDob:
        // this.state.secondaryDob == null
        //     ? this.props.location.state.secondaryDob
        //     : moment(this.state.secondaryDob).format("yyyy-MM-DD"),

        secondaryDob:
          this.state.secondaryDob == null
            ? null
            : moment(this.state.secondaryDob).format("yyyy-MM-DD"),

        confirmationCode: this.props.location.state.confirmationCode,
      };

      this.setState({
        isLoaded: true,
      });
      api.AddpatientAPI(data, (res) => {
        if (res.statusCode === 200) {
          this.setState({
            toogleNext: true,
            showCollect: true,
            showSuccess: true,
            disableFlag: true,
          });

          this.setState({
            isLoaded: false,
          });
        } else if (res.errorCode === 400) {
          toast.error(res.errorMessage);
          this.setState({
            isLoaded: false,
            showSuccess: false,
            disableFlag: true,
          });
        }
      });
    }
  }

  // else if (arrFlag == 0) {
  //   let data = {
  //     patientId: this.state.patientId,
  //     firstName: firstName.trim(),
  //     lastName: lastName.trim(),
  //     middleName: middleName,
  //     mobileNumber:
  //       this.props.location.state.mobileNumber === mobileNumber
  //         ? this.props.location.state.mobileNumber
  //         : "+" + mobileNumber,
  //     email: NA == true ? "N/A" : email.trim(),
  //     addressOne: addressOne.trim(),
  //     addressTwo: addressTwo ? addressTwo : addressTwo,
  //     city: city.trim(),
  //     zipCode: zipCode.trim(),
  //     county:
  //       this.props.location.state.county === county
  //         ? this.props.location.state.county
  //         : county,
  //     occupation: occupation !== null ? occupation.trim() : null,
  //     primaryInsuranceCompanyName: primaryInsuranceCompanyName.trim(),
  //     primarySubscriberName: primarySubscriberName.trim(),
  //     primaryMemberId: primaryMemberId.trim(),
  //     primaryGroupId: primaryGroupId.trim(),
  //     secondaryInsuranceCompanyName: secondaryInsuranceCompanyName.trim(),
  //     secondarySubscriberName: secondarySubscriberName.trim(),
  //     secondaryMemberId: secondaryMemberId.trim(),
  //     secondaryGroupId: secondaryGroupId.trim(),
  //     mediCarepolicyId: mediCarepolicyId.trim(),
  //     secondaryInfo: secondaryInfo,
  //     //radio
  //     ethnicity: ethnicity,
  //     gender: gender,
  //     prioritizedGroup: prioritizedGroup,
  //     //select
  //     state: state.slice(0, 2),
  //     race: race,
  //     edit: true,
  //     //date
  //     dob:
  //       this.state.dob === this.props.location.state.dob
  //         ? this.props.location.state.dob
  //         : this.state.dob.getFullYear() +
  //           "-" +
  //           ("0" + (this.state.dob.getMonth() + 1)).slice(-2) +
  //           "-" +
  //           ("0" + this.state.dob.getDate()).slice(-2),
  //     primaryDob:
  //       this.state.primaryDob != null || this.state.primaryDob != ""
  //         ? this.props.location.state.primaryDob
  //         : this.state.primaryDob.getFullYear() +
  //           "-" +
  //           ("0" + (this.state.primaryDob.getMonth() + 1)).slice(-2) +
  //           "-" +
  //           ("0" + this.state.primaryDob.getDate()).slice(-2),
  //     secondaryDob:
  //       this.state.secondaryDob == null || this.state.secondaryDob == ""
  //         ? this.props.location.state.secondaryDob
  //         : this.state.secondaryDob.getFullYear() +
  //           "-" +
  //           ("0" + (this.state.secondaryDob.getMonth() + 1)).slice(-2) +
  //           "-" +
  //           ("0" + this.state.secondaryDob.getDate()).slice(-2),
  //     confirmationCode: this.props.location.state.confirmationCode,
  //   };

  //   this.setState({
  //     isLoaded: true,
  //   });
  //   api.AddpatientAPI(data, (res) => {
  //     if (res.statusCode === 200) {
  //       this.setState({
  //         toogleNext: true,
  //         showCollect: true,
  //         showSuccess: true,
  //       });

  //       this.setState({
  //         isLoaded: false,
  //       });
  //     } else if (res.errorCode === 400) {
  //       toast.error(res.errorMessage);
  //       this.setState({
  //         isLoaded: false,
  //         showSuccess: false,
  //       });
  //     }
  //   });
  // }
  // }

  handleChangeValueprimarycheck(event) {
    const target = event.target;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    // const name = target.name;

    // this.setState({
    //   [name]: value
    // });

    this.setState({
      primaryInfo: event.target.checked,
    });

    if (event.target.checked === false) {
      this.setState({
        primaryInsuranceCompanyName: "",
        primarySubscriberName: "",
        primaryDob: null,
        primaryMemberId: "",
        primaryGroupId: "",
      });
    }
  }

  handleChangeValue(keyValue, eve) {
    if (eve.target.type != undefined && eve.target.type == "checkbox") {
      this.setState({ secondaryInfo: eve.target.checked });

      if (eve.target.checked === false) {
        this.setState({
          secondaryInsuranceCompanyName: "",
          secondarySubscriberName: "",
          secondaryDob: null,
          secondaryMemberId: "",
          secondaryGroupId: "",
        });
      }
    } else {
      if (keyValue == "race" || keyValue == "prioritizedGroup") {
        let value = eve.target.value.replaceAll('"',"'");
        this.setState({ [keyValue]: value.replaceAll("''","'") });
      } else {
        let value = eve.target.value.replaceAll('"',"'");
        this.setState({ [keyValue]: value.replaceAll("''","'") });
      }
    }
  }

  CountryChange(event) {
    this.setState({
      country: event.target.value,
      countyTemp: [],
    });

    this.state.Countryname.map((item) => {
      if (item.countryCode === event.target.value) {
        let data = {
          countryId: item.id,
        };

        // siga
        api.Get_State_List(data, (res) => {
          if (res.statusCode === 200) {
            this.setState({
              state: "",
              // county: "",
              // city: "",
            });
            let allEntries = Object.entries(res.info.states);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            this.setState({
              Statename: sortedEntries,
            });
          } else {
            toast.error(res.errorMessage);
          }
        });
      }
    });
  }

  // State and county selection
  StateChange(event) {
    if (event.target.value === "") {
      this.setState({
        countyTemp: [],
      });
    }
    let check1 = event.target.value.split(",");
    this.setState({
      state: event.target.value,
      // county: "",
      // city: "",
    });

    this.state.Statename.map((item) => {
      if (item[0] == check1[1]) {
        let data = {
          stateId: item[1].id,
        };

        // siga
        api.Get_City_List(data, (res) => {
          if (res.statusCode === 200) {
            let allEntries = Object.entries(res.info.cities);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            this.setState({
              countyTemp: sortedEntries,
            });
          } else {
            toast.error(res.errorMessage);
          }
        });
      }
    });
  }
  countyChange(event) {
    this.setState({
      county: event.target.value,
    });
  }
  ZipcodeChange(event) {
    this.setState({
      zipCode: event.target.value,
    });
  }

  cOneHomeNumberChange(number, e) {
    this.setState({ cOneHomeNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  cOneMobileNumberChange(number, e) {
    this.setState({ cOneMobileNumber: number });
    this.setState({ dialCode: e.dialCode });
  }

  cTwoHomeNumberChange(number, e) {
    this.setState({ cTwoHomeNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  cTwoMobileNumberChange(number, e) {
    this.setState({ cTwoMobileNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  mobileNumberChange(number, e) {
    this.setState({ mobileNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  dobdateChange(date, keyValue) {
    this.setState({ dob: date });
  }
  primarydateChange(date, keyValue) {
    this.setState({ primaryDob: date });
  }
  SecdateChange(date, keyValue) {
    // if (date > new Date() || date == "Invalid Date") {

    //   this.setState({ SecerrorDate: true });
    // } else if (date != null) {
    //   this.setState({ secondaryDob: date, SecerrorDate: false });
    // } else if (date == null) {
    //   this.setState({ secondaryDob: date, SecerrorDate: false });
    // }

    this.setState({ secondaryDob: date });
  }
  isParentChecked(event) {
    this.setState({
      cOneNA: event.target.checked,
    });

    if (this.state.cOneNA === true) {
      this.setState({
        cOneemail: "",
      });
    }
  }
  isParentChecked1(event) {
    this.setState({
      cTwoNA: event.target.checked,
    });

    if (this.state.cTwoNA === true) {
      this.setState({
        cTwoemail: "",
      });
    }
  }
  isChecked1(event) {
    this.setState({
      NA: event.target.checked,
    });

    if (this.state.NA === true) {
      this.setState({
        email: "",
      });
    }
  }

  backSearch(e) {
    if (localStorage.getItem("fromvalue") === "appointmentlookup") {
      this.props.history.push({
        pathname: "/testrecords",
        backstate: this.props.location.state,
      });
      localStorage.removeItem("fromvalue");
    } else if (localStorage.getItem("fromvalue") === "notification") {
      this.props.history.push({
        pathname: "/notification",
        backstate: this.props.location.state,
      });
      localStorage.removeItem("fromvalue");
    } else if (localStorage.getItem("fromvalue") === "testresult") {
      this.props.history.push({
        pathname: "/recordresult",
        backstate: this.props.location.state,
      });
      localStorage.removeItem("fromvalue");
    } else if (localStorage.getItem("fromvalue") === "collectSample") {
      this.props.history.push({
        pathname: "/collectsample",
        backstate: this.props.location.state,
      });
      localStorage.removeItem("fromvalue");
    } else if (localStorage.getItem("fromvalue") === "patientSearch") {
      this.props.history.push({
        pathname: "/patientsearch",
        backstate: this.props.location.state,
      });
      localStorage.removeItem("fromvalue");
    }
  }
  cancelEdit(e) {
    this.setState({ disableFlag: true });
  }

  sendCollectSample() {
    this.props.history.push({
      pathname: "/collectsample",
      state: this.props.location.state.patientId,
    });
  }
  render() {
    if (this.state.propsFlag == false && this.props.location.obj != undefined) {
      this.setState({ searchData: this.props.location.obj });
      this.setState({ propsFlag: true });
    }
    return (
      <>
        <ToastContainer autoClose={2000} />
        {this.state.isLoaded == true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div id="addPatient">
            {this.state.toogleNext === false ? (
              <div>
                {this.state.disableFlag == true ? (
                  <div>
                    <div className="patientTitle">Patient Information</div>
                    <div
                      onClick={this.OpenAddPatient.bind(this)}
                      className="editcancelbtn"
                    >
                      {" "}
                      Edit
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="patientTitle">Edit Patient Information</div>
                    <div
                      onClick={this.cancelEdit.bind(this)}
                      className="editcancelbtn"
                    >
                      {" "}
                      Cancel
                    </div>
                  </div>
                )}
                {/* input field */}
                <div className="addInput firstRowAdd">
                  <div>
                    <label className="ethnicit">First Name *</label>
                    <br />
                    <input
                      type="text"
                      className="PatientInput"
                      name="firstName"
                      disabled={this.state.disableFlag == true ? true : false}
                      value={this.state.firstName}
                      onChange={this.handleChangeValue.bind(this, "firstName")}
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Middle Initial</label>
                    <br />
                    <input
                      type="text"
                      onChange={this.handleChangeValue.bind(this, "middleName")}
                      className="PatientInput"
                      name="middleName"
                      disabled={this.state.disableFlag == true ? true : false}
                      value={this.state.middleName}
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Last Name *</label>
                    <br />
                    <input
                      type="text"
                      className="PatientInput"
                      name="lastName"
                      disabled={this.state.disableFlag == true ? true : false}
                      value={this.state.lastName}
                      onChange={this.handleChangeValue.bind(this, "lastName")}
                    />
                  </div>
                </div>

                <div className="addInput secondRowAdd">
                  <div className="datepickerstyleAdder">
                    <label className="ethnicit">Date of Birth *</label>
                    <br />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        clearable
                        placeholder="MM/DD/YYYY"
                        // value={moment(this.state.dob).format("MMM/DD/yyyy")}

                        value={this.state.dob}
                        format="MM/dd/yyyy"
                        maxDate={new Date()}
                        required
                        disabled={this.state.disableFlag == true ? true : false}
                        onChange={(date) => {
                          this.dobdateChange(date, "dob");
                        }}
                        autoOk
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    {localStorage.getItem("id") === "16" ||
                      localStorage.getItem("id") === "17" ? (
                      <div>
                        <label className="ethnicit">Gender *</label>
                        <br />
                        <select
                          className="PatientSelect"
                          value={this.state.gender}
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          onChange={this.handleChangeValue.bind(this, "gender")}
                        >
                          <option value="select gender">Gender *</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Transgender Male to Female">
                            Transgender Male to Female
                          </option>
                          <option value="Transgender Female to Male">
                            Transgender Female to Male
                          </option>
                          <option value="Transgender Unspecified">
                            Transgender Unspecified
                          </option>
                          <option value="Declined to Answer">
                            Declined to Answer
                          </option>
                          <option value="Another Gender">Another Gender</option>
                        </select>
                      </div>
                    ) : (
                      <div>
                        <label className="ethnicit">Gender *</label>
                        <br />
                        <select
                          className="PatientSelect"
                          value={this.state.gender}
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          onChange={this.handleChangeValue.bind(this, "gender")}
                        >
                          <option value="select gender">Gender *</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Ambiguous">Non-binary</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>

                <div className="addInput thirdRowAdd">
                  <div>
                    <label className="ethnicit">Race * </label>
                    <div>
                      <select
                        className="PatientSelect"
                        value={this.state.race}
                        disabled={this.state.disableFlag == true ? true : false}
                        onChange={this.handleChangeValue.bind(this, "race")}
                      >
                        <option value="" disabled>
                          Race
                        </option>
                        {this.state.raceNames.map((index) => (
                          <option value={index.raceName}>
                            {index.raceName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <label className="ethnicit">Ethnicity *</label>
                    <br />
                    <div>
                      <select
                        className="PatientSelect"
                        onChange={this.setEthnicity.bind(this)}
                        disabled={this.state.disableFlag == true ? true : false}
                        value={this.state.ethnicity}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {this.state.EthnicityNames.map((index) => (
                          <option value={index.ethinicityName}>
                            {index.ethinicityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="addInput fourthRowAdd">
                  <div>
                    <label className="ethnicit">Mobile Number * </label>
                    <PhoneInput
                      // country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      disabled={this.state.disableFlag == true ? true : false}
                      onChange={this.mobileNumberChange.bind(this)}
                      value={this.state.mobileNumber}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </div>

                  <div>
                    <label className="ethnicit">Email</label>
                    <br />

                    <input
                      type="text"
                      className="PatientInput"
                      disabled={
                        this.state.disableFlag == true || this.state.NA == true
                          ? true
                          : false
                      }
                      name="email"
                      value={this.state.NA == true ? "N/A" : this.state.email}
                      onChange={this.handleChangeValue.bind(this, "email")}
                    />
                  </div>
                  <div className="addInput">
                    <input
                      type="checkbox"
                      onChange={this.isChecked1.bind(this)}
                      checked={this.state.NA}
                      disabled={this.state.disableFlag == true ? true : false}
                      id="na"
                    />
                    <label for="na">N/A - Not Applicable</label>
                  </div>
                </div>
                <Accordion className="accordionOne" style={{marginTop: "20px" }}>
                  <AccordionSummary
                    onClick={this.expandOneOnChange.bind(this)}
                    expandIcon={this.state.expand_one ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ color: "#00afb9" }}>Emergency Contact 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box margin={1} style={{ width: "100%" }}>
                      <Paper elevation={4} style={{ width: "100%", padding: "20px", margin: "5px 0px" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">First Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cOneFirstName"
                              disabled={this.state.disableFlag == true ? true : false}
                              value={this.state.cOneFirstName}
                              onChange={this.handleChangeValue.bind(this, "cOneFirstName")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Last Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cOneLastName"
                              disabled={this.state.disableFlag == true ? true : false}
                              value={this.state.cOneLastName}
                              onChange={this.handleChangeValue.bind(this, "cOneLastName")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Home Phone Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cOneHomeNumberChange.bind(this)}
                              value={this.state.cOneHomeNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Mobile Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cOneMobileNumberChange.bind(this)}
                              value={this.state.cOneMobileNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Email</label>
                            <br />
                            <input
                              type="text"
                              className="PatientInput"
                              disabled={
                                this.state.disableFlag == true || this.state.cOneNA == true
                                  ? true
                                  : false
                              }
                              name="cOneemail"
                              value={this.state.cOneNA == true ? "N/A" : this.state.cOneemail}
                              onChange={this.handleChangeValue.bind(this, "cOneemail")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <input
                              type="checkbox"
                              onChange={this.isParentChecked.bind(this)}
                              checked={this.state.cOneNA}
                              id="cOneNA"
                            />
                            <label for="cOneNA">N/A - Not Applicable</label>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="accordionTwo" style={{ marginTop: "15px" }}>
                  <AccordionSummary
                    onClick={this.expandTwoOnChange.bind(this)}
                    expandIcon={this.state.expand_two ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ color: "#00afb9" }}>Emergency Contact 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box margin={1} style={{ width: "100%" }}>
                      <Paper elevation={4} style={{ width: "100%", padding: "20px", margin: "5px 0px" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">First Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cTwoFirstName"
                              disabled={this.state.disableFlag == true ? true : false}
                              value={this.state.cTwoFirstName}
                              onChange={this.handleChangeValue.bind(this, "cTwoFirstName")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Last Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cTwoLastName"
                              disabled={this.state.disableFlag == true ? true : false}
                              value={this.state.cTwoLastName}
                              onChange={this.handleChangeValue.bind(this, "cTwoLastName")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Home Phone Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cTwoHomeNumberChange.bind(this)}
                              value={this.state.cTwoHomeNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Mobile Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cTwoMobileNumberChange.bind(this)}
                              value={this.state.cTwoMobileNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Email</label>
                            <br />
                            <input
                              type="text"
                              className="PatientInput"
                              disabled={
                                this.state.disableFlag == true || this.state.cTwoNA == true
                                  ? true
                                  : false
                              }
                              name="cTwoemail"
                              value={this.state.cTwoNA == true ? "N/A" : this.state.cTwoemail}
                              onChange={this.handleChangeValue.bind(this, "cTwoemail")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <input
                              type="checkbox"
                              onChange={this.isParentChecked1.bind(this)}
                              checked={this.state.cTwoNA}
                              id="cTwoNA"
                            />
                            <label for="cTwoNA">N/A - Not Applicable</label>
                          </Grid>
                        </Grid>

                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <div className="addInput fifthRowAdd">
                  <div>
                    <label className="ethnicit">Address 1 *</label>
                    <br />
                    <input
                      type="text"
                      className="PatientInput"
                      disabled={this.state.disableFlag == true ? true : false}
                      name="addressOne"
                      value={this.state.addressOne}
                      onChange={this.handleChangeValue.bind(this, "addressOne")}
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Address 2</label>
                    <br />
                    <input
                      disabled={this.state.disableFlag == true ? true : false}
                      type="text"
                      className="PatientInput"
                      name="addressTwo"
                      value={this.state.addressTwo}
                      onChange={this.handleChangeValue.bind(this, "addressTwo")}
                    />
                  </div>
                </div>

                <div className="addInput sixthRowAdd">
                  <div>
                    <label className="ethnicit">Country *</label>

                    <select
                      className="PatientSelect"
                      onChange={this.CountryChange.bind(this)}
                      // onKeyUp={this.EnterKeynext.bind(this)}
                      disabled={this.state.disableFlag == true ? true : false}
                      value={this.state.country}
                    >
                      <option value="">Select Country</option>
                      {this.state.Countryname.map((item) => (
                        <option value={item.countryCode}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="ethnicit"> State / Territory *</label>
                    <br />

                    <select
                      className="PatientSelect"
                      value={this.state.state}
                      disabled={this.state.disableFlag == true ? true : false}
                      onChange={this.StateChange.bind(this)}
                    >
                      <option
                        value="race"
                        disabled={this.state.disableFlag == true ? true : false}
                      >
                        Select State / Territory
                      </option>
                      {this.state.Statename.length &&
                        this.state.Statename.map((item) => (
                          <option value={item[1].stateCode + "," + item[0]}>
                            {item[0]}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                <div className="addInput seventhRowAdd">
                  <div>
                    <label className="ethnicit">Zip Code / Postal Code *</label>
                    <br />

                    <input
                      type="text"
                      disabled={this.state.disableFlag == true ? true : false}
                      className="PatientInput"
                      name="zipCode"
                      value={this.state.zipCode}
                      onChange={this.ZipcodeChange.bind(this)}
                    />
                  </div>
                  {this.state.countyTemp.length !== 0 ? (
                    <div>
                      <label className="ethnicit">County / district *</label>
                      <br />

                      <select
                        className="PatientSelect"
                        onChange={this.countyChange.bind(this)}
                        value={this.state.county}
                        disabled={
                          this.state.disableFlag === true ? true : false
                        }
                      >
                        <option value="">County / district</option>
                        {this.state.countyTemp ? (
                          this.state.countyTemp.map((item) => (
                            <option value={item[0]}>{item[0]}</option>
                          ))
                        ) : (
                          <option></option>
                        )}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <label className="ethnicit">City / Estate *</label>

                      <input
                        type="text"
                        className="PatientInput"
                        name="city"
                        onChange={this.handleChangeValue.bind(this, "city")}
                        disabled={
                          this.state.disableFlag === true ? true : false
                        }
                        // onKeyUp={this.EnterKeynext.bind(this)}
                        value={this.state.city}
                      />
                    </div>
                  )}
                </div>

                {/* <div className="addInput"></div> */}

                <div>
                  <div className="addPButtonAdja">
                    <div>
                      <button
                        className="NextBtn"
                        onClick={this.backSearch.bind(this)}
                      >
                        Back
                      </button>
                    </div>

                    <div>
                      <button
                        className="NextBtn"
                        onClick={this.NextFun.bind(this)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {this.state.disableFlag == true ? (
                  <div>
                    <div className="patientTitle">Patient Information</div>
                    <div
                      className="editcancelbtn"
                      onClick={this.OpenAddPatient.bind(this)}
                    >
                      {" "}
                      Edit
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="patientTitle">Edit Patient Information</div>
                    <div
                      className="editcancelbtn"
                      onClick={this.cancelEdit.bind(this)}
                    >
                      {" "}
                      Cancel
                    </div>
                  </div>
                )}

                <div className="prioritized">Prioritized Group *</div>
                <div>
                  <div className="prioritizedList">
                    <div>
                      <label>
                        <input
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          type="radio"
                          value="First response/Healthcare"
                          name="prioritizedGroup"
                          checked={
                            this.state.prioritizedGroup ==
                              "First response/Healthcare"
                              ? true
                              : false
                          }
                          onChange={this.handleChangeValue.bind(
                            this,
                            "prioritizedGroup"
                          )}
                        />
                        First responder/Healthcare worker
                      </label>
                      <Tooltip
                        title="Employed as a firefighter, police officer, nurse,etc. ?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>

                    <div>
                      <label>
                        <input
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          type="radio"
                          value="Hospitality/Transportation"
                          name="prioritizedGroup"
                          checked={
                            this.state.prioritizedGroup ==
                              "Hospitality/Transportation"
                              ? true
                              : false
                          }
                          onChange={this.handleChangeValue.bind(
                            this,
                            "prioritizedGroup"
                          )}
                        />
                        Hospitality/Transportation worker
                      </label>
                      <Tooltip
                        title="Employed in a hotel, restaurant, bus line, etc. ?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="prioritizedList">
                    <div>
                      <label>
                        <input
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          type="radio"
                          value="Congregate facility"
                          name="prioritizedGroup"
                          checked={
                            this.state.prioritizedGroup == "Congregate facility"
                              ? true
                              : false
                          }
                          onChange={this.handleChangeValue.bind(
                            this,
                            "prioritizedGroup"
                          )}
                        />
                        Congregate facility worker/resident
                      </label>
                      <Tooltip
                        title="Active in an assisted living facility,shelter,prison,etc.?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>

                    <div>
                      <label>
                        <input
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          type="radio"
                          value="In-person school/daycare"
                          name="prioritizedGroup"
                          checked={
                            this.state.prioritizedGroup ==
                              "In-person school/daycare"
                              ? true
                              : false
                          }
                          onChange={this.handleChangeValue.bind(
                            this,
                            "prioritizedGroup"
                          )}
                        />
                        In-person school/daycare worker
                      </label>
                      <Tooltip
                        title="Working in a school or childcare facility?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="prioritizedList">
                  <div>
                    <label>
                      <input
                        disabled={this.state.disableFlag == true ? true : false}
                        type="radio"
                        value="None"
                        name="prioritizedGroup"
                        checked={
                          this.state.prioritizedGroup == "None" ? true : false
                        }
                        onChange={this.handleChangeValue.bind(
                          this,
                          "prioritizedGroup"
                        )}
                      />
                      Not a priority group
                    </label>
                  </div>
                  <label>
                    <input
                      disabled={this.state.disableFlag == true ? true : false}
                      type="radio"
                      value="Not reported"
                      name="prioritizedGroup"
                      checked={
                        this.state.prioritizedGroup == "Not reported"
                          ? true
                          : false
                      }
                      onChange={this.handleChangeValue.bind(
                        this,
                        "prioritizedGroup"
                      )}
                    />
                    Not reported
                  </label>

                  <div></div>
                </div>
                <div className="prioritizedList">
                  <div>
                    <label>
                      <input
                        disabled={this.state.disableFlag == true ? true : false}
                        type="radio"
                        value="Others"
                        name="prioritizedGroup"
                        checked={
                          this.state.prioritizedGroup == "Others" ? true : false
                        }
                        onChange={this.handleChangeValue.bind(
                          this,
                          "prioritizedGroup"
                        )}
                      />
                      Other Group (Please indicate below)
                    </label>
                  </div>
                </div>
                {this.state.prioritizedGroup ? (
                  <div className="addInput">
                    <div className="setFull">
                      <label className="ethnicit">
                        Occupation/Affiliation *
                      </label>
                      <br />
                      <input
                        disabled={this.state.disableFlag == true ? true : false}
                        type="text"
                        className="PatientInput"
                        name="occupation"
                        onChange={this.handleChangeValue.bind(
                          this,
                          "occupation"
                        )}
                        value={this.state.occupation}
                      />
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="prioritized heighteradj">
                  Primary Insurance Company Information
                </div>

                <div className="checkinsurance">
                  <label>
                    <input
                      type="checkbox"
                      value={this.state.primaryInfo}
                      disabled={this.state.disableFlag == true ? true : false}
                      checked={this.state.primaryInfo == true ? true : false}
                      onChange={this.handleChangeValueprimarycheck.bind(this)}
                      name="primaryInfo"
                    />
                    Add primary insurance information
                  </label>
                </div>

                {this.state.primaryInfo === true ? (
                  <>
                    <div className="addInput primaryInsuranceRow">
                      <div>
                        <label className="ethnicit">
                          Name of Insurance Company
                        </label>
                        <br />
                        <input
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          type="text"
                          className="PatientInput"
                          name="primaryInsuranceCompanyName"
                          value={this.state.primaryInsuranceCompanyName}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "primaryInsuranceCompanyName"
                          )}
                        />
                      </div>
                      <div>
                        <label className="ethnicit">Subscriber’s Name</label>
                        <br />

                        <input
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          type="text"
                          className="PatientInput"
                          name="primarySubscriberName"
                          value={this.state.primarySubscriberName}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "primarySubscriberName"
                          )}
                        />
                      </div>
                    </div>

                    <div className="addInput primaryInsuranceRow">
                      <div className="datepickerstyleAdder">
                        <label className="ethnicit">Date of Birth</label>
                        <br />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            placeholder="MM/DD/YYYY"
                            clearable
                            disabled={
                              this.state.disableFlag == true ? true : false
                            }
                            value={this.state.primaryDob}
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                            onChange={(date) => {
                              this.primarydateChange(date, "primaryDob");
                            }}
                            autoOk
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <label className="ethnicit">Member ID#</label>
                        <br />

                        <input
                          type="text"
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          className="PatientInput"
                          name="primaryMemberId"
                          value={this.state.primaryMemberId}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "primaryMemberId"
                          )}
                        />
                      </div>
                    </div>

                    <div className="addInput primaryInsuranceRow">
                      <div>
                        <label className="ethnicit">Group ID #</label>
                        <br />

                        <input
                          type="text"
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          className="PatientInput"
                          name="primaryGroupId"
                          value={this.state.primaryGroupId}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "primaryGroupId"
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : null}
                {/* <div className="prioritized">Primary Insurance Company Information</div> */}
                <div className="checkinsurance">
                  <label>
                    <input
                      type="checkbox"
                      value={this.state.secondaryInfo}
                      disabled={this.state.disableFlag == true ? true : false}
                      checked={this.state.secondaryInfo == true ? true : false}
                      onChange={this.handleChangeValue.bind(
                        this,
                        "secondaryInfo"
                      )}
                      name={this.state.secondaryInfo}
                    />
                    Add secondary insurance information
                  </label>
                </div>
                {this.state.secondaryInfo === true ? (
                  <>
                    <div className="prioritized">
                      Secondary Insurance Company Information
                    </div>

                    <div className="addInput secondaryInsuranceRow">
                      <div>
                        <label className="ethnicit">
                          Name of Insurance Company
                        </label>
                        <br />

                        <input
                          type="text"
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          className="PatientInput"
                          name="secondaryInsuranceCompanyName"
                          value={this.state.secondaryInsuranceCompanyName}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "secondaryInsuranceCompanyName"
                          )}
                        />
                      </div>
                      <div>
                        <label className="ethnicit">Subscriber’s Name</label>
                        <br />

                        <input
                          type="text"
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          className="PatientInput"
                          name="secondarySubscriberName"
                          value={this.state.secondarySubscriberName}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "secondarySubscriberName"
                          )}
                        />
                      </div>
                    </div>

                    <div className="addInput secondaryInsuranceRow">
                      <div className="datepickerstyleAdder">
                        <label className="ethnicit">Date of Birth</label>
                        <br />

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            placeholder="MM/DD/YYYY"
                            clearable
                            disabled={
                              this.state.disableFlag == true ? true : false
                            }
                            value={this.state.secondaryDob}
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                            onChange={(date) => {
                              this.SecdateChange(date, "secondaryDob");
                            }}
                            autoOk
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <label className="ethnicit">Member ID#</label>
                        <br />
                        <input
                          type="text"
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          className="PatientInput"
                          name="secondaryMemberId"
                          value={this.state.secondaryMemberId}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "secondaryMemberId"
                          )}
                        />
                      </div>
                    </div>

                    <div className="addInput secondaryInsuranceRow">
                      <div>
                        <label className="ethnicit">Group ID #</label>
                        <br />
                        <input
                          type="text"
                          disabled={
                            this.state.disableFlag == true ? true : false
                          }
                          className="PatientInput"
                          name="secondaryGroupId"
                          value={this.state.secondaryGroupId}
                          onChange={this.handleChangeValue.bind(
                            this,
                            "secondaryGroupId"
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="prioritized heighteradj">
                  Medicare Information
                </div>

                <div className="addInput">
                  <div className="setFull">
                    <label className="ethnicit">Policy ID #</label>
                    <br />

                    <input
                      type="text"
                      disabled={this.state.disableFlag == true ? true : false}
                      className="PatientInput"
                      name="mediCarepolicyId"
                      value={this.state.mediCarepolicyId}
                      onChange={this.handleChangeValue.bind(
                        this,
                        "mediCarepolicyId"
                      )}
                    />
                  </div>
                </div>

                {this.state.showSuccess === true ? (
                  <div className="showSuccessmsg">
                    Patient updated successfully
                  </div>
                ) : null}

                <div>
                  {this.state.disableFlag == true &&
                    this.state.showCollect == false ? (
                    <div className="addPButtonAdja">
                      <button
                        className="NextBtn"
                        onClick={this.BackFun.bind(this)}
                      >
                        Back
                      </button>
                    </div>
                  ) : (
                    <div>
                      {this.state.showCollect === true ? (
                        <div className="addPButtonAdja">
                          <div>
                            <button
                              className="NextBtn"
                              onClick={this.BackFun.bind(this)}
                            >
                              Back
                            </button>
                          </div>

                          {localStorage.getItem("fromvalue") !=
                            "appointmentlookup" &&
                            localStorage.getItem("fromvalue") != "testresult" &&
                            localStorage.getItem("fromvalue") !=
                            "notification" ? (
                            <div className="adderCollectS">
                              <button
                                className="NextBtn"
                                onClick={this.sendCollectSample.bind(this)}
                              >
                                Collect Sample
                              </button>
                            </div>
                          ) : (
                            ""
                          )}

                          <div>
                            <button
                              className="NextBtn"
                              onClick={this.handleSubmitUpdate.bind(this)}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="addPButtonAdja">
                            <div>
                              <button
                                className="NextBtn"
                                onClick={this.BackFun.bind(this)}
                              >
                                Back
                              </button>
                            </div>

                            <div>
                              <button
                                className="NextBtn"
                                onClick={this.handleSubmitUpdate.bind(this)}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default Patientdetails;
