import React, { Component } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backIcn from "../../Image/backIcn.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

export class EditDailyUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toogleNext: false,
      dailyupdate: [],
      testTypeStorage: JSON.parse(localStorage.getItem("testTypes")),
      //date -add
      todaydate: this.props.editdata.reportDate,
      editListData:this.props.editdata.testTypes,
      notes2: this.props.editdata.comments,

      //edit
      editdata: {},
      navigate: false,
    };

    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  handleInputChange = (e, index, field) => {
   
    if (field === "totalTests") {
      const { name, value } = e.target;
      const list = [...this.state.editListData];
      list[index][name] = value;
      this.setState({editListData:list})
      
    } else if(field === "totalPositive"){
      const { name, value } = e.target;
      const list = [...this.state.editListData];
      list[index][name] = value;
      this.setState({editListData:list})
    }
    else if(field === "totalNegative"){
      const { name, value } = e.target;
      const list = [...this.state.editListData];
      list[index][name] = value;
      this.setState({editListData:list})
    }
    else if(field === "totalQc"){
      const { name, value } = e.target;
      const list = [...this.state.editListData];
      list[index][name] = value;
      this.setState({editListData:list})
    }
    else if(field === "totalInvalid"){
      const { name, value } = e.target;
      const list = [...this.state.editListData];
      list[index][name] = value;
      this.setState({editListData:list})
    }
  };

  handleDateChange(date) {
    var finaldateDob =
      moment(date).format('yyyy-MM-DD');
    this.setState({ todaydate: date });
  }

  updateData = (e, id) => {
    e.preventDefault();
    let arr = this.state.editListData.map((item) => {
      return {
        id: item.id,
        testType: item.testType,
        totalInvalid:item.totalInvalid,
        totalNegative:  item.totalNegative,
        totalPositive: item.totalPositive,
        totalQc: item.totalQc,
        totalTests: item.totalTests,
      };
    });

    let Checkfilter_data = arr.filter(function (x) {
      if (x.testType == "") {
        return true;
      } else if (x.totalInvalid === "") {
        return true;
      } else if (x.totalNegative === "") {
        return true;
      } else if (x.totalPositive === "") {
        return true;
      } else if (x.totalQc === "") {
        return true;
      } else if (x.totalTests === "") {
        return true;
      }  
    });

let totalcheck = arr.filter(function (x) {
  if (
    Number(x.totalTests) !==
    Number(x.totalPositive) +
      Number(x.totalNegative) +
      Number(x.totalQc) +
      Number(x.totalInvalid)
  ) {
    return true;
  }
});

    if (this.state.todaydate === null) {
      toast.error("Date cannot be empty");
    } else if (this.state.editListData.length) {
      for (let i = 0; i < this.state.editListData.length; i++) {
        if (this.state.editListData[i].totalTests === "") {
          toast.error(
            "Please enter the Total No. of Tests for the test type " +
              this.state.editListData[i].testType
          );
        } else if (this.state.editListData[i].totalPositive === "") {
          toast.error(
            "Please enter the No. of positive for the test type " +
              this.state.editListData[i].testType
          );
        } else if (this.state.editListData[i].totalNegative === "") {
          toast.error(
            "Please enter the No. of negative for the test type " +
              this.state.editListData[i].testType
          );
        } else if (this.state.editListData[i].totalQc === "") {
          toast.error(
            "Please enter the No. of QC for the test type " +
              this.state.editListData[i].testType
          );
        } else if (this.state.editListData[i].totalInvalid === "") {
          toast.error(
            "Please enter the No. of Invalid for the test type " +
              this.state.editListData[i].testType
          );
        } else if (
          Number(this.state.editListData[i].totalTests) !==
          Number(this.state.editListData[i].totalPositive) +
            Number(this.state.editListData[i].totalNegative) +
            Number(this.state.editListData[i].totalQc) +
            Number(this.state.editListData[i].totalInvalid)
        ) {
          toast.error(
            "Please correct your counts to match the total number of tests completed for " +
              this.state.editListData[i].testType
          );
        }
      }
      if (Checkfilter_data.length === 0 && totalcheck.length === 0) {
        console.log(">>>>>>> API call >>>>>>>>>>");
        let finallist = arr.map((item) => {
          return {
            id: item.id,
            testType: item.testType,
            totalInvalid: Number(item.totalInvalid),
            totalNegative: Number(item.totalNegative),
            totalPositive: Number(item.totalPositive),
            totalQc: Number(item.totalQc),
            totalTests: Number(item.totalTests),
          };
        })
        
        let editdatas = {
          id: this.props.editdata.id,
          reportDate: this.state.todaydate,
          testTypes: finallist,
        };

        this.setState({
          isLoaded: true,
        });
        api.EditDailyUpdate(editdatas, (res) => {
          if (res.statusCode === 200) {
            toast.success("Updated successfully");
            this.setState({
              isLoaded: false,
            });
            this.props.handleBack();
            window.scrollTo(0, 0);
          } else if (res.errorCode === 400) {
            toast.error(res.errorMessage);
            this.setState({
              isLoaded: false,
            });
          }
        });
      }
    }
  }
  Enterupdate(evt) {
    if (evt.keyCode == 13) {
      this.updateData()
    }
  }
  render() {    
    return (
      <>
        <ToastContainer autoClose={2000} />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div>
            <h1>
              <img
                src={backIcn}
                className="backIcon"
                style={{ display: "none" }}
                onClick={this.props.handleBack.bind(this)}
                alt="backIcon"
              />
              Edit Daily Update
            </h1>
            <div className="dailyUpdateForm editable">
              <div className="dater">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    placeholder="MMM/DD/YYYY"
                    clearable
                    value={moment(this.state.todaydate).format("MMM/DD/yyyy")}
                    onChange={(date) => this.handleDateChange(date)}
                    format="MM/dd/yyyy"
                    label="Date"
                    // maxDate={new Date()}
                    required
                    disabled
                    autoOk
                    onKeyUp={this.Enterupdate.bind(this)}
                  />
                </MuiPickersUtilsProvider>
              </div>

              {this.state.editListData.map((item, i) => {
                return (
                  this.state.testTypeStorage.reduce((a, o) => (o.testName && a.push(o.testName), a), []).includes(`${item.testType}`) &&
                  <>
                    <h1 className="customH1">{item.testType}</h1>
                    <div className="binaxForm">
                      <div className="firstRow">
                        <div>
                          <label className="ethnicit22">
                            Total No.of Tests *
                          </label>
                          <input
                            type="text"
                            placeholder="Total No.of Tests *"
                            name="totalTests"
                            onChange={(e) =>
                              this.handleInputChange(e, i, "totalTests")
                            }
                            onKeyUp={this.Enterupdate.bind(this)}
                            value={item.totalTests}
                            autoComplete="off"
                          />
                        </div>

                        <div>
                          <label className="ethnicit22">
                            No. of positive *
                          </label>
                          <input
                            type="text"
                            placeholder="No. of positive *"
                            name="totalPositive"
                            onChange={(e) =>
                              this.handleInputChange(e, i, "totalPositive")
                            }
                            onKeyUp={this.Enterupdate.bind(this)}
                            value={item.totalPositive}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="secondRow">
                        <div>
                          <label className="ethnicit22">
                            No. of Negative *
                          </label>
                          <input
                            type="text"
                            placeholder="No. of Negative *"
                            name="totalNegative"
                            onChange={(e) =>
                              this.handleInputChange(e, i, "totalNegative")
                            }                         
                            onKeyUp={this.Enterupdate.bind(this)}
                            value={item.totalNegative}
                            autoComplete="off"
                          />
                        </div>

                        <div>
                          <label className="ethnicit22">No. of QC *</label>
                          <input
                            type="text"
                            placeholder="No. of QC *"
                            name="totalQc"
                            onChange={(e) =>
                              this.handleInputChange(e, i, "totalQc")
                            }                         
                            onKeyUp={this.Enterupdate.bind(this)}
                            value={item.totalQc}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="thirdRow">
                        <div>
                          <label className="ethnicit22">No. of Invalid *</label>
                          <input
                            type="text"
                            placeholder="No. of Invalid *"
                            name="totalInvalid"
                            onChange={(e) =>
                              this.handleInputChange(e, i, "totalInvalid")
                            }                         
                            onKeyUp={this.Enterupdate.bind(this)}
                            value={item.totalInvalid}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}

              <div className="fourthRow">
                  <label className="ethnicit22">Notes</label>
                  <input
                    type="text"
                    placeholder="Notes"
                    className="notesClass"
                    name="notes2"
                    onKeyUp={this.Enterupdate.bind(this)}
                    onChange={this.myChangeHandler}
                    value={this.state.notes2}
                    maxLength={200}
                  />
              </div>
              <div className="btnAdj">
                <button
                  className="subimission"
                  onClick={this.props.handleBack.bind(this)}
                >
                  Back
                </button>
                <button
                  className="subimission"
                  onClick={(e) =>
                    this.updateData( e,this.props.editdata.id)
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default EditDailyUpdate;
