import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import moment from "moment";
import { makeStyles, useTheme } from '@material-ui/core/styles';

export class index extends Component {
  testData = "";
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      appointmentdate: null,
      searchLast: "",
      notificationsatus: "",
      dob: null,
      categories: {
        Sent: false,
        Delivered: false,
        Undelivered: false,
      },

    };
  }

  componentDidMount() {
    // get Test type
    window.scrollTo(0, 0);
    if (this.props.location.backstate != null) {
      const favColors = Object.keys(this.props.location.backstate.checkStatus).filter(
        (key) => this.props.location.backstate.checkStatus[key]
      );
      this.setState({
        isLoaded: true,
        searchLast: this.props.location.backstate.checklname ? this.props.location.backstate.checklname : '',
        appointmentdate: this.props.location.backstate.checksampleCollectedDate,
        dob: this.props.location.backstate.checkdob,
        categories: this.props.location.backstate.checkStatus ? this.props.location.backstate.checkStatus : {}
      })
      let item = {
        "pageNo": this.props.location.backstate.checkpageno ? this.props.location.backstate.checkpageno : 0,
        "lastName": this.props.location.backstate.checklname ? this.props.location.backstate.checklname : '',
        "dob": this.props.location.backstate.checkdob != "" ? moment(this.props.location.backstate.checkdob).format('yyyy-MM-DD') : this.props.location.backstate.checkdob,
        "sampleCollectedDate": this.props.location.backstate.checksampleCollectedDate != "" ? moment(this.props.location.backstate.checksampleCollectedDate).format('yyyy-MM-DD') : this.props.location.backstate.checksampleCollectedDate,
        "notificationStatus": favColors ? favColors : [],

      }
      api.SMSstatus(item, (response) => {
        if (response.statusCode === 200) {
          let checkdata = {
            checklname: this.state.searchLast ? this.state.searchLast : '',
            checksampleCollectedDate: this.state.appointmentdate ? this.state.appointmentdate : "",
            checkdob: this.state.dob ? this.state.dob : "",
            checkpageno: this.props.location.backstate.checkpageno ? this.props.location.backstate.checkpageno : 0,
            checkStatus: this.state.categories ? this.state.categories : {}
          }
          let resposneCheck = response
          var attributes = Object.keys(checkdata)

          for (var i = 0; i < attributes.length; i++) {
            resposneCheck[attributes[i]] = checkdata[attributes[i]]
          }
          console.log("resposneCheck", resposneCheck)
          this.props.history.push({
            pathname: "/notificationlist",
            state: resposneCheck,
          });

        } else {
          this.setState({
            isLoaded: false,
          });
          toast.error("No data found");
        }
      })
    }

  }
  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          isLoaded: false,
          appointmentdate: null,
          searchLast: "",
          notificationsatus: "",
          dob: null,
          categories: {
            Sent: false,
            Delivered: false,
            Undelivered: false,
          },
        });
      }
    } else {
    }
  }


  Searchtable() {
    const favColors = Object.keys(this.state.categories).filter(
      (key) => this.state.categories[key]
    );
    if (this.state.dob == "Invalid Date" || moment(this.state.dob).format("yyyy") <= "1899" == true || (this.state.dob) > (new Date()) == true) {
      toast.error("Please enter valid DOB");
      return 0;
    }
    if (this.state.appointmentdate == "Invalid Date" || moment(this.state.appointmentdate).format("yyyy") <= "1899" == true || (this.state.appointmentdate) > (new Date()) == true) {
      toast.error("Please enter valid Appointment date");
      return 0;
    }
    else if (
      this.state.dob === null && this.state.searchLast == "" && this.state.appointmentdate === null && favColors.length == 0) {
      toast.error("Please enter one of the search field");
      return 0;
    }

    else {
      let data = {
        "pageNo": 0,
        "dob": this.state.dob != null ? moment(this.state.dob).format('yyyy-MM-DD') : this.state.dob,
        "lastName": this.state.searchLast,
        "sampleCollectedDate": this.state.appointmentdate != null ? moment(this.state.appointmentdate).format('yyyy-MM-DD') : this.state.appointmentdate,
        "notificationStatus": favColors,
      };

      this.setState({
        isLoaded: true,
      });
      api.SMSstatus(data, (response) => {
        if (response.statusCode === 200) {
          let checkdata = {
            checklname: this.state.searchLast ? this.state.searchLast : '',
            checksampleCollectedDate: this.state.appointmentdate ? this.state.appointmentdate : "",
            checkdob: this.state.dob ? this.state.dob : "",
            checkStatus: this.state.categories ? this.state.categories :{}
          }
          let resposneCheck = response
          var attributes = Object.keys(checkdata)

          for (var i = 0; i < attributes.length; i++) {
            resposneCheck[attributes[i]] = checkdata[attributes[i]]
          }
          console.log("resposneCheck", resposneCheck)
          this.props.history.push({
            pathname: "/notificationlist",
            state: resposneCheck,
          });

        } else {
          this.setState({
            isLoaded: false,
          });
          toast.error("No data found");
        }
      });
    }
  }

  EnterKeySearch(evt) {
    if (evt.keyCode == 13) {
      this.Searchtable();
    }
  }

  handleDateChange(date) {
    this.setState({ dob: date });
  }
  handleDateChange1(date) {
    this.setState({ appointmentdate: date });
  }
  onChangeShortness = (event) => {
    const { name, checked } = event.target;

    this.setState((prevState) => {
      const categories = prevState.categories;
      categories[name] = checked;
      return categories;
    });

  };
  
  render() {
    return (
      <>
        {/* Loader */}
        <ToastContainer autoClose={2000} />

        {/* Loader state */}
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          // Search patient div
          <div className="collectContainer">
            <div className="searcher adjSearchMargin">
              <div>
                <h1>Search Notifications</h1>
              </div>
            </div>

            <div className="">
              <div className="DisplayIte">


                <div className="dater datepickerstyle1" style={{ marginTop: '18px' }}>
                  <label className="ethnicit">Date of Birth (MM/DD/YYYY)</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      clearable
                      error={true}
                      value={this.state.dob}
                      onChange={(date) => this.handleDateChange(date)}
                      label=""
                      maxDate={new Date()}
                      placeholder="MM/DD/YYYY"
                      format="MM/dd/yyyy"
                      required
                      autoOk
                      onKeyUp={this.EnterKeySearch.bind(this)}
                    />
                  </MuiPickersUtilsProvider>

                </div>

                <div style={{ marginTop: '19px', width: '50%', marginLeft: '-4px' }}>
                  <label className="ethnicit">Last Name</label>
                  <input type="text"
                    value={this.state.searchLast}
                    style={{ marginTop: '15px' }}
                    onKeyUp={this.EnterKeySearch.bind(this)}
                    onChange={(e) => {
                      let value = e.target.value.replaceAll('"', "'")
                      this.setState({ searchLast: value.replaceAll("''","'") }) 
                    }} />
                </div>


              </div>

              <div className="DisplayIte" style={{ marginTop: '10px' }}>


                <div className="dater datepickerstyle1" style={{ marginTop: '18px' }}>
                  <label className="ethnicit">Appointment Date (MM/DD/YYYY)</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      clearable
                      error={true}
                      value={this.state.appointmentdate}
                      onChange={(date) => this.handleDateChange1(date)}
                      label=""
                      maxDate={new Date()}
                      placeholder="MM/DD/YYYY"
                      format="MM/dd/yyyy"
                      required
                      autoOk
                      onKeyUp={this.EnterKeySearch.bind(this)}
                    />
                  </MuiPickersUtilsProvider>

                </div>

                <div style={{ marginTop: '18px', width: '44%', marginLeft: '-4px' }} className="innerselect1">
                  <label className="ethnicit">Notification Status</label> <br />
                  <div style={{ display: "flex", justifyContent: 'space-between', marginTop: "38px" }}>
                    <div>
                      <label>
                        <input
                          checked={this.state.categories.Sent}
                          onChange={this.onChangeShortness}
                          type="checkbox"
                          onKeyUp={this.EnterKeySearch.bind(this)}
                          name="Sent"
                        />
                            Sent
                            </label>
                    </div>

                    <div >
                      <label>
                        <input
                          checked={this.state.categories.Delivered}
                          onChange={this.onChangeShortness}
                          type="checkbox"
                          onKeyUp={this.EnterKeySearch.bind(this)}
                          name="Delivered"
                        />
                              Delivered
                            </label>
                    </div>

                    <div >
                      <label>
                        <input
                          checked={this.state.categories.Undelivered}
                          onKeyUp={this.EnterKeySearch.bind(this)}
                          onChange={this.onChangeShortness}
                          type="checkbox"
                          name="Undelivered"
                        />
                              Undelivered
                            </label>
                    </div>
                  </div>





                  {/* <FormControl >
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={this.state.smsarray}
                      onChange={this.handleChangesms.bind(this)}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {this.state.smsoptions.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}

                </div>

              </div>

              {/* <div style={{ marginTop: '30px', width: '44%', marginLeft: '-4px' }} className="innerselect">
                <label className="ethnicit">Email Status</label><br />
                <FormControl >
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={this.state.emailarray}
                    onChange={this.handleChangeEmail.bind(this)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {this.state.emailoptions.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}


              <div className="btnFix">
                <button
                  className="subBtn1"
                  onClick={this.Searchtable.bind(this)}
                >
                  Search
                  </button>
              </div>
            </div>

          </div>
        )}
      </>
    );
  }
}

export default index;
