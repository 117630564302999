import React, { Component } from "react";
import "./dailyUpdate.css";
import dailyadd from "../../Image/dailyAdd.png";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import api from "../../Service/API/index.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../Image/edit.png";
import EditDailyUpdate from "./EditDailyUpdate.js";
import backIcn from "../../Image/backIcn.png";
import moment from "moment";

export class DailyUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toogleNext: false,
      dailyupdate: [],
      showEditComp: false,
      errorDate: false,
      //date -add
      todaydate: new Date(),

      //AddList
      addDataList: [],
      showNotes:[],
      notes2: "",
      //edit
      editdata: {},

      //load
      isLoaded: false,
      testTypeStorage: JSON.parse(localStorage.getItem("testTypes")),
      mainid: "",
      reportExist: "",
      lengther: "",
    };

    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          todaydate: new Date(),
          notes2: "",
          toogleNext: false,
          showEditComp:false,
        });
      }
    } else {
    }
  }
  componentDidMount() {
    // this.getRaces();
    window.scrollTo(0, 0);
    this.setState({
      isLoaded: true,
    });
    this.filtered = this.state.testTypeStorage.reduce(
      (a, o) => (o.testName && a.push(o.testName), a),
      []
    );
    api.GetDailyUpdate((res) => {
      let dummyarr = [];
      res.info.map((item) => {
        item.testTypes.map((item1) => {
          this.filtered.find((item2) => {
            if (item1.testType == item2) {
              dummyarr.push(item);  
            }
          });
        });
      });
      let duplicateCheck=Array.from(new Set(dummyarr.map(JSON.stringify))).map(JSON.parse);
      
      this.setState({
        dailyupdate: [...this.state.dailyupdate, ...duplicateCheck],
        isLoaded: false,
      });
    });
  }

  handleInputChange = (e, index, field) => {
    if (field === "totalTests") {
      const { name, value } = e.target;
      const list = [...this.state.addDataList];
      list[index][name] = value;
      this.setState({ addDataList: list });
    } else if (field === "totalPositive") {
      const { name, value } = e.target;
      const list = [...this.state.addDataList];
      list[index][name] = value;
      this.setState({ addDataList: list });
    } else if (field === "totalNegative") {
      const { name, value } = e.target;
      const list = [...this.state.addDataList];
      list[index][name] = value;
      this.setState({ addDataList: list });
    } else if (field === "totalQc") {
      const { name, value } = e.target;
      const list = [...this.state.addDataList];
      list[index][name] = value;
      this.setState({ addDataList: list });
    } else if (field === "totalInvalid") {
      const { name, value } = e.target;
      const list = [...this.state.addDataList];
      list[index][name] = value;
      this.setState({ addDataList: list });
    }
  };

  NextFun() {
    this.setState({
      addDataList: [],
      showNotes:[true]
    })
    let data = {
      date: moment(this.state.todaydate).format("yyyy-MM-DD"),
    };

    api.CheckDate(data, (res) => {
      this.setState({
        reportExist: res.reportExist,
        //culp
        lengther:
          res.info.testTypes !== undefined ? res.info.testTypes.length : 0,
      });

      if (res.info.reportDate && this.state.reportExist === "y") {

        let dummynoteArray=[];
            res.info.testTypes.map((item)=>{
              this.filtered.find((item1)=>{
                if(item.testType==item1){
                  dummynoteArray.push(true)
                }else{
                  dummynoteArray.push(false)
                }
              })

            })


        this.setState({
          notes2: res.info.comments,
          showNotes:[...dummynoteArray],
          addDataList: res.info.testTypes,
          isLoaded: false,
          mainid: res.info.id,
          toogleNext: true,
        });
      } else if (this.state.reportExist === "n") {
        let dummyarray = [];
        for (let i = 0; i < this.filtered.length; i++) {
          let data = {
            id: "",
            testType: this.filtered[i],
            totalInvalid: "",
            totalNegative: "",
            totalPositive: "",
            totalQc: "",
            totalTests: "",
          };

          dummyarray.push(data);
        }

        this.setState({
          addDataList: dummyarray,
          isLoaded: false,
          toogleNext: true,
        });
        
      }
    });
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };

  handleDateChange(date) {
    this.setState({
      addDataList: [],
      showNotes:[]
    })
    if (date > new Date() || moment(date).format("yyyy") <= "1899") {
      this.setState({ errorDate: true, todaydate: date });
    } else {
      this.setState({
        errorDate: false,
        todaydate: date,
      });

      if (date !== null && date.toString() !== "Invalid Date") {
        let data = {
          date: moment(date).format("yyyy-MM-DD"),
        };
        this.setState({
          isLoaded: true,
        });
        api.CheckDate(data, (res) => {
          this.setState({
            reportExist: res.reportExist,
            lengther:
              res.info.testTypes !== undefined ? res.info.testTypes.length : 0,
          });
          if (res.info.reportDate && this.state.reportExist == "y") {

            let dummynoteArray=[];
            res.info.testTypes.map((item)=>{
              this.filtered.find((item1)=>{
                if(item.testType==item1){
                  dummynoteArray.push(true)
                }else{
                  dummynoteArray.push(false)
                }
              })

            })
            
            this.setState({
              addDataList: res.info.testTypes,
              showNotes:[...dummynoteArray],
              isLoaded: false,
              mainid: res.info.id,
              notes2: res.info.comments,
            });
          } else if (this.state.reportExist === "n") {
            this.setState({
              showNotes:[true]
            })
            let dummyarray = [];
            for (let i = 0; i < this.filtered.length; i++) {
              let data = {
                id: "",
                testType: this.filtered[i],
                totalInvalid: "",
                totalNegative: "",
                totalPositive: "",
                totalQc: "",
                totalTests: "",
              };

              dummyarray.push(data);
            }
            this.setState({
              addDataList: dummyarray,
              notes2: "",
              isLoaded: false,
            });
          }
          this.setState({
            isLoaded: false,
          });
        });
      }
    }
  }

  EditChange(data) {
    this.setState({
      toogleNext: true,
      showEditComp: true,
      editdata: {
        ...this.state.editdata,
        data,
      },
    });
  }

  handleBack() {
    api.GetDailyUpdate((res) => {
      let dummyarr = [];
      res.info.map((item) => {
        item.testTypes.map((item1) => {
          this.filtered.find((item2) => {
            if (item1.testType == item2) {
              dummyarr.push(item);
            }
          });
        });
      });
      let duplicateCheck=Array.from(new Set(dummyarr.map(JSON.stringify))).map(JSON.parse);
      this.setState({
        dailyupdate: [...duplicateCheck],
        isLoaded: false,
      });
    });
    this.setState({
      todaydate: new Date(),
      toogleNext: false,
      showEditComp: false,
      showNotes:[]
    });
  }

  handleSubmit = (e, id) => {
    // e.preventDefault();
    let arr = this.state.addDataList.map((item) => {
      return {
        id: item.id,
        testType: item.testType,
        totalInvalid: item.totalInvalid,
        totalNegative: item.totalNegative,
        totalPositive: item.totalPositive,
        totalQc: item.totalQc,
        totalTests: item.totalTests,
      };
    });

    let Checkfilter_data = arr.filter(function (x) {
      if (x.testType == "") {
        return true;
      } else if (x.totalInvalid === "") {
        return true;
      } else if (x.totalNegative === "") {
        return true;
      } else if (x.totalPositive === "") {
        return true;
      } else if (x.totalQc === "") {
        return true;
      } else if (x.totalTests === "") {
        return true;
      }
    });

    let totalcheck = arr.filter(function (x) {
      if (
        Number(x.totalTests) !==
        Number(x.totalPositive) +
          Number(x.totalNegative) +
          Number(x.totalQc) +
          Number(x.totalInvalid)
      ) {
        return true;
      }
    });

    if (this.state.todaydate === null||this.state.todaydate > new Date()||moment(this.state.todaydate).format("yyyy") <= "1899" ) {
      toast.error("Date cannot be empty");
    } else if (this.state.errorDate == true) {
      toast.error("Please enter valid date");
    } else if (this.state.addDataList.length) {
      for (let i = 0; i < this.state.addDataList.length; i++) {
        if (this.state.addDataList[i].totalTests === "") {
          toast.error(
            "Please enter the Total No. of Tests for the test type " +
              this.state.addDataList[i].testType
          );
        } else if (this.state.addDataList[i].totalPositive === "") {
          toast.error(
            "Please enter the No. of positive for the test type " +
              this.state.addDataList[i].testType
          );
        } else if (this.state.addDataList[i].totalNegative === "") {
          toast.error(
            "Please enter the No. of negative for the test type " +
              this.state.addDataList[i].testType
          );
        } else if (this.state.addDataList[i].totalQc === "") {
          toast.error(
            "Please enter the No. of QC for the test type " +
              this.state.addDataList[i].testType
          );
        } else if (this.state.addDataList[i].totalInvalid === "") {
          toast.error(
            "Please enter the No. of Invalid for the test type " +
              this.state.addDataList[i].testType
          );
        } else if (
          Number(this.state.addDataList[i].totalTests) !==
          Number(this.state.addDataList[i].totalPositive) +
            Number(this.state.addDataList[i].totalNegative) +
            Number(this.state.addDataList[i].totalQc) +
            Number(this.state.addDataList[i].totalInvalid)
        ) {
          toast.error(
            "Please correct your counts to match the total number of tests completed for " +
              this.state.addDataList[i].testType
          );
        }
      }
      if (Checkfilter_data.length === 0 && totalcheck.length === 0) {
        console.log(">>>>>>>Entered into API call >>>>>>>>>>");
        let filter1 = arr.map((item) => {
          return {
            testType: item.testType,
            totalInvalid: Number(item.totalInvalid),
            totalNegative: Number(item.totalNegative),
            totalPositive: Number(item.totalPositive),
            totalQc: Number(item.totalQc),
            totalTests: Number(item.totalTests),
          };
        });
        let filter2 = arr.map((item) => {
          return {
            id: item.id,
            testType: item.testType,
            totalInvalid: Number(item.totalInvalid),
            totalNegative: Number(item.totalNegative),
            totalPositive: Number(item.totalPositive),
            totalQc: Number(item.totalQc),
            totalTests: Number(item.totalTests),
          };
        });
        let addData = {
          reportDate:
            this.state.todaydate != null
              ? moment(this.state.todaydate).format("yyyy-MM-DD")
              : this.state.todaydate,
          testTypes: filter1,
          comments: this.state.notes2,
        };
        let editedData = {
          id: this.state.mainid,
          reportDate:
            this.state.todaydate != null
              ? moment(this.state.todaydate).format("yyyy-MM-DD")
              : this.state.todaydate,
          testTypes: filter2,
          comments: this.state.notes2,
        };

        if (this.state.reportExist == "n" && this.state.lengther <= 1) {
          this.setState({
            isLoaded: true,
          });
          console.log(">>>>>>>Add Data API call >>>>>>>>>>");
          api.PostDailyUpdate(addData, (res) => {
            
            if (res.statusCode === 200) {
              toast.success("Daily update added successfully");

              api.GetDailyUpdate((res) => {
                let dummyarr = [];
                res.info.map((item) => {
                  item.testTypes.map((item1) => {
                    this.filtered.find((item2) => {
                      if (item1.testType == item2) {
                        dummyarr.push(item);
                      }
                    });
                  });
                });
                let duplicateCheck=Array.from(new Set(dummyarr.map(JSON.stringify))).map(JSON.parse);
                this.setState({
                  dailyupdate: [...duplicateCheck],
                });
              });

              this.setState({
                toogleNext: false,
                isLoaded: false,
                
                notes2: "",
                todaydate: new Date(),
              });
            } else if (res.errorCode === 400) {
              this.setState({
                isLoaded: false,
              });
              toast.error(res.errorMessage);
            }
          });
        } else if (this.state.reportExist == "y") {
          this.setState({
            isLoaded: true,
          });
          console.log(">>>>>>>Update Data API call >>>>>>>>>>");
          api.EditDailyUpdate(editedData, (res) => {
            this.setState({
              isLoaded: true,
            });

            if (res.statusCode === 200) {
              toast.success("Daily update added successfully");
              api.GetDailyUpdate((res) => {
                let dummyarr = [];
                res.info.map((item) => {
                  item.testTypes.map((item1) => {
                    this.filtered.find((item2) => {
                      if (item1.testType == item2) {
                        dummyarr.push(item);
                      }
                    });
                  });
                });
                let duplicateCheck=Array.from(new Set(dummyarr.map(JSON.stringify))).map(JSON.parse);
                this.setState({
                  dailyupdate: [...duplicateCheck],
                });
              });

              this.setState({
                toogleNext: false,
                isLoaded: false,
                notes2: "",
                todaydate: new Date(),
              });
            } else if (res.errorCode === 400) {
              this.setState({
                isLoaded: false,
              });
              toast.error(res.errorMessage);
            }
          });
        }
      }
    }
  };

  EnterSubmit(evt) {
    if (evt.keyCode == 13) {
      this.handleSubmit();
    }
  }

  render() {
    return (
      <>
        <ToastContainer autoClose={2000} />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div className="dailyContainer">
            {this.state.showEditComp === true ? null : (
              <>
                <div className="dailer">
                  <div>
                    <h1>
                      {this.state.toogleNext === true ? (
                        <img
                          src={backIcn}
                          className="backIcon"
                          style={{ marginLeft: "unset", display: "none" }}
                          onClick={this.handleBack.bind(this)}
                          alt="backIcon"
                        />
                      ) : null}
                      Daily Update
                    </h1>
                  </div>

                  {this.state.toogleNext === false ? (
                    <div>
                      <img src={dailyadd} onClick={this.NextFun.bind(this)} alt="DailyAdd" />
                    </div>
                  ) : null}
                </div>
                {this.state.toogleNext === true ? (
                  <p className="infoer">
                    Please verify the following information for the daily update
                    to DOH Lab
                  </p>
                ) : null}
              </>
            )}

            {this.state.toogleNext === false ? (
              <div className="dailerDates">
              {this.state.dailyupdate.map((data) => (
                <div className="dailyData" key={data.id}>
                  {/* {data.id} */}
                  <div className="editHolder">
                    <div className="date">
                      <div>Date:</div>
                      {/* <div>{data.reportDate} </div> */}
                      <div style={{ marginLeft: "10px" }}>
                        {moment(data.reportDate).format("MM/DD/yyyy")}
                      </div>
                    </div>
                    <div>
                      <img
                        className="editicon"
                        src={edit}
                        onClick={this.EditChange.bind(this, data)}
                        alt="EditIcon"
                      />
                    </div>
                  </div>

                  {data.testTypes.map(
                    (dailydataSet) =>
                      this.filtered.includes(`${dailydataSet.testType}`) && (
                        <>
                          <div className="dailydataSet" key={dailydataSet.id}>
                            {/* {dailydataSet.id} */}
                            <div className="dailyDateTitle">
                              <div>
                                {/* <div> COVID-19:</div> */}
                                <div> {dailydataSet.testType}</div>
                              </div>

                              <div>
                                <div> Total:</div>
                                <div style={{ marginLeft: "10px" }}>
                                  {" "}
                                  {dailydataSet.totalTests}
                                </div>
                              </div>
                            </div>

                            <div className="dailyCount">
                              <div>
                                <div>Positive</div>
                                <div>{dailydataSet.totalPositive}</div>
                              </div>

                              <div>
                                <div>Negative</div>
                                <div>{dailydataSet.totalNegative}</div>
                              </div>

                              <div>
                                <div>QC</div>
                                <div>{dailydataSet.totalQc}</div>
                              </div>

                              <div>
                                <div>Invalid</div>
                                <div>{dailydataSet.totalInvalid}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                  )}
                </div>
              ))}
            </div>
            ) : (
              <>
                {this.state.showEditComp === true ? (
                  <EditDailyUpdate
                    editdata={this.state.editdata.data}
                    handleBack={this.handleBack.bind(this)}
                  />
                ) : (
                  <div className="dailyUpdateForm">
                    <div className="dater datepickerstyle" style={{ width: "54%" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          placeholder="MM/DD/YYYY"
                          clearable
                          value={this.state.todaydate}
                          onChange={(date) => this.handleDateChange(date)}
                          format="MM/dd/yyyy"
                          label="Date (MM/DD/YYYY)"
                          maxDate={new Date()}
                          required
                          autoOk
                          onKeyUp={this.EnterSubmit.bind(this)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    {this.state.addDataList &&
                      this.state.addDataList.map((item, i) => {
                        return (
                          this.state.testTypeStorage
                            .reduce(
                              (a, o) => (o.testName && a.push(o.testName), a),
                              []
                            )
                            .includes(`${item.testType}`) && (
                            <>
                              <h1 className="customH1">{item.testType}</h1>
                              <div className="binaxForm">
                                <div className="firstRow">
                                  <div>
                                    <label className="ethnicit22">
                                      Total No.of Tests *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Total No.of Tests *"
                                      name="totalTests"
                                      onChange={(e) =>this.handleInputChange(e,i,"totalTests")}
                                      onKeyUp={this.EnterSubmit.bind(this)}
                                      value={item.totalTests}
                                      autoComplete="off"
                                    />
                                  </div>

                                  <div>
                                    <label className="ethnicit22">
                                      No. of positive *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="No. of positive *"
                                      name="totalPositive"
                                      onChange={(e) =>this.handleInputChange(e,i,"totalPositive")}
                                      onKeyUp={this.EnterSubmit.bind(this)}
                                      value={item.totalPositive}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>

                                <div className="secondRow">
                                  <div>
                                    <label className="ethnicit22">
                                      No. of Negative *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="No. of Negative *"
                                      name="totalNegative"
                                      onChange={(e) =>this.handleInputChange(e,i,"totalNegative")}
                                      onKeyUp={this.EnterSubmit.bind(this)}
                                      value={item.totalNegative}
                                      autoComplete="off"
                                    />
                                  </div>

                                  <div>
                                    <label className="ethnicit22">
                                      No. of QC *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="No. of QC *"
                                      name="totalQc"
                                      onChange={(e) =>this.handleInputChange(e,i,"totalQc")}
                                      onKeyUp={this.EnterSubmit.bind(this)}
                                      value={item.totalQc}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>

                                <div className="thirdRow">
                                  <div>
                                    <label className="ethnicit22">
                                      No. of Invalid *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="No. of Invalid *"
                                      name="totalInvalid"
                                      onChange={(e) =>this.handleInputChange(e,i,"totalInvalid")}
                                      onKeyUp={this.EnterSubmit.bind(this)}
                                      value={item.totalInvalid}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        );
                      })}

                    {this.state.showNotes.includes(true)&&<><div className="fourthRow">
                      <label className="ethnicit22">Notes</label>
                      <input
                        type="text"
                        placeholder="Notes"
                        className="notesClass"
                        name="notes2"
                        onKeyUp={this.EnterSubmit.bind(this)}
                        onChange={this.myChangeHandler}
                        value={this.state.notes2}
                        maxLength={200}
                      />
                    </div>

                    <div className="btnAdj">
                      <button
                        className="subimission"
                        onClick={this.handleBack.bind(this)}
                      >
                        Back
                      </button>

                      <button
                        className="subimission"
                        onClick={this.handleSubmit.bind(this)}
                      >
                        Submit
                      </button>
                    </div></>}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

export default DailyUpdate;
