import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTableSortLabel: {
      root: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      icon: {
        fill: "#FFFFFF !important",
      },
      active: {
        color: "#FFFFFF !important",
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-of-type(odd)": {
          backgroundColor: "white",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "grey",
        },
      },
    },
    MuiTableCell: {
      head: {
        padding: "15px",
        lineHeight: "1rem",
      },
    },
    MuiTablePagination: {
      spacer: {
        flex: "none",
      },
    },
  },
});
export default theme;
