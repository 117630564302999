import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import api from "../../Service/API/index.js";
import { toast } from "react-toastify";
import { TableRow, TableCell } from "@material-ui/core";
import moment from 'moment'
function Row({ dataItems, data, fetchDetails }) {
  const {
    slotDate,
    slotTime,
    firstName,
    lastName,
    dob,
    vaccinationConfirmationCode,
    createdIn,
    sendMessageToMobile,
    mobileNumber,
    email,
    receivedVaccineDose,
    testType,
    createdDate,
    testResultId,
    appointmentStatus,
    disability,
    receivedCovid19Vaccine,
    confirmationCode,
    disabilityOthers,
    disablityOthers,
    disablity
  } = dataItems;

  const onCheckData = (id) => {
    const filterdata = data.filter((obj) => obj.testResultId === id);
    const value = {
      testResultId: filterdata[0].testResultId,
      status: "Attended",
    };

    if (value) {
      api.REPORTPOSTAPI(value, (response) => {
        if (response.statusCode === 200) {
          fetchDetails();

          toast.success(response.info);
        } else {
          toast.error(response.error);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="center">
          <Checkbox
            id={testResultId}
            color="primary"
            checked={appointmentStatus == "Attended"}
            inputProps={{ "aria-label": "secondary checkbox" }}
            disabled={appointmentStatus === "Attended"}
            onChange={() => onCheckData(testResultId)}
          />
        </TableCell>
        <TableCell align="center">{slotDate}</TableCell>
        <TableCell align="center">{slotTime}</TableCell>
        <TableCell align="center">{lastName}</TableCell>
        <TableCell align="center">{firstName}</TableCell>
        <TableCell align="center">{dob}</TableCell>
        <TableCell align="center">{mobileNumber}</TableCell>
        <TableCell align="center">{confirmationCode}</TableCell>
        <TableCell align="center">{receivedCovid19Vaccine ==="N"?"No":receivedCovid19Vaccine==="Y"?"Yes":""}</TableCell>
        <TableCell align="center">{createdIn}</TableCell>
        <TableCell align="center">{email}</TableCell>
        <TableCell align="center">{sendMessageToMobile===true?"Yes":"No"}</TableCell>
        <TableCell align="center">{createdDate?moment(createdDate).format('MM-DD-yyyy'):createdDate}</TableCell>
        <TableCell align="center">{testType}</TableCell>
      </TableRow>
      <TableRow></TableRow>
    </React.Fragment>
  );
}
    
export default Row;
