import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import SplitPane from "react-split-pane";
import PatientSearch from "../PatientSearch/index.js";
import AddPatient from "../AddPatient/addPatient.js";
import DailyUpdate from "../DailyUpdate/DailyUpdate.js";
import Resupply from "../ResupplyRequest/ResupplyRequest.js";
import CollectSample from "../CollectSample/index.js";
import RecordResult from "../Recordresult/index.js";
import PatientsDetails from "../PatientsDetails/patientdetails.js";
import "./index.css";
import Logo from "../../Image/applogo.png";
import Logotext from "../../Image/apptext.png";
import Notifications from '../Notifications/searchindex.js'
import NotificationList from '../Notifications/tableindex.js'
import FeedbackIcon from "../../Image/FeedbackIcon.png";
//add
import TestRecords from "../ConfirmationCode/index.js";
import AppointmentChanges from '../Appointmentchanges/index.js';
import Reporting from '../Reporting/TableContainer.js'
import TableComponent from '../Appointmentchanges/table.js'
const styles = {
  cursor: "col-resize",
  margin: "0px",
  height: "100%",
};

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routestate: true,
      open: false,
      id: ""
    };
  }
  render() {

    let arrayNavigation = [
      {
        name: "Patient Search",
        link: "/patientsearch",
        img: "patientsearch",
        valid: "Search Patient",
      },
      {
        name: "Appointment Lookup",
        link: "/testrecords",
        img: "appointment",
        valid: "Collect Sample",
      },
      {
        name: "Add Patient",
        link: "/addpatient",
        img: "addpatient",
        valid: "Add Patient",
      },
      {
        name: "Collect Sample",
        link: "/collectsample",
        img: "collsamGroup 10337",
        valid: "Collect Sample",
      },

      {
        name: "Test Results",
        link: "/recordresult",
        img: "recordResult",
        valid: "Test Result",
      },
      {
        name: "Daily Update",
        link: "/dailyupdate",
        img: "dailyupdate",
        valid: "Daily Update",
      },
      {
        name: "Resupply Request",
        link: "/resupply",
        img: "resupply",
        valid: "Request Test",
      },
      {
        name: "Notifications",
        link: "/notification",
        img: "smsstatus",
        valid: "Notification",
      },
      {
        name: "Request",
        link: "/resupply",
        img: "resupply",
        valid: "Test"
      },
      {
        name: "Appointment Changes",
        link: "/appointment-changes",
        img: "resupply",
        valid: "Hotline Testing Appointment"
      },
      {
        name: "Reporting",
        link: "/report",
        img: "resupply",
        valid: "Hotline Testing Reporting"
      }

    ];
    let changeValue = [];
    let changeValue1 = localStorage.getItem("features");

    if (changeValue1) {
      changeValue = changeValue1.split(",");

      if (changeValue.length > 0) {
        arrayNavigation = arrayNavigation.filter((x) => {
          if (changeValue.includes(x.valid)) {
            return x;
          }
        });
      }
    } else {
      localStorage.clear()
    window.location.replace("/");
    }
    const pathname = "";
    return (
      <div className="sideNav">

        {this.state.open === true ? (
          <div
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalBox"
          >
            <div className="modal-content">
              <div className="btnAdjHeader">
                Your form updates have not yet been submitted. Are you sure you want to exit?
              </div>
              <div className="btnAdjHeader">
                <button className="subBtn10" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>

                <button
                  className="subBtn10"
                  onClick={this.submitMenu.bind(this)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        ) : null}



        <Router>
          <SplitPane
            split="vertical"
            minSize={300}
            defaultSize={300}
            resizerStyle={styles}
          >
            <menu>
              {arrayNavigation.map((x, index) => (
                <div
                  onClick={() => {
                    this.setState({ routestate: false });
                  }}
                >
                  <NavLink
                    to={x.link}
                    exact
                    activeClassName="selected"
                    key={index}
                  >
                    <div className="menuAdj">
                      <div>
                        <img src={`/Image/${x.img}.png`} className="menuImg" />
                      </div>
                      <div>{x.name}</div>
                    </div>
                  </NavLink>
                </div>
              ))}

              
              <div className="Footermain" style={{ marginTop: '11px' }}>
                <div >
                  <img className="footerlogo" src={Logo} />
                </div>
                <div className="textDiv">
                  <p className="footerP">Powered by</p> <br />
                  <img className='textlogo' src={Logotext} />
                </div>
              </div>
            </menu>
            <div>
              <Route
                exact
                path="/patientsearch"
                render={(props) => (
                  <PatientSearch
                    routestate={this.state.routestate}
                    {...props}
                  />
                )}
              />
              <Route
                path="/addpatient"

                render={(props) => (
                  <AddPatient routestate={this.state.routestate} {...props} />
                )}
              />
              <Route
                path="/dailyupdate"
                render={(props) => (
                  <DailyUpdate routestate={this.state.routestate} {...props} />
                )}
              />
              <Route
                path="/collectsample"
                render={(props) => (
                  <CollectSample
                    routestate={this.state.routestate}
                    {...props}
                  />
                )}
              />
              <Route
                path="/recordresult"
                render={(props) => (
                  <RecordResult routestate={this.state.routestate} {...props} />
                )}
              />
              <Route
                path="/resupply"
                render={(props) => (
                  <Resupply routestate={this.state.routestate} {...props} />
                )}
              />
              <Route path="/details" exact component={PatientsDetails} />
              <Route path="/notificationlist" exact component={NotificationList} />

              <Route
                path="/testrecords"
                render={(props) => (
                  <TestRecords routestate={this.state.routestate} {...props} />
                )}
              />
              <Route
                path="/notification"
                render={(props) => (
                  <Notifications routestate={this.state.routestate} {...props} />
                )}
              />
              <Route
                path="/appointment-changes"
                render={(props) => (
                  <AppointmentChanges routestate={this.state.routestate} {...props} />
                )}
              />
              <Route
                path="/report"
                render={(props) => (
                  <Reporting routestate={this.state.routestate} {...props} />
                )}
              />
               <Route
                exact
                path="/appointmentchanges/table"
                render={(props) => (
                  <TableComponent
                    routestate={this.state.routestate}
                    {...props}
                  />
                )}
              />

            </div>
          </SplitPane>
        </Router>
        <img className="feedback" id="myCustomTrigger" onClick={()=>window.showCollectorDialog()} src={FeedbackIcon}/>
      </div>
    );
  }
}

export default SideNav;
