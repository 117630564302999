import React, { Component } from "react";
import Header from "../Header/index.js";
import OTPimg from "../../Image/success.png";
import "./index.css";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from '../Footer/Footer.js'
class roleLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      infoArray: [],
      tempData: {},
      from: "select"
    };
  }

  componentDidMount() {
    // if (localStorage.getItem('username') != "") {
    //   if (window.location.pathname == "/role") {
    //     this.setState({isLoaded:true})
    //     window.location.replace("/patientsearch");
    //   }
    // }


    let getlocal = JSON.parse(localStorage.getItem("infoArray"))
    var showInWeb = getlocal.filter(function (el) {
      return el.showInWeb == true
    });
    this.setState({
      infoArray: showInWeb,
    });


    if(localStorage.getItem("isLoggedIn") ==="true"){
      if(window.location.pathname=="/role"){
        this.setState({isLoaded:true})
        window.location.replace("/patientsearch")
      } 
    }


  }

  LabNameOnChange(e) {
    var newArray = this.state.infoArray.filter(function (el) {
      return el.labId == (e.target.value)
    });

    this.setState({
      tempData: newArray[0],
    });



  }

  SubmitRole() {
    let data = this.state.tempData;
    this.setState({
      isLoaded: true,
    });

    if (Object.keys(data).length === 0) {
      toast.error("Please select lab to proceed further");

      this.setState({
        isLoaded: false,
      });

    } else {


      api.PostOTPAPI(data, (response) => {
        if (response.statusCode === 200) {

          localStorage.setItem("messageId", (response.messageId));

          this.setState({
            isLoaded: false,
          });
          // this.props.history.push("/otp");

          this.props.history.push(
            {
              pathname: '/otp',
              state: { from: this.state.from }
            }
          )

        }
        else if (response.errorCode === 400) {
          toast.error(
            response.errorMessage
          );
          this.setState({
            isLoaded: false,
          });
        }
      });

    }

  }

  render() {
    return (
      <>
        <Header />

        <ToastContainer />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <div className="otpDiv">
              <div>
                <img src={OTPimg} className="otpSec" />
              </div>
              <div style={{ color: "#D3D7D8", marginTop: "50px" }}>
                Please choose the lab site you want to login <br />
              </div>
              <div>
                <select className="RolerSelect" onChange={this.LabNameOnChange.bind(this)}>

                  <option>Select Lab</option>
                  {this.state.infoArray !== undefined && this.state.infoArray.map((index) => (
                    <option value={index.labId}>{index.labName}</option>
                  ))}
                </select>
              </div>

              <div className="btnAdj">
                <button
                  className="subBtn1"
                  onClick={this.SubmitRole.bind(this)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: '65px' }}>
          <Footer />

        </div>
      </>
    );
  }
}

export default roleLogin;
