import React, { Component } from "react";
import "./ResupplyRequest.css";
import axios from "axios";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backIcn from "../../Image/backIcn.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';


export class ResupplyRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testType: "",
      boxCount: "",
      notes: "",
      RequestTest: true,
      TestHistory: false,
      requestHistoryTest: [],
      testOptions: [],
      labId: JSON.parse(localStorage.getItem("labdetails")).id,
      saveSuccess: false,
      isLoaded: false,
    };
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          RequestTest: true,
          TestHistory: false,
        })
      }
    }
    else {
    }
  }
  componentDidMount() {   
    let testValue = JSON.parse(localStorage.getItem("testTypes"));
    let labID = localStorage.getItem("id");    

    if (labID == 111 || labID == 70) {
      let filteredTestValue = testValue.filter((item) => item.testName !== "Sofia SARS Antigen FIA")
      console.log("Filtertestvalue", filteredTestValue)
      this.setState({
        testOptions: filteredTestValue,
        isLoaded: true,
      });
    } else {
      this.setState({
        testOptions: testValue,
        isLoaded: true,
      });
    }    
    api.getTestRequest((res) => {
      if (res.statusCode == 200) {
        this.setState({ requestHistoryTest: res.info, isLoaded: false });
      }
    });

  }
  numberOfBoxes(event) {
    this.setState({ boxCount: event.target.value });
  }
  testTypeChange(event) {
    this.setState({ testType: event.target.value });
  }
  notes(event) {
    this.setState({ notes: event.target.value });
  }
  testSubmit(stateObj, event) {

    const { testType, boxCount, notes } = this.state;

    if (testType === "") {
      toast.error("Please select test type");
    } else if (boxCount === "") {
      toast.error("Please enter Number of Boxes");
    } else if (boxCount < 1) {
      toast.error("The minimum number of boxes should be 1");
    } else if (notes.trim() === "") {
      toast.error("Please state the reason for your request");
    }

    else {
      let data = {
        testType: this.state.testType,
        noOfKits: this.state.boxCount,
        notes: this.state.notes.trim(),
        labId: this.state.labId,
      };

      if (testType !== "" && boxCount !== "" && boxCount >= 1 && notes !== "") {
        this.setState({ isLoaded: true });
        api.postResupplyRequest(data, (res) => {
          if (res.statusCode == 200) {
            this.setState({
              isLoaded: false,
              testType: "",
              boxCount: "",
              notes: "",
              saveSuccess:true

            });
          }
        });
      }
    }


  }
  openTestRequest(event) {
    this.setState({ isLoaded: true });

    api.getTestRequest((res) => {
      if (res.statusCode == 200) {
        this.setState({ requestHistoryTest: res.info, isLoaded: false });
      }
    });

    this.setState({ RequestTest: false, isLoaded: false });
    this.setState({ TestHistory: true });

  }
  backButton(event) {
    this.setState({ RequestTest: true });
    this.setState({ TestHistory: false,saveSuccess:false });
  }
  EnterKeySubmit(evt) {
    if (evt.keyCode == 13) {
      this.testSubmit()
    }
  }
  render() {
    window.scrollTo(0, 0);
    return (
      <>
        <ToastContainer autoClose={2000} />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
            <div className="ResupplyContainer">
              {this.state.RequestTest == true ? (
                <div>
                  <div className="searcher">
                    <div>
                      <h1> Request Test</h1>
                    </div>
                    <div>
                      <button
                        className="searchAgain"
                        onClick={this.openTestRequest.bind(this)}
                      >
                        Test Request History
                    </button>
                    </div>
                  </div>

                  <div className="RequestForm">
                    <div className="firstRow">

                      <div style={{ display: 'block' }}>
                        <div>
                          <label className="ethnicit">Test Type *</label>
                        </div>
                        <div>

                          <select
                            className="PatientSelect"
                            onChange={this.testTypeChange.bind(this)}
                          >
                            <option value="race">Select Test Type</option>
                            {this.state.testOptions.length > 0 ? (
                              this.state.testOptions.map((x) => (
                                <option value={x.testName}> {x.testName} </option>
                              ))
                            ) : (
                                <option value=""></option>
                              )}
                          </select>
                        </div>
                      </div>
                      <div style={{ display: 'block',marginTop:'25px' }}>
                        <div>
                          <label className="ethnicit">Number of Boxes *</label>
                        </div>
                        <div>

                        <input
                          type="number"
                          style={{width:'96%'}}
                          onChange={this.numberOfBoxes.bind(this)}
                          onKeyUp={this.EnterKeySubmit.bind(this)}
                        />
                        </div>
                      </div>
                    </div>

                    <div className="secondRow" style={{display:'block'}}>
                      <label className="ethnicit">Please state the reason for your request. To request your Standard Allocation then state “standard allocation” *</label>

                      <div>
                        <br />
                        <input
                          type="text"
                          onChange={this.notes.bind(this)}
                          maxLength="200"
                          onKeyUp={this.EnterKeySubmit.bind(this)}

                        />
                      </div>
                    </div>
                  </div>
                  {this.state.saveSuccess === true ? (
                    <div className="sampleSuccessDiv" style={{ marginTop: "5%" }}>
                      <label className="sampleSuccess">
                        Request submitted successfully
                    </label>
                    </div>
                  ) : (
                      <div></div>
                    )}
                  <div className="btnAdj">
                    <button
                      className="subimission"
                      onClick={this.testSubmit.bind(this, this.state)}
                    >
                      Submit
                  </button>
                  </div>
                </div>
              ) : (
                  <div></div>
                )}

              {this.state.TestHistory == true ? (
                <div>

                  <div className="flexer">
                    <div>
                      <h1 className="history">
                        <img
                          src={backIcn}
                          style={{ display: "none" }}
                          className="backIcon"
                          onClick={this.backButton.bind(this)}
                        />{" "}
                  Test Request History
                </h1>
                    </div>
                    <div
                      onClick={this.backButton.bind(this)} style={{ float: "right", marginTop: '-28px', marginRight: '66px', color: '#F07167', cursor: 'pointer' }}> Back
                                </div>
                    {/* <div>
                      <button className="btn" onClick={this.backButton.bind(this)}>Back</button>
                    </div> */}
                  </div>
                  {this.state.requestHistoryTest.length > 0 ? (
                    this.state.requestHistoryTest.map((x) => (
                      <div className="historyData">
                        <div className="firstRowHistory">
                          <div>
                            {x.createdDate.slice(3, 6)}{x.createdDate.slice(0, 3)}{x.createdDate.slice(6, 11)}
                          </div>
                          <div>{x.testType}</div>

                          <div>
                            <div>No.of Boxes :</div>
                            <div>{x.noOfKits}</div>
                          </div>
                        </div>

                        <div className="secondRowHistory">
                          <div>Notes:</div>
                          <div>{x.notes}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                      <div className="resupplyHis">No history found</div>
                    )}

                  {/* <div className="btnAdj2">
                <div>
                  <button className="btn" onClick={this.backButton.bind(this)}>Back</button>
                </div>

              </div> */}
                </div>
              ) : (
                  <div></div>
                )}
            </div>
          )}
      </>
    );
  }
}

export default ResupplyRequest;
