import React, { Component } from "react";
import "../Login/index.css";
import usviLogo from "../../Image/beacon.full.logo.right-type.png";
import bviLogo from "../../Image/beaconBVI.full.logo.2.right-type (2).png";
import Logo1 from "../../Image/logo1.png";
import Logo2 from "../../Image/bvi-auth.png";
import Info from "../../Image/info.png";
import api from "../../Service/API/index.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "../Footer/Footer.js";
import Tooltip from "@material-ui/core/Tooltip";

class LoginClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      RadioLoginType: "email",
      inputemail: "",
      inputmobile: "",
      dialCode: "",
      Inputvalid: false,
      from: "noselect",
    };
  }

  componentDidMount() {
    // if (localStorage.getItem('username') != "") {
    //   if (window.location.pathname == "/") {
    //     this.setState({isLoaded:true})
    //     window.location.replace("/patientsearch");
    //   }
    // }

    if (localStorage.getItem("isLoggedIn") === "true") {
      if (window.location.pathname == "/") {
        this.setState({ isLoaded: true });
        // window.location.replace("/patientsearch");
        
        if (localStorage.getItem("features").indexOf("Search Patient") >= 0) {
          window.location.replace("/patientsearch");
        } else if (localStorage.getItem("features").indexOf("Collect Sample") >= 0) {
          window.location.replace("/testrecords");
        } else if (localStorage.getItem("features").indexOf("Add Patient") >= 0) {
          window.location.replace("/addpatient");
        } else if (localStorage.getItem("features").indexOf("Test Result") >= 0) {
          window.location.replace("/recordresult");
        } else if (localStorage.getItem("features").indexOf("Daily Update") >= 0) {
          window.location.replace("/dailyupdate");
        } else if (localStorage.getItem("features").indexOf("Request Test") >= 0) {
          window.location.replace("/resupply");
        } else if (localStorage.getItem("features").indexOf("Notification") >= 0) {
          window.location.replace("/notification");
        } else if(localStorage.getItem("features").indexOf("Hotline Testing Appointment") >= 0) {
          window.location.replace("/appointment-changes");
        } else if(localStorage.getItem("features").indexOf("Hotline Testing Reporting") >= 0) {
          window.location.replace("/report");
        } else if(localStorage.getItem("features").indexOf("Test") >= 0) {
          window.location.replace("/resupply");
        } else {
          window.location.replace("/patientsearch");
        }




      }
    }
  }
  // Radio button onchange
  handleChange(event) {
    if (event.target.value == "email") {
      this.setState({
        inputmobile: "",
        RadioLoginType: event.target.value,
      });
    } else if (event.target.value == "mobile") {
      this.setState({
        inputemail: "",
        RadioLoginType: event.target.value,
      });
    }
  }
  // Email input onchange
  HandleInputChange(e) {
    this.setState({
      inputemail: e.target.value,
    });
  }
  SubmitLogin() {
    if (
      this.state.inputmobile.slice(this.state.dialCode.length) !== "" ||
      this.state.inputemail != ""
    ) {
      if (
        /^[a-zA-Z0-9_.+-\/\']+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.']+$/.test(
          this.state.inputemail
        ) == false &&
        this.state.inputemail != ""
      ) {
        toast.error("Enter the correct Email-id");
      } else {
        let data;

        if (this.state.RadioLoginType === "email") {
          data = {
            email: this.state.inputemail.trim(),
            messageCode: "",
            loggedInAt: "Web",
          };
        } else {
          data = {
            mobileNumber: "+" + this.state.inputmobile,
            messageCode: "",
            loggedInAt: "Web",
          };
        }
        this.setState({
          isLoaded: true,
        });
        api.LoginAPI(data, (response) => {
          if (response.statusCode === 200) {
            localStorage.setItem("messageId", response.messageId);

            if (response.info.length === 0) {
              this.props.history.push({
                pathname: "/otp",
                state: this.state.inputemail
                  ? this.state.inputemail
                  : this.state.inputmobile,
                state: { from: this.state.from },
              });

              this.setState({
                isLoaded: false,
              });
            } else {
              localStorage.setItem("infoArray", JSON.stringify(response.info));

              this.props.history.push({
                pathname: "/role",
                state: this.state.inputemail
                  ? this.state.inputemail
                  : this.state.inputmobile,
              });
              this.setState({
                isLoaded: false,
              });
            }
          } else if (response.errorCode === 400) {
            toast.error(response.errorMessage);
            this.setState({
              isLoaded: false,
            });
          }
        });
      }
    } else if (
      this.state.inputmobile.slice(this.state.dialCode.length) === "" ||
      this.state.inputemail === ""
    ) {
      if (this.state.RadioLoginType === "email") {
        toast.error("Please enter an email address");
      } else {
        toast.error("Please enter a Mobile Number");
      }
    }
  }
  mobileNumberChange(number, e) {
    this.setState({ inputmobile: number });
    this.setState({ dialCode: e.dialCode });
  }
  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.SubmitLogin();
    }
  }
  // ---------------------------------------------------------------------------------------------------------
  render() {
    window.scrollTo(0, 0);

    return (
      <div className="loginAdj">
        <ToastContainer />
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div className="loginBG">
            <div>
              <div>
                {window.location.hostname.includes("usvi.care") ? (
                  <img src={usviLogo} className="logoHeader" />
                ) : (
                  <img
                    src={bviLogo}
                    className="bviHeader"
                    style={{ marginTop: "10px" }}
                  />
                )}
              </div>
              <br />
            </div>

            <div className="welcome">
              <div>
                {window.location.hostname.includes("usvi.care") ? (
                  <img src={Logo1} className="logo1" />
                ) : (
                  <img src={Logo2} style={{ width: "90px" }} />
                )}
              </div>

              <h3>Welcome to the</h3>
              {window.location.hostname.includes("bvi.care") ? (
                <h4>BVI Testing Gateway</h4>
              ) : (
                <h4>DOH Testing Gateway</h4>
              )}
            </div>

            <div className="inputDiv">
              <div style={{ display: "flex", marginLeft: "1%" }}>
                <div>
                  <p>Please select your login method</p>
                </div>
                <div>
                  <Tooltip
                    title="This is where you will receive your secure code for log-in"
                    placement="top"
                  >
                    <img src={Info} className="Info" />
                  </Tooltip>
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div>
                  <input
                    type="radio"
                    value="email"
                    checked={this.state.RadioLoginType === "email"}
                    onChange={this.handleChange.bind(this)}
                    defaultChecked
                    id="email"
                  />{" "}
                  <label for="email">Email</label>
                </div>
                <div style={{ marginLeft: "50%" }}>
                  <input
                    type="radio"
                    value="mobile"
                    checked={this.state.RadioLoginType === "mobile"}
                    onChange={this.handleChange.bind(this)}
                    id="mno"
                  />{" "}
                  <label for="mno">Mobile number</label>
                </div>
              </div>

              <div>
                {this.state.RadioLoginType === "email" ? (
                  <input
                    type="text"
                    className="textInput"
                    placeholder="Enter your Email address"
                    style={{
                      width: "82%",
                      marginLeft: "1%",
                      paddingLeft: "0px",
                    }}
                    value={this.state.inputemail}
                    onChange={(e) =>
                      this.setState({ inputemail: e.target.value })
                    }
                    onKeyUp={this.SignInC.bind(this)}
                  />
                ) : (
                  <PhoneInput
                    country={"us"}
                    // onlyCountries={["us"]}
                    placeholder={"Mobile Number"}
                    // disableDropdown={true}
                    onChange={this.mobileNumberChange.bind(this)}
                    value={this.state.inputmobile}
                    onKeyUp={this.SignInC.bind(this)}
                    inputProps={{
                      autoFormat: true,
                      countryCodeEditable: true,
                    }}
                    style={{ width: "95%", marginLeft: "1%" }}
                  />
                )}
              </div>

              {this.state.Inputvalid === true &&
              this.state.RadioLoginType === "email" ? (
                <div className="validError">Enter the Email-id</div>
              ) : this.state.Inputvalid === true &&
                this.state.RadioLoginType === "mobile" ? (
                <div className="validError">Enter the Mobile number</div>
              ) : (
                ""
              )}

              
              <div className="centerAdj">
                <button
                  className="subBtn"
                  onClick={this.SubmitLogin.bind(this)}
                >
                  Submit
                </button>
              </div>
            </div>


            {window.location.hostname.includes("usvi.care") && <div style={{marginTop: "1.5em", marginLeft: "2em",
                fontFamily: 'Nunito',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '22px',
                textDecorationLne: 'underline',
              }}>
                <a href="https://beacon-landing.usvi.care" style={{color: '#007FA9'}}>School Surveillance Testing. Sign in now &gt;</a>
                </div>
              }

          </div>
        )}
        <div style={{ marginTop: "5px" }}>
          <Footer />
        </div>
      </div>
    );
  }
}
export default LoginClass;
