import React, { Component } from "react";
import "./SearchPatient.css";
import DatePicker from "react-datepicker";
import eye from "../../Image/eye.png";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../Service/API/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toogleSearch: false,
      searchFirst: "",
      searchLast: "",
      searchMobile: "",
      dob: null,
      ListPatient: "",
      propsFlag: false,
      searchData: {},
      errorDate: false,
    };
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          toogleSearch: false,
          searchFirst: "",
          searchLast: "",
          searchMobile: "",
          dob: null,
        });
      }
    }
  }
  componentDidMount() {
    console.log("this.props.location", this.props.location);
    if (this.props.location.backstate != null) {
      this.setState({
        isLoaded: true,
        searchFirst: this.props.location.backstate.checkfname,
        searchLast: this.props.location.backstate.checklname,
        searchMobile: this.props.location.backstate.checkmobile,
        dob: this.props.location.backstate.checkdob,
      });
      let item = {
        firstName: this.props.location.backstate.checkfname.replaceAll("''", "'"),
        lastName: this.props.location.backstate.checklname.replaceAll("''", "'"),
        dob:
          this.props.location.backstate.checkdob != ""
            ? moment(this.props.location.backstate.checkdob).format(
                "yyyy-MM-DD"
              )
            : this.props.location.backstate.checkdob,
        mobileNumber: this.props.location.backstate.checkmobile ===""?null:"+"+this.props.location.backstate.checkmobile,
      };
      api.SearchpatientAPI(item, (response) => {
        if (response.statusCode === 200) {
          localStorage.removeItem("redirect");

          this.setState({
            ListPatient: response.info,
            toogleSearch: true,
            isLoaded: false,
          });
        } else {
          toast.error(response.errorMessage);
          this.setState({ isLoaded: false });
        }
      });
    }
  }
  routeToogle() {
    this.setState({
      toogleSearch: false,
    });
  }
  Searchtable() {
    if (
      this.state.searchFirst.trim() == '' &&
      this.state.searchLast.trim() == '' &&
      this.state.dob == null &&
      this.state.searchMobile == ''
    ) {
      toast.error('Please enter any data to search')
    } else {
      if (this.state.dob != null) {
        if (this.state.errorDate == true) {
          toast.error('Please enter valid DOB')
          return 0
        } else if (this.state.dob != null) {
          if (
            this.state.dob.getFullYear() <= '1899' ||
            this.state.dob == 'Invalid date'
          ) {
            toast.error('Please enter valid DOB')
            return 0
          }
        }
      }
      let data = {
        firstName: this.state.searchFirst.trim().replaceAll("''", "'"),
        lastName: this.state.searchLast.trim().replaceAll("''", "'"),
        dob:
          this.state.dob != null
            ? moment(this.state.dob).format("yyyy-MM-DD")
            : this.state.dob,
        mobileNumber:
      this.state.searchMobile === "" ? null : "+" + this.state.searchMobile,
      };
      this.setState({
        searchData: data,
        isLoaded: true,
      })

      api.SearchpatientAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            ListPatient: response.info,
            toogleSearch: true,
            isLoaded: false,
          })
        } else {
          toast.error(response.errorMessage);
          this.setState({
            isLoaded: false,
          })
        }
      })
    }
  }
  handleDateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ errorDate: true });
    } else if (date != null) {
      this.setState({ dob: date, errorDate: false });
    } else {
      this.setState({ dob: null, errorDate: false });
    }
  }
  SearchAgain() {
    this.setState({
      toogleSearch: false,
    });
    this.setState({ searchFirst: "" });
    this.setState({ searchLast: "" });
    this.setState({ searchMobile: "" });
    this.setState({ dob: null });
  }
  mobileNumberChange(number) {
    this.setState({ searchMobile: number });
  }
  redirectTosample(patientId) {
    let checkdata = {
      checkfname: this.state.searchFirst ? this.state.searchFirst : "",
      checklname: this.state.searchLast ? this.state.searchLast : "",
      checkmobile: this.state.searchMobile ? this.state.searchMobile : "",
      checkdob: this.state.dob ? this.state.dob : "",
    };

    this.props.history.push({
      pathname: "/collectsample",
      state: patientId,
      redirect: checkdata,
    });
    localStorage.setItem("redirect", true);
  }
  openDetails(patientId) {
    this.setState({
      isLoaded: true,
    });
    let data = {
      patientId: patientId,
    };
    api.Getpatientdetails(data, (response) => {
      if (response.statusCode == "200") {
        let checkdata = {
          checkfname: this.state.searchFirst ? this.state.searchFirst : "",
          checklname: this.state.searchLast ? this.state.searchLast : "",
          checkmobile: this.state.searchMobile ? this.state.searchMobile : "",
          checkdob: this.state.dob ? this.state.dob : "",
        };
        let resposneCheck = response.info;
        var attributes = Object.keys(checkdata);

        for (var i = 0; i < attributes.length; i++) {
          resposneCheck[attributes[i]] = checkdata[attributes[i]];
        }
        console.log("resposneCheck", resposneCheck);
        this.props.history.push({
          pathname: "/details",
          state: resposneCheck,
        });
        localStorage.setItem("fromvalue", "patientSearch");
      } else {
      }
    });
  }
  // dataChecking(item) {
  //   this.setState({ isLoaded: true })
  //   api.SearchpatientAPI(item, (response) => {
  //     if (response.statusCode === 200) {
  //       this.setState({
  //         ListPatient: response.info,
  //         toogleSearch: true,
  //         isLoaded: false,
  //         searchData: item
  //       })
  //     }
  //     else {
  //       toast.error(response.errorMessage);
  //       this.setState({ isLoaded: false })
  //     }
  //   })
  // }
  SignInC(evt) {
    if (evt.keyCode == 13) {
      this.Searchtable();
    }
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    } else {
      return phoneNumberString;
    }
    // return null;
  }

  render() {
    window.scrollTo(0, 0);
    // if (this.state.propsFlag == false && this.props.location.backstate != undefined) {
    //   this.dataChecking(this.props.location.backstate)
    //   this.setState({ propsFlag: true })
    // }
    return (
      <>
        {/* <RouteToogle route={this.routeToogle.bind(this)} /> */}
        <ToastContainer autoClose={2000} />
        {this.state.isLoaded == true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div>
            {this.state.toogleSearch === false ? (
              <div className="patientsearchContainer">
                <div className="searcher adjSearchMargin">
                  <div>
                    <h1> Search for Patient</h1>
                  </div>
                  <div style={{ display: "none" }}>
                    <button className="searchAgain">Search Again</button>
                  </div>
                </div>

                <div className="">
                  <div className="DisplayIte">
                    <div
                      className="dater datepickerstyle1"
                      style={{ marginTop: "18px" }}
                    >
                      <label className="ethnicit">
                        Date of Birth (MM/DD/YYYY)
                      </label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          error={true}
                          value={this.state.dob}
                          onChange={(date) => this.handleDateChange(date)}
                          label=""
                          maxDate={new Date()}
                          placeholder="MM/DD/YYYY"
                          format="MM/dd/yyyy"
                          required
                          autoOk
                          onKeyUp={this.SignInC.bind(this)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div style={{ marginTop: "20px", width: "50%" }}>
                      <label className="ethnicit">Phone number</label>

                      <PhoneInput
                        country={"us"}
                        // onlyCountries={['us']}
                        // disableDropdown={true}
                        onChange={this.mobileNumberChange.bind(this)}
                        value={this.state.searchMobile}
                        onKeyUp={this.SignInC.bind(this)}
                        inputProps={{
                          autoFormat: true,
                          countryCodeEditable: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="DisplayIte">
                    <div className="patient_name">
                      <label className="ethnicit">First Name</label>

                      <input
                        type="text"
                        style={{ marginTop: "5px" }}
                        value={this.state.searchFirst}
                        onKeyUp={this.SignInC.bind(this)}
                        onChange={(e) => {
                          let value = e.target.value.replaceAll('"', "'")
                          this.setState({ searchFirst: value.replaceAll("''", "'") });
                        }}
                      />
                    </div>

                    <div className="patient_lname">
                      <label className="ethnicit">Last Name</label>
                      <input
                        type="text"
                        value={this.state.searchLast}
                        style={{ marginTop: "5px" }}
                        onKeyUp={this.SignInC.bind(this)}
                        onChange={(e) => {
                          let value = e.target.value.replaceAll('"', "'")
                          this.setState({ searchLast: value.replaceAll("''", "'") });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="btnFix">
                  <button
                    className="subBtn1"
                    onClick={this.Searchtable.bind(this)}
                  >
                    Search
                  </button>
                </div>
              </div>
            ) : (
              <div className="patientsearchContainer">
                <div className="searcher adjSearchMargin">
                  <div>
                    <h1> Search for Patient</h1>
                  </div>
                  <div></div>

                  <div>
                    <button
                      className="searchAgain"
                      onClick={this.SearchAgain.bind(this)}
                    >
                      Search Again
                    </button>
                  </div>
                </div>

                {this.state.ListPatient
                  ? this.state.ListPatient.map((item) => (
                      <div className="data">
                        <div className="firstRow">
                          <div>
                            <div>Name:</div>
                            <div
                              style={{ marginLeft: "-25px" }}
                              title={
                                item.firstName.length < 16
                                  ? ""
                                  : item.firstName + item.lastName
                              }
                            >
                              {item.firstName.length < 16
                                ? item.firstName
                                : item.firstName.slice(0, 16) + "...."}{" "}
                              {item.firstName.length < 16 ? item.lastName : ""}
                            </div>
                          </div>

                          <div>
                            <div>Email:</div>
                            <div style={{ marginLeft: "-30px" }}>
                              {item.email}
                            </div>
                          </div>

                          <div>
                            <div>Phone No:</div>
                            <div style={{ marginLeft: "0px" }}>
                              {formatPhoneNumberIntl(item.mobileNumber)}
                            </div>
                          </div>

                          <div>
                            <span
                              className="viewP"
                              onClick={this.openDetails.bind(
                                this,
                                item.patientId
                              )}
                            >
                              View Patient
                            </span>
                          </div>
                        </div>

                        <div className="secondRow">
                          <div>
                            <div>DOB:</div>
                            <div style={{ marginLeft: "-25px" }}>
                              {moment(item.dob).format("MM/DD/yyyy")}
                            </div>
                          </div>
                          {item.confirmationCode != null ? (
                            <div style={{ marginLeft: "-160px" }}>
                              <div>Confirmation code:</div>
                              <div style={{ marginLeft: "10px" }}>
                                {item.confirmationCode}{" "}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="collectSample">
                            <div
                              onClick={this.redirectTosample.bind(
                                this,
                                item.patientId
                              )}
                            >
                              Collect Sample
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : "No record"}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default index;
