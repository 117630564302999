import React, { Component } from "react";
import "./index.css";
import usviLogo from "../../Image/beacon.full.logo.right-type.png";
import bviLogo from "../../Image/beaconBVI.full.logo.2.right-type (2).png";
import profile from "../../Image/logout.png";
import close from "../../Image/close.png";


class HeaderClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  Logout() {
    localStorage.clear()
    window.location.replace("/");
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    console.log("BVI care:", window.location.hostname.includes('bvi.care'))
    console.log("USVI care:", window.location.hostname.includes('usvi.care'))

    return (
      <div>
        {this.state.open === true ? (
          <div
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modalBox"
          >
            <div className="modal-content">
              <img src={close} className="closeIcn" onClick={this.handleClose.bind(this)} />
              <div className="btnAdjHeader">
                Are you sure you want to logout?
              </div>
              <div className="btnAdjHeader">
                <button className="subBtn10" onClick={this.Logout.bind(this)}>
                  Yes
                </button>

                <button
                  className="subBtn10"
                  onClick={this.handleClose.bind(this)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="header">
          <div>
            {window.location.hostname.includes('usvi.care') ?
              <img src={usviLogo} className="logoHeader" />
              :
              <img src={bviLogo} className="bviHeader" />
            }
          </div>

          {!window.location.pathname.match(/otp/) && !window.location.pathname.match(/role/) ? (
            <>
              <div>
                <span className="facilityName">
                  {
                    JSON.parse(localStorage.getItem("labdetails")).nameDetails
                      .name
                  }
                </span>
              </div>

              <div className="floatRighter">
                <span>Welcome {localStorage.getItem("username")} !</span>

                <img
                  src={profile}
                  onClick={this.handleOpen.bind(this)}
                  className="logoutImg"
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
export default HeaderClass;
