export const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = API_URL + "/web/auth/login";
export const OTP_URL = API_URL + "/auth/otp/validate";
export const SEARCH_PATIENT = API_URL + "/patient/sample/search";
export const REPORT_URL = API_URL + "/hotline/testing/fetchData";
export const EXPORT_URL = API_URL + "/hotline/testing/exportdata";
export const POST_OTP = API_URL + "/auth/otp/send";
export const NEW_POST_OTP = API_URL + "/auth/otp/resend";

// Refresh Token
export const REFRESH_TOKEN = API_URL + "/auth/refresh/token?refreshToken=";

export const SEARCHAPP_RECORD = API_URL + "/hotline/testing/search";

//add patient
export const ADD_PATIENT = API_URL + "/patient/add";
export const GET_COUNTRY_LIST = API_URL + "/bvi/country/list";
export const GET_STATE_LIST = API_URL + "/bvi/state/list";
export const GET_CITY_LIST = API_URL + "/bvi/city/list";
export const GET_STATE_COUNTY = API_URL + "/patient/dropdown/list";
export const GET_PATIENT_RACE = API_URL + "/patient/race/ethinicity/list";

// Collect sample
export const DOWNLOAD_PDF = API_URL + "/patient/pdf/download";
export const ADD_SAMPLE_PRINT = API_URL + "/patient/sample/print";
export const COLLECT_PATIENT_ID = API_URL + "/patient/collect/sample";

// Record Result
export const REPORTPOST_URL = API_URL + "/hotline/testing/update/test";
export const RECORD_RESULT_SEARCH = API_URL + "/patient/result/search";
export const RECORD_UPDATE = API_URL + "/patient/update/result";
//daily update

export const GET_DAILY_UPDATE = API_URL + "/daily/report/list";
export const POST_DAILY_UPDATE = API_URL + "/add/daily/report";
export const EDIT_DAILY_UPDATE = API_URL + "/update/daily/report";
export const CHECK_DATE = API_URL + "/daily/report/search";

//resupply request

export const GET_TEST_HISTORY = API_URL + "/test/request/list";
export const POST_TEST_HISTORY = API_URL + "/test/request";

// Shipment
export const GET_SHIPMENT = API_URL + "/shipment/summary";
export const GET_SHIPMENT_DETAILS = API_URL + "/shipment/summary/view/boxes";

// Test Records - Confirmation by code

export const SEARCH_CONFIRMATION_PATIENT =
  API_URL + "/look/by/confirmation/search";
export const SAVE_TEST_RECORD = API_URL + "/look/by/confirmation/save";
export const TESTRESULT_ID = API_URL + "/look/by/confirmation/collect/sample";

// Notification
export const NOTIFICATION_STATUS =
  API_URL + "/audit/dashboard/search/email/sms";

// Check patient details
export const GET_PATIENTDETAILS = API_URL + "/patient/information/get";
//RESEND NOTIFICATION
export const RESEND_NOTIFICATION = API_URL + "/patient/resend/notifications";

// Sort by:
export const SORT_BY = API_URL + "/patient/sort/filter/results";
