import React, { Component } from "react";
import "./recordresult.css";
import DatePicker from "react-datepicker";
import eye from "../../Image/eye.png";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import calendar from "../../Image/calendar.png";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Viewer, { SpecialZoomLevel, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import Modal from "react-modal";
import Barcodeimg from "../../Image/barcode-white.png";
import Closeicon from "../../Image/close.png";
import Lableimg from "../../Image/label12.png";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import printJS from 'print-js'
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toogleSearch: true,
      toogleview: false,
      saveSuccess: false,
      searchDate: null,
      testDate: null,
      searchMobile: "",
      PatientList: "",
      RecordPosNeg: "",
      firstname: "",
      lastname: "",
      sampleIdsearch: "",
      resultId: "",
      patientId: "",
      resultUpdated: "",
      checked: false,
      propsFlag: false,
      isAuto: false,
      sampleAnalysisUpdate: new Date(),
      sampleAnalysisUpdate1: new Date(),
      labelUrl: "",
      ShowPdf: false,
      resultUpdatedOn: "",
      doberrorDate: false,
      testDateerrorDate: false,
      sampleerrorDate: false,
      CheckDate: "",
      sampleCheck: "",
      alternativesampleId: "",
      resultType: "",
      testType: JSON.parse(localStorage.getItem("testTypes"))
        ? JSON.parse(localStorage.getItem("testTypes"))
        : [],
      resultsStatus: "",
      testTypeValue: "",
      sortby: "",
      calltoNotify: "",
      firstListArr: [],
      ctValue: "",
      workingNumber: "",
      patientchecked: false,
      patientchecked1: false,
      patientchecked2: false,
      patientchecked3: false,

      calltoNotifycheck: false,

      resultchecked: false,
      resultchecked1: false,

      patientcheckedresult: false,
      patientcheckedresult1: false,
      patientcheckedresult2: false,
      // enableSendNotificationButton:''
      TestTypeChecked: new Array(
        JSON.parse(localStorage.getItem("testTypes")).length
      ).fill(false),
      TestTypeCheckedValue: new Array(
        JSON.parse(localStorage.getItem("testTypes")).length
      ).fill(false),
    };
    this.onChangeCheckedTestType = this.onChangeCheckedTestType.bind(this);
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          toogleSearch: true,
          firstname: "",
          lastname: "",
          sampleIdsearch: "",
          alternativesampleId: "",
          searchDate: null,
          testDate: null,
          searchMobile: "",
        });
      }
    }
  }
  componentDidMount() {
    if (this.props.location.backstate != null) {
      console.log(
        "this.props.location.backstate",
        this.props.location.backstate
      );
      this.setState({
        isLoaded: true,
        firstname: this.props.location.backstate.checkfname,
        lastname: this.props.location.backstate.checklname,
        searchMobile: this.props.location.backstate.checkmobile,
        searchDate: this.props.location.backstate.checkdob,
        sampleIdsearch: this.props.location.backstate.checksampleId,
        alternativesampleId:
          this.props.location.backstate.checkalternateSampleId,
        testDate: this.props.location.backstate.checktestOrderDate,
      });
      let data = {
        firstName: this.props.location.backstate.checkfname.replaceAll("''", "'"),
        lastName: this.props.location.backstate.checklname.replaceAll("''", "'"),
        dob: this.props.location.backstate.checkdob
          ? moment(this.props.location.backstate.checkdob).format("yyyy-MM-DD")
          : "",
        mobileNumber: this.props.location.backstate.checkmobile,
        testOrderDate: this.props.location.backstate.checktestOrderDate
          ? moment(this.props.location.backstate.checktestOrderDate).format(
            "yyyy-MM-DD"
          )
          : "",
        sampleId: this.props.location.backstate.checksampleId.replaceAll("''", "'"),
        alternateSampleId: this.props.location.backstate.checkalternateSampleId.replaceAll("''", "'"),
        sortBy: "",
        filter1: "",
        filter2: "",
        filter3: "",
        filter4: "",
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordResultSearchAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            toogleSearch: false,
            toogleview: true,
            PatientList: response.info,
            firstListArr: response.info,
            resultId: response.info.resultId,
            patientId: response.info.patientId,
            RecordPosNeg: response.info.testResult,
            isAuto: true,
          });
        } else {
          toast.error(
            response.errorMessage ? response.errorMessage : response.error
          );
        }
        this.setState({
          isLoaded: false,
        });
      });
    }
  }

  handleChangeCTValue(event, index, ctval) {
    let arr = this.state.PatientList;
    arr[index].ctValue = event.target.value.replaceAll('"', "'");
    this.setState({ PatientList: [] });

    this.setState({
      PatientList: arr,
    });
    this.setState({ ctValue: event.target.value.replaceAll('"', "'") });
    arr[index].ctValue = "";
  }
  handleChangeWorkingNumber(event, index, wrkNum) {    
    let arr = this.state.PatientList;
      arr[index].workingNumber = event.target.value.replaceAll('"', "'");
      this.setState({ PatientList: [] });
  
      this.setState({
        PatientList: arr,       
      });
      this.setState({ workingNumber: event.target.value.replaceAll('"', "'") });
      arr[index].workingNumber = "";
  }
  SearchFun() {
    if (
      this.state.searchDate === null &&
      this.state.lastname == "" &&
      this.state.testDate === null &&
      this.state.sampleIdsearch == "" &&
      this.state.alternativesampleId == ""
    ) {
      toast.error(
        "Please enter either DOB, lastname, sample collection date, sampleid or alternative sample id"
      );
      this.setState({
        toogleview: false,
      });
    } else if (this.state.doberrorDate == true) {
      toast.error("Please enter valid DOB");
      return 0;
    } else if (
      this.state.searchDate != null &&
      moment(this.state.searchDate).format("yyyy") <= "1899"
    ) {
      toast.error("Please enter valid DOB");
      return 0;
    } else if (
      this.state.searchDate != null &&
      this.state.searchDate == "Invalid date"
    ) {
      toast.error("Please enter valid DOB");
      return 0;
    } else if (this.state.testDateerrorDate == true) {
      toast.error("Please enter valid sample collection Date");
      return 0;
    } else if (
      this.state.testDate != null &&
      this.state.testDate == "Invalid date"
    ) {
      toast.error("Please enter valid sample collection Date");
      return 0;
    } else if (
      this.state.testDate != null &&
      moment(this.state.testDate).format("yyyy") <= "1899"
    ) {
      toast.error("Please enter valid sample collection Date");
    } else {
      let data = {
        firstName: this.state.firstname.trim().replaceAll("''", "'"),
        lastName: this.state.lastname.trim().replaceAll("''", "'"),
        dob:
          this.state.searchDate != null && this.state.searchDate != ""
            ? moment(this.state.searchDate).format("yyyy-MM-DD")
            : this.state.searchDate,
        mobileNumber: this.state.searchMobile,
        testOrderDate:
          this.state.testDate != null && this.state.testDate != ""
            ? moment(this.state.testDate).format("yyyy-MM-DD")
            : this.state.testDate,
        sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
        alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
        sortBy: "",
        filter1: "",
        filter2: "",
        filter3: "",
        filter4: "",
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordResultSearchAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({ ctValue: "", workingNumber: "" });
          this.setState({
            toogleSearch: false,
            toogleview: true,
            PatientList: response.info,
            firstListArr: response.info,
            resultId: response.info.resultId,
            patientId: response.info.patientId,
            RecordPosNeg: response.info.testResult,
            isAuto: true,

            patientchecked: false,
            patientchecked1: false,
            patientchecked2: false,
            patientchecked3: false,
            calltoNotifycheck: false,
            resultchecked: false,
            resultchecked1: false,
            patientcheckedresult: false,
            patientcheckedresult1: false,
            patientcheckedresult2: false,
            enableSendNotificationButton:
              response.info.enableSendNotificationButton,
            TestTypeChecked: new Array(
              JSON.parse(localStorage.getItem("testTypes")).length
            ).fill(false),
            TestTypeCheckedValue: new Array(
              JSON.parse(localStorage.getItem("testTypes")).length
            ).fill(false),
            CheckDate: "",
            sampleCheck: "",
            alternativesampleId: "",
            resultType: "",
            resultsStatus: "",
            testTypeValue: "",
            sortby: "",
            calltoNotify: "",
          });
        } else {
          toast.error(
            response.errorMessage ? response.errorMessage : response.error
          );
        }
        this.setState({
          isLoaded: false,
        });
      });
    }
  }
  isChecked(index, value, event) {
    let arr = this.state.PatientList;
    arr[index].testResult = value;
    let patientId = this.state.PatientList[index].patientId;
    let resultId = this.state.PatientList[index].resultId;
    let valueChange = value;
    this.setState({ PatientList: [] });
    this.setState({
      PatientList: arr,
      patientIdUp: patientId,
      resultIdUp: resultId,
      valueChangeUp: valueChange,
    });

  }
  handleDateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ doberrorDate: true, searchDate: date });
    } else if (date != null) {
      this.setState({ searchDate: date, doberrorDate: false });
    } else if (date == null) {
      this.setState({ searchDate: null, doberrorDate: false });
    }
  }
  sampleAnalysis(date, index, sampleCheck) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ sampleerrorDate: true });
    } else if (date != null) {
      let arr = this.state.PatientList;
      arr[index].sampleAnalysisDate = date;

      this.setState({ PatientList: [] });
      this.setState({
        PatientList: arr,
        sampleCheck: sampleCheck,
      });
      this.setState({ sampleAnalysisUpdate1: date, sampleerrorDate: false });
    } else if (date == null) {
      this.setState({
        sampleAnalysisUpdate: null,
        sampleCheck: sampleCheck,
        sampleerrorDate: false,
      });
    }
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    } else {
      return phoneNumberString;
    }
    // return null;
  }

  testDateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ testDateerrorDate: true, testDate: date });
    } else if (date != null) {
      this.setState({ testDate: date, testDateerrorDate: false });
    } else if (date == null) {
      this.setState({ testDate: null, testDateerrorDate: false });
    }
  }
  mobileNumberChange(number) {
    this.setState({ searchMobile: number });
  }

  ResultApi(sampledate, valueChangeUp, patientId, resultId, ctValue, workingNumber, testType, testResult) {
    this.setState({
      ctValue: ctValue,
      workingNumber: workingNumber,
    })
    console.log("testing",this.state.workingNumber,this.state.ctValue)
    if (this.state.sampleAnalysisUpdate == null && sampledate == "") {
      toast.error("Please select sample analysis date");
    } else if (this.state.CheckDate > this.state.sampleCheck) {
      toast.error(
        "Sample Analysis Date should be between Sample Collected on date and Result date"
      );
    } else if (
      this.state.sampleAnalysisUpdate &&
      this.state.sampleAnalysisUpdate.getFullYear() <= "1899"
    ) {
      toast.error("Please enter valid sample analysis date");
      return 0;
    } else if (
      this.state.sampleAnalysisUpdate1 &&
      this.state.sampleAnalysisUpdate1.getFullYear() <= "1899"
    ) {
      toast.error("Please enter valid sample analysis date");
      return 0;
    } else if (this.state.sampleerrorDate == true) {
      toast.error("Please enter valid sample analysis date ");
    } else if ((testType == "RT-PCR"
    || testType == "RIDA GENE SARS-CoV-2 RT-PCR test"
    || testType == "Primerdesign COVID-19 genesig RT-PCR assay") &&
      workingNumber == ""
    ) {
      toast.error("Please enter valid working number");
    } else if (
      valueChangeUp == undefined ||
      valueChangeUp == "Unknown"
    ) {
      toast.error("Please select result");
    } else if ((testType == "RT-PCR"
      || testType == "RIDA GENE SARS-CoV-2 RT-PCR test"
      || testType == "Primerdesign COVID-19 genesig RT-PCR assay") &&
      ctValue !== "" &&
      ctValue.match(/[a-z]/gi)
    ) {
      toast.error("Please enter valid CT value");
    } else if ((testType === "RT-PCR"
      || testType == "RIDA GENE SARS-CoV-2 RT-PCR test"
      || testType == "Primerdesign COVID-19 genesig RT-PCR assay") &&
      (ctValue == "" || ctValue == 0) && (testResult !=="Negative" && testResult !=="Invalid")
    ) {
      toast.error("The CT value is not within the range for the test result. Please update the test result or CT value to continue.");
    } else if ((testType == "RT-PCR"
      || testType == "RIDA GENE SARS-CoV-2 RT-PCR test"
      || testType == "Primerdesign COVID-19 genesig RT-PCR assay") &&
      ctValue >= 0.1 && ctValue <= 34.9 && testResult !=="Positive" && testResult !=="Invalid"
    ) {
      toast.error("The CT value is not within the range for the test result. Please update the test result or CT value to continue.");
    } else if ((testType == "RT-PCR"
      || testType == "RIDA GENE SARS-CoV-2 RT-PCR test"
      || testType == "Primerdesign COVID-19 genesig RT-PCR assay") &&
      ctValue >= 35 && ctValue <= 44.9 && testResult !=="Inconclusive" && testResult !=="Invalid"
    ) {
      toast.error("The CT value is not within the range for the test result. Please update the test result or CT value to continue.");
    } else if ((testType == "RT-PCR"
      || testType == "RIDA GENE SARS-CoV-2 RT-PCR test"
      || testType == "Primerdesign COVID-19 genesig RT-PCR assay") &&
      ctValue >= 45 && testResult !=="Negative" && testResult !=="Invalid"
    ) {
      toast.error("The CT value is not within the range for the test result. Please update the test result or CT value to continue.");
    }  else {
      let data = {
        resultId: this.state.resultIdUp ? this.state.resultIdUp : resultId,
        patientId: this.state.patientIdUp ? this.state.patientIdUp : patientId,
        result: this.state.valueChangeUp
          ? this.state.valueChangeUp
          : valueChangeUp,
        analysisDate: this.state.sampleAnalysisUpdate1
          ? moment(this.state.sampleAnalysisUpdate1).format("yyyy-MM-DD")
          : sampledate,
        ctValue: this.state.ctValue
          ? this.state.ctValue : ctValue,
        workingNumber: this.state.workingNumber
          ? this.state.workingNumber : workingNumber,
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordUpdateAPI(data, (response) => {
        if (response.statusCode === 200) {
          // this.setState({ ctValue: "", workingNumber: "" });
          if (this.state.isAuto == true) {
            let data = {
              firstName: this.state.firstname.trim().replaceAll("''", "'"),
              lastName: this.state.lastname.trim().replaceAll("''", "'"),
              dob:
                this.state.searchDate != null && this.state.searchDate != ""
                  ? moment(this.state.searchDate).format("yyyy-MM-DD")
                  : this.state.searchDate,
              mobileNumber: this.state.searchMobile,
              testOrderDate:
                this.state.testDate !== null && this.state.testDate !== ""
                  ? moment(this.state.testDate).format("yyyy-MM-DD")
                  : this.state.testDate,
              sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
              alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
              sortBy: this.state.sortby ? this.state.sortby : "",
              filter1: this.state.resultsStatus ? this.state.resultsStatus : "",
              filter2: this.state.resultType ? this.state.resultType : "",
              filter3: this.state.calltoNotify ? this.state.calltoNotify : "",
              filter4: this.state.testTypeValue ? this.state.testTypeValue : "",
            };
            this.setState({
              isLoaded: true,
            });
            api.RecordResultSearchAPI(data, (response) => {
              if (response.statusCode === 200) {
                this.setState({ ctValue: "", workingNumber: "" });
                this.setState({
                  toogleSearch: false,
                  toogleview: true,
                  PatientList: response.info,
                  firstListArr: response.info,
                  resultId: response.info.resultId,
                  patientId: response.info.patientId,
                  RecordPosNeg: response.info.testResult,
                  isAuto: true,
                });
              } else {
                toast.error(
                  response.errorMessage ? response.errorMessage : response.error
                );
              }
              this.setState({
                isLoaded: false,
              });
            });
            toast.success(response.info);
            // setTimeout(() => {
            this.setState({
              isLoaded: false,
              toogleSearch: false,
              toogleview: true,
              resultUpdated: response.resultUpdated,
              RecordPosNeg: this.state.valueChangeUp,
            });
            // }, 500);
          } else {
            toast.success(response.info);
            // setTimeout(() => {
            this.setState({
              isLoaded: false,
              toogleSearch: false,
              toogleview: true,
              resultUpdated: response.resultUpdated,
              RecordPosNeg: this.state.valueChangeUp,
              labelUrl: response.preSignedUrl ? response.preSignedUrl : "",
              smallLabelUrl: response.smallLabelPreSignedUrl ? response.smallLabelPreSignedUrl : "",
              resultUpdatedOn: response.resultUpdatedOn,
            });

            // }, 500);
          }
        } else {
          this.setState({
            isLoaded: false,
          });
          toast.error(response.errorMessage);
        }
      });
    }
  }

  Resend(resultId, index) {
    let data = {
      resultId: resultId,
    };

    api.ResendNotification(data, (response) => {
      if (response.statusCode === 200) {
        toast.success(response.info);
      } else {
        this.setState({
          isLoaded: false,
        });
        toast.error(response.errorMessage);
      }
    });
  }
  backPage() {
    this.setState({
      toogleSearch: true,
      firstname: "",
      lastname: "",
      sampleIdsearch: "",
      alternativesampleId: "",
      searchDate: null,
      testDate: null,
      searchMobile: "",
      patientchecked: false,
      patientchecked1: false,
      patientchecked2: false,
      patientchecked3: false,
      calltoNotifycheck: false,
      resultchecked: false,
      resultchecked1: false,
      patientcheckedresult: false,
      patientcheckedresult1: false,
      patientcheckedresult2: false,
      resultType: "",
      resultsStatus: "",
      testTypeValue: "",
      sortby: "",
      calltoNotify: "",
      TestTypeChecked: new Array(
        JSON.parse(localStorage.getItem("testTypes")).length
      ).fill(false),
      TestTypeCheckedValue: new Array(
        JSON.parse(localStorage.getItem("testTypes")).length
      ).fill(false),
    });
  }
  setData(item) {
    this.setState({ PatientList: item });
    this.setState({
      toogleSearch: false,
      toogleview: true,
      propsFlag: true,
      isLoaded: false,
    });
  }
  EnterKeySearch(evt) {
    if (evt.keyCode == 13) {
      this.SearchFun();
    }
  }
  EnterKeySave(evt) {
    if (evt.keyCode == 13) {
      this.ResultApi();
    }
  }
  OpenPDF(value) {
    window.open("/pdf", "_blank");
    localStorage.setItem("pdf", value ? value : this.state.labelUrl);

    // this.setState({
    //   openPDFstate: true,
    //   labelUrl: value ? value : this.state.labelUrl,
    // });
  }
  OpenBarcodePDF(value) {
    printJS({printable: value, type: 'pdf'});
  }
  handleClose(e) {
    this.setState({
      openPDFstate: false,
    });
  }
  openDetails(patientId, sampleId) {
    this.setState({
      isLoaded: true,
    });
    let data = {
      patientId: patientId,
    };
    api.Getpatientdetails(data, (response) => {
      if (response.statusCode == "200") {
        let checkdata = {
          checkfname: this.state.firstname ? this.state.firstname : "",
          checklname: this.state.lastname ? this.state.lastname : "",
          checkmobile: this.state.searchMobile ? this.state.searchMobile : "",
          checkdob: this.state.searchDate ? this.state.searchDate : "",
          checksampleId: this.state.sampleIdsearch
            ? this.state.sampleIdsearch
            : "",
          checktestOrderDate: this.state.testDate ? this.state.testDate : "",
          checkalternateSampleId: this.state.alternativesampleId
            ? this.state.alternativesampleId
            : "",
        };
        let resposneCheck = response.info;
        var attributes = Object.keys(checkdata);

        for (var i = 0; i < attributes.length; i++) {
          resposneCheck[attributes[i]] = checkdata[attributes[i]];
        }
        this.props.history.push({
          pathname: "/details",
          state: resposneCheck,
        });
        localStorage.setItem("fromvalue", "testresult");
      } else {
      }
    });
  }

  // call to notify
  onChangeCallcheck() {
    if (this.state.calltoNotifycheck === false) {
      this.setState({
        calltoNotifycheck: true,
      });
    } else {
      this.setState(
        {
          calltoNotifycheck: false,

          // patientchecked1: false,
          // patientchecked2: false,
          calltoNotify: "",

          // resultchecked1: false,
          // resultchecked: false,
          resultsStatus: this.state.resultsStatus,

          // patientcheckedresult: false,
          // patientcheckedresult1: false,
          // patientcheckedresult2: false,
          resultType: this.state.resultType,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: "",
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.calltoNotifycheck === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      // this.SearchFun();
    }
  }

  //first sort

  onChangeChecked() {
    if (this.state.patientchecked === false) {
      this.setState({
        patientchecked: true,
        patientchecked1: false,
        patientchecked2: false,
        patientchecked3: false,
      });
    } else {
      this.setState(
        {
          patientchecked: false,

          // patientchecked1: false,
          // patientchecked2: false,
          sortby: "",

          // resultchecked1: false,
          // resultchecked: false,
          resultsStatus: this.state.resultsStatus,

          // patientcheckedresult: false,
          // patientcheckedresult1: false,
          // patientcheckedresult2: false,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: "",
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.calltoNotifycheck === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      // this.SearchFun();
    }
  }

  onChangeChecked1(event) {
    console.log("evented", event.target.value);

    if (this.state.patientchecked1 === false) {
      this.setState({
        patientchecked1: true,
        patientchecked: false,
        patientchecked2: false,
        patientchecked3: false,
      });
    } else {
      this.setState(
        {
          patientchecked1: false,

          // patientchecked: false,
          // patientchecked2: false,
          sortby: "",

          // resultchecked1: false,
          // resultchecked: false,
          // resultsStatus: this.status.resultsStatus,

          // patientcheckedresult: false,
          // patientcheckedresult1: false,
          // patientcaheckedresult2: false,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: "",
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.calltoNotifycheck === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );
    }
  }

  onChangeChecked2() {
    if (this.state.patientchecked2 === false) {
      this.setState({
        patientchecked2: true,
        patientchecked: false,
        patientchecked1: false,
        patientchecked3: false,
      });
    } else {
      this.setState(
        {
          patientchecked2: false,

          // patientchecked: false,
          // patientchecked1: false,
          sortby: "",

          // resultchecked1: false,
          // resultchecked: false,
          resultsStatus: this.state.resultsStatus,

          // patientcheckedresult: false,
          // patientcheckedresult1: false,
          // patientcheckedresult2: false,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: "",
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.calltoNotifycheck === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      // this.SearchFun();
    }
  }

  onChangeChecked3(event) {
    console.log("evented", event.target.value);

    if (this.state.patientchecked3 === false) {
      this.setState({
        patientchecked3: true,
        patientchecked: false,
        patientchecked1: false,
        patientchecked2: false,
      });
    } else {
      this.setState(
        {
          patientchecked3: false,

          // patientchecked: false,
          // patientchecked1: false,
          sortby: "",

          // resultchecked1: false,
          // resultchecked: false,
          resultsStatus: this.state.resultsStatus,

          // patientcheckedresult: false,
          // patientcheckedresult1: false,
          // patientcheckedresult2: false,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: "",
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });
          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.calltoNotifycheck === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      // this.SearchFun();
    }
  }

  //second sort

  onChangeCheckedResult(event) {
    console.log("evented", event.target.value);

    if (this.state.resultchecked === false) {
      this.setState({
        resultchecked: true,
        resultchecked1: false,
        resultsStatus: this.state.resultsStatus,
        patientchecked2: this.state.patientchecked2,
        patientchecked3: this.state.patientchecked3,
        calltoNotifycheck: this.state.calltoNotifycheck,
        patientchecked: this.state.patientchecked,
        patientchecked1: this.state.patientchecked1,
        sortby: this.state.sortby,
        patientcheckedresult: this.state.patientcheckedresult,
        patientcheckedresult1: this.state.patientcheckedresult1,
        patientcheckedresult2: this.state.patientcheckedresult2,
        resultType: this.state.resultType,
        testTypeValue: this.state.testTypeValue,
      });
    } else {
      this.setState(
        {
          isLoaded: true,
          resultchecked: false,
          resultchecked1: false,
          resultsStatus: "",
          patientchecked2: this.state.patientchecked2,
          patientchecked3: this.state.patientchecked3,

          patientchecked: this.state.patientchecked,
          patientchecked1: this.state.patientchecked1,
          sortby: this.state.sortby,
          patientcheckedresult: this.state.patientcheckedresult,
          patientcheckedresult1: this.state.patientcheckedresult1,
          patientcheckedresult2: this.state.patientcheckedresult2,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: "",
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      console.log("sortby", this.state.sortby);
      // this.SearchFun();
    }
  }

  onChangeCheckedResult1(event) {
    console.log("evented", event.target.value);

    if (this.state.resultchecked1 === false) {
      this.setState({
        resultchecked1: true,
        resultchecked: false,
        resultsStatus: this.state.resultsStatus,
        patientchecked2: this.state.patientchecked2,
        patientchecked3: this.state.patientchecked3,

        patientchecked: this.state.patientchecked,
        patientchecked1: this.state.patientchecked1,
        sortby: this.state.sortby,
        patientcheckedresult: this.state.patientcheckedresult,
        patientcheckedresult1: this.state.patientcheckedresult1,
        patientcheckedresult2: this.state.patientcheckedresult2,
        resultType: this.state.resultType,
        testTypeValue: this.state.testTypeValue,
      });
    } else {
      this.setState(
        {
          isLoaded: true,
          resultchecked1: false,
          resultchecked: false,
          resultsStatus: "",
          patientchecked2: this.state.patientchecked2,
          patientchecked3: this.state.patientchecked3,

          patientchecked: this.state.patientchecked,
          patientchecked1: this.state.patientchecked1,
          sortby: this.state.sortby,
          patientcheckedresult: this.state.patientcheckedresult,
          patientcheckedresult1: this.state.patientcheckedresult1,
          patientcheckedresult2: this.state.patientcheckedresult2,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: "",
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      // this.SearchFun();
    }
  }

  //third sort

  onChangeCheckedType(event) {
    console.log("evented", event.target.value);

    if (this.state.patientcheckedresult === false) {
      this.setState(
        {
          isLoaded: true,
          patientcheckedresult: true,
          patientcheckedresult1: false,
          patientcheckedresult2: false,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });
        }
      );
    } else {
      this.setState(
        {
          isLoaded: true,
          patientcheckedresult: false,
          patientcheckedresult1: false,
          patientcheckedresult2: false,
          resultsStatus: this.state.resultsStatus,
          sortby: this.state.sortby,
          resultType: "",
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: "",
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      console.log("sortby", this.state.sortby);
      //  this.SearchFun();
    }
  }

  onChangeCheckedType1(event) {
    console.log("evented", event.target.value);

    if (this.state.patientcheckedresult1 === false) {
      this.setState(
        {
          patientcheckedresult1: true,
          isLoaded: true,
          patientcheckedresult: false,
          patientcheckedresult2: false,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });
        }
      );
    } else {
      this.setState(
        {
          isLoaded: true,
          patientcheckedresult: false,
          patientcheckedresult1: false,
          patientcheckedresult2: false,
          resultsStatus: this.state.resultsStatus,
          sortby: this.state.sortby,
          resultType: "",
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: "",
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });
        }
      );

      if (
        this.state.patientchecked === false &&
        this.state.patientchecked1 === false &&
        this.state.patientchecked2 === false &&
        this.state.patientchecked3 === false &&
        this.state.resultchecked === false &&
        this.state.resultchecked1 === false &&
        this.state.patientcheckedresult === false &&
        this.state.patientcheckedresult1 === false &&
        this.state.patientcheckedresult2 === false &&
        !this.state.TestTypeCheckedValue.includes(true)
      ) {
        this.SearchFun();
      }

      // this.SearchFun();
    }
  }

  onChangeCheckedType2(event) {
    console.log("evented", event.target.value);

    if (this.state.patientcheckedresult2 === false) {
      this.setState(
        {
          isLoaded: true,
          resultType: this.state.resultType,
          testTypeValue: this.state.testTypeValue,
          patientcheckedresult2: true,
          patientcheckedresult: false,
          patientcheckedresult1: false,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: this.state.resultType,
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });
        }
      );
    } else {
      this.setState(
        {
          isLoaded: true,
          patientcheckedresult: false,
          patientcheckedresult1: false,
          patientcheckedresult2: false,
          resultsStatus: this.state.resultsStatus,
          sortby: this.state.sortby,
          resultType: "",
          testTypeValue: this.state.testTypeValue,
        },
        () => {
          let data = {
            firstName: this.state.firstname.trim().replaceAll("''", "'"),
            lastName: this.state.lastname.trim().replaceAll("''", "'"),
            dob:
              this.state.searchDate != null && this.state.searchDate !== ""
                ? moment(this.state.searchDate).format("yyyy-MM-DD")
                : this.state.searchDate,
            mobileNumber: this.state.searchMobile,
            testOrderDate:
              this.state.testDate != null && this.state.testDate !== ""
                ? moment(this.state.testDate).format("yyyy-MM-DD")
                : this.state.testDate,
            sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
            alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
            sortBy: this.state.sortby,
            filter1: this.state.resultsStatus,
            filter2: "",
            filter3: this.state.calltoNotify,
            filter4: this.state.testTypeValue,
          };
          this.setState({
            isLoaded: true,
          });
          api.RecordResultSearchAPI(data, (response) => {
            if (response.statusCode === 200) {
              this.setState({
                PatientList: response.info,
                toogleSearch: false,
                toogleview: true,
                isLoaded: false,
              });
            } else {
              this.setState({
                isLoaded: false,
                sortBy: "",
                resultsStatus: "",
                resultType: "",
                testTypeValue: "",
              });
            }
          });

          if (
            this.state.patientchecked === false &&
            this.state.patientchecked1 === false &&
            this.state.patientchecked2 === false &&
            this.state.patientchecked3 === false &&
            this.state.resultchecked === false &&
            this.state.resultchecked1 === false &&
            this.state.patientcheckedresult === false &&
            this.state.patientcheckedresult1 === false &&
            this.state.patientcheckedresult2 === false &&
            !this.state.TestTypeCheckedValue.includes(true)
          ) {
            this.SearchFun();
          }
        }
      );

      //  this.SearchFun();
    }
  }

  // sort by
  sortbyChange(event) {
    this.setState({ sortby: event.target.value, isLoaded: true }, () => {
      let data = {
        firstName: this.state.firstname.trim().replaceAll("''", "'"),
        lastName: this.state.lastname.trim().replaceAll("''", "'"),
        dob:
          this.state.searchDate != null && this.state.searchDate !== ""
            ? moment(this.state.searchDate).format("yyyy-MM-DD")
            : this.state.searchDate,
        mobileNumber: this.state.searchMobile,
        testOrderDate:
          this.state.testDate != null && this.state.testDate !== ""
            ? moment(this.state.testDate).format("yyyy-MM-DD")
            : this.state.testDate,
        sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
        alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
        sortBy: this.state.sortby,
        filter1: this.state.resultsStatus,
        filter2: this.state.resultType,
        filter3: this.state.calltoNotify,
        filter4: this.state.testTypeValue,
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordResultSearchAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            PatientList: response.info,
            toogleSearch: false,
            toogleview: true,
            isLoaded: false,
          });
        } else {
          this.setState({
            isLoaded: false,
            sortBy: "",
            resultsStatus: "",
            resultType: "",
            testTypeValue: "",
          });
        }
      });
    });
  }

  CallnotifyChange(event) {
    this.setState({ calltoNotify: event.target.value, isLoaded: true }, () => {
      let data = {
        firstName: this.state.firstname.trim().replaceAll("''", "'"),
        lastName: this.state.lastname.trim().replaceAll("''", "'"),
        dob:
          this.state.searchDate != null && this.state.searchDate !== ""
            ? moment(this.state.searchDate).format("yyyy-MM-DD")
            : this.state.searchDate,
        mobileNumber: this.state.searchMobile,
        testOrderDate:
          this.state.testDate != null && this.state.testDate !== ""
            ? moment(this.state.testDate).format("yyyy-MM-DD")
            : this.state.testDate,
        sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
        alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
        sortBy: this.state.sortby,
        filter1: this.state.resultsStatus,
        filter2: this.state.resultType,
        filter3: this.state.calltoNotify,
        filter4: this.state.testTypeValue,
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordResultSearchAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            PatientList: response.info,
            toogleSearch: false,
            toogleview: true,
            isLoaded: false,
          });
        } else {
          this.setState({
            isLoaded: false,
            sortBy: "",
            resultsStatus: "",
            calltoNotify: "",
            resultType: "",
          });
        }
      });
    });
  }

  // result status

  resultsStatusChange(event) {
    this.setState({ resultsStatus: event.target.value, isLoaded: true }, () => {
      let data = {
        firstName: this.state.firstname.trim().replaceAll("''", "'"),
        lastName: this.state.lastname.trim().replaceAll("''", "'"),
        dob:
          this.state.searchDate != null && this.state.searchDate !== ""
            ? moment(this.state.searchDate).format("yyyy-MM-DD")
            : this.state.searchDate,
        mobileNumber: this.state.searchMobile,
        testOrderDate:
          this.state.testDate != null && this.state.testDate !== ""
            ? moment(this.state.testDate).format("yyyy-MM-DD")
            : this.state.testDate,
        sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
        alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
        sortBy: this.state.sortby,
        filter1: this.state.resultsStatus,
        filter2: this.state.resultType,
        filter3: this.state.calltoNotify,
        filter4: this.state.testTypeValue,
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordResultSearchAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            PatientList: response.info,
            toogleSearch: false,
            toogleview: true,
            isLoaded: false,
          });
        } else {
          this.setState({
            isLoaded: false,
            sortBy: "",
            resultsStatus: "",
            resultType: "",
          });
        }
      });
    });
  }

  //result type

  resultTypechange(event) {
    this.setState({ resultType: event.target.value }, () => {
      console.log("result type", event.target.value);

      let data = {
        firstName: this.state.firstname.trim().replaceAll("''", "'"),
        lastName: this.state.lastname.trim().replaceAll("''", "'"),
        dob:
          this.state.searchDate != null && this.state.searchDate !== ""
            ? moment(this.state.searchDate).format("yyyy-MM-DD")
            : this.state.searchDate,
        mobileNumber: this.state.searchMobile,
        testOrderDate:
          this.state.testDate != null && this.state.testDate !== ""
            ? moment(this.state.testDate).format("yyyy-MM-DD")
            : this.state.testDate,
        sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
        alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
        sortBy: this.state.sortby,
        filter1: this.state.resultsStatus,
        filter2: this.state.resultType,
        filter3: this.state.calltoNotify,
        filter4: this.state.testTypeValue,
      };
      this.setState({
        isLoaded: true,
      });
      api.RecordResultSearchAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            PatientList: response.info,
            toogleSearch: false,
            toogleview: true,
            isLoaded: false,
          });
        } else {
          this.setState({
            isLoaded: false,
            sortBy: "",
            resultsStatus: "",
            resultType: "",
          });
        }
      });
    });
  }

  // componentDidUpdate(prevState) {
  //   if (
  //     prevState.sortBy !== this.state.sortby &&
  //     this.state.sortby === "First name(A-Z)"
  //   ) {
  //     this.setState({ patientchecked: true });
  //   }
  // }

  //Test Type
  onChangeCheckedTestType(position) {
    if (this.state.TestTypeCheckedValue[position] == false) {
      this.updatedCheckedState = this.state.TestTypeChecked.map((item, index) =>
        index == position ? !item : item
      );
    } else {
      this.updatedCheckedState = this.state.TestTypeCheckedValue.map(
        (item, index) => (index == position ? !item : item)
      );
      this.setState({
        testTypeValue: "",
      });
    }
    this.setState(
      {
        TestTypeCheckedValue: this.updatedCheckedState,
      },
      () => {
        let data = {
          firstName: this.state.firstname.trim().replaceAll("''", "'"),
          lastName: this.state.lastname.trim().replaceAll("''", "'"),
          dob:
            this.state.searchDate != null && this.state.searchDate !== ""
              ? moment(this.state.searchDate).format("yyyy-MM-DD")
              : this.state.searchDate,
          mobileNumber: this.state.searchMobile,
          testOrderDate:
            this.state.testDate != null && this.state.testDate !== ""
              ? moment(this.state.testDate).format("yyyy-MM-DD")
              : this.state.testDate,
          sampleId: this.state.sampleIdsearch.trim().replaceAll("''", "'"),
          alternateSampleId: this.state.alternativesampleId.replaceAll("''", "'"),
          sortBy: this.state.sortby,
          filter1: this.state.resultsStatus,
          filter2: this.state.resultType,
          filter3: this.state.calltoNotify,
          filter4: this.state.testTypeValue,
        };
        this.setState({
          isLoaded: true,
        });
        api.RecordResultSearchAPI(data, (response) => {
          if (response.statusCode === 200) {
            this.setState({
              PatientList: response.info,
              toogleSearch: false,
              toogleview: true,
              isLoaded: false,
            });
          } else {
            this.setState({
              isLoaded: false,
              sortBy: "",
              resultsStatus: "",
              resultType: "",
              testTypeValue: "",
            });
          }
        });
      }
    );
  }
  //Test Type dropdown onChange
  TestTypeOnChange(event) {
    this.setState({
      testTypeValue: event.target.value.replaceAll('"', "'"),
    });
  }
  render() {
    const customStylesDelete = {
      content: {
        top: "40%",
        height: "24%",
        backgroundColor: "white",
        border: "none",
        outline: "none",
        width: "90%",
        marginLeft: "5%",
      },
    };

    if (
      this.state.propsFlag == false &&
      this.props.location.state != undefined
    ) {
      this.setState({ isLoaded: true });
      this.setData(this.props.location.state);
    }

    return (

      <>
        <ToastContainer />
        {this.state.openPDFstate == true ? (
          <Modal
            isOpen={this.state.openPDFstate}
            style={customStylesDelete}
            contentLabel="Example Modal"
            className="shedule"
          >
            <div
              className="container-fluid"
              style={{
                backgroundColor: "#6fe2e9",
                padding: "2%",
                marginTop: "5%",
                borderRadius: "10px",
                boxShadow:
                  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
              }}
            >
              <div
                // className="close"
                onClick={this.handleClose.bind(this)}
                className="pdfclose"
              >
                <img src={Closeicon} style={{ width: "30px" }} />
              </div>
              <div
                style={{
                  padding: "1%",
                  textAlign: "center",
                  color: "black",
                  fontSize: "16px",
                }}
              ></div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <div
                  style={{ height: "600px", width: "90%", margin: "0 auto" }}
                >
                  <Viewer zoom={1} fileUrl={this.state.labelUrl} />
                </div>
              </Worker>
            </div>
          </Modal>
        ) : (
          ""
        )}

        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          <div className="searchContainer rec">
            <div className="searcher">
              {this.state.toogleSearch === true ? (
                <div>
                  <h1> Search for Patient</h1>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {this.state.toogleSearch === true ? (
              // Search div
              <div>
                <div className="">
                  <div className="DisplayIte">
                    <div
                      className="dater datepickerstyle1"
                      style={{ marginTop: "18px" }}
                    >
                      <label className="ethnicit">
                        Date of Birth (MM/DD/YYYY)
                      </label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          error={true}
                          value={this.state.searchDate}
                          onChange={(date) => this.handleDateChange(date)}
                          label=""
                          maxDate={new Date()}
                          placeholder="MM/DD/YYYY"
                          format="MM/dd/yyyy"
                          required
                          autoOk
                          onKeyUp={this.EnterKeySearch.bind(this)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div style={{ marginTop: "20px", width: "50%" }}>
                      <label className="ethnicit">Phone number</label>

                      <PhoneInput
                        country={"us"}
                        // onlyCountries={["us"]}
                        // disableDropdown={true}
                        onChange={this.mobileNumberChange.bind(this)}
                        value={this.state.searchMobile}
                        onKeyUp={this.EnterKeySearch.bind(this)}
                        inputProps={{
                          autoFormat: true,
                          countryCodeEditable: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="DisplayIte">
                    <div className="patient_name">
                      <label className="ethnicit">First Name</label>

                      <input
                        type="text"
                        style={{ marginTop: "5px" }}
                        value={this.state.firstname}
                        onKeyUp={this.EnterKeySearch.bind(this)}
                        onChange={(e) => {
                          let value = e.target.value.replaceAll('"', "'")
                          this.setState({ firstname: value.replaceAll("''", "'") });
                        }}
                      />
                    </div>

                    <div className="patient_lname">
                      <label className="ethnicit">Last Name</label>
                      <input
                        type="text"
                        value={this.state.lastname}
                        style={{ marginTop: "5px" }}
                        onKeyUp={this.EnterKeySearch.bind(this)}
                        onChange={(e) => {
                          let value = e.target.value.replaceAll('"', "'")
                          this.setState({ lastname: value.replaceAll("''", "'") });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="flexing">
                  <div
                    className="dater datepickerstyle2"
                    style={{ width: "49%" }}
                  >
                    <label className="ethnicit">
                      Search by sample collection date (MM/DD/YYYY)
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        clearable
                        value={this.state.testDate}
                        onChange={(date) => this.testDateChange(date)}
                        format="MM/dd/yyyy"
                        maxDate={new Date()}
                        required
                        placeholder="MM/DD/YYYY"
                        autoOk
                        onKeyUp={this.EnterKeySearch.bind(this)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div style={{ width: "48%", marginLeft: "-5px" }}>
                    <label className="ethnicit">Sample ID</label>
                    <input
                      style={{ paddingTop: "27px" }}
                      type="text"
                      onKeyUp={this.EnterKeySearch.bind(this)}
                      value={this.state.sampleIdsearch}
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"', "'")
                        this.setState({ sampleIdsearch: value.replaceAll("''", "'") })
                      }}
                    />
                  </div>
                </div>

                <div className="flexing">
                  <div style={{ width: "48%", marginLeft: "-5px" }}>
                    <label className="ethnicit">Alternative Sample ID</label>
                    <input
                      style={{ paddingTop: "27px" }}
                      type="text"
                      onKeyUp={this.EnterKeySearch.bind(this)}
                      value={this.state.alternativesampleId}
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"', "'")
                        this.setState({ alternativesampleId: value.replaceAll("''", "'") })
                      }}
                    />
                  </div>
                </div>

                <div className="btnFix">
                  <button
                    className="subBtn1"
                    onClick={this.SearchFun.bind(this)}
                  >
                    Search
                  </button>
                </div>
              </div>
            ) : this.state.toogleview === true ? (
              <div>
                <div className="searcher">
                  <div>
                    <h2 style={{ fontWeight: "normal" }}>Record Result</h2>
                  </div>

                  <div>
                    <button
                      className="searchAgain"
                      onClick={this.backPage.bind(this)}
                    >
                      Search Again
                    </button>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-10px",
                  }}
                >
                  <div>
                    <FormControl style={{ width: "220px" }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Sort by
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={this.state.sortby}
                        onChange={this.sortbyChange.bind(this)}
                        input={<Input />}
                      >
                        <MenuItem
                          value={"First name(A-Z)"}
                          onClick={this.onChangeChecked.bind(this)}
                        >
                          <Checkbox checked={this.state.patientchecked}>
                            Patient First Name
                          </Checkbox>
                          <ListItemText
                            primary={"Patient First Name (A-Z)"}
                            className="MuiListItemText-primary"
                          />
                        </MenuItem>

                        <MenuItem
                          value={"Last name(A-Z)"}
                          onClick={this.onChangeChecked1.bind(this)}
                        >
                          <Checkbox checked={this.state.patientchecked1}>
                            Patient Last Name (A-Z)
                          </Checkbox>
                          <ListItemText
                            className="MuiListItemText-primary"
                            primary={"Patient Last Name (A-Z)"}
                          />
                        </MenuItem>

                        <MenuItem
                          value={"Sample collected on (desc)"}
                          onClick={this.onChangeChecked2.bind(this)}
                        >
                          <Checkbox checked={this.state.patientchecked2}>
                            Sample Collection Time
                          </Checkbox>
                          <ListItemText
                            className="MuiListItemText-primary"
                            primary={"Sample Collection Time"}
                          />
                        </MenuItem>
                        <MenuItem
                          value={"Alt Sample ID(A-Z)"}
                          onClick={this.onChangeChecked3.bind(this)}
                        >
                          <Checkbox checked={this.state.patientchecked3}>
                            Alternative Sample ID
                          </Checkbox>
                          <ListItemText
                            className="MuiListItemText-primary"
                            primary={"Alternative Sample ID (A-Z)"}
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ marginLeft: "20px" }}>
                    <FormControl style={{ width: "220px" }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Result Notification
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={this.state.calltoNotify}
                        onChange={this.CallnotifyChange.bind(this)}
                        input={<Input />}
                      >
                        <MenuItem
                          value={"Call to notify"}
                          onClick={this.onChangeCallcheck.bind(this)}
                        >
                          <Checkbox checked={this.state.calltoNotifycheck}>
                            Call to notify
                          </Checkbox>
                          <ListItemText
                            primary={"Call to notify"}
                            className="MuiListItemText-primary"
                          />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <FormControl style={{ width: "160px" }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Results Status
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={this.state.resultsStatus}
                        onChange={this.resultsStatusChange.bind(this)}
                        input={<Input />}
                      >
                        <MenuItem
                          value={"Result published"}
                          onClick={this.onChangeCheckedResult.bind(this)}
                        >
                          <Checkbox checked={this.state.resultchecked}>
                            Published
                          </Checkbox>
                          <ListItemText primary={"Published"} />
                        </MenuItem>
                        <MenuItem
                          value={"Result pending"}
                          onClick={this.onChangeCheckedResult1.bind(this)}
                        >
                          <Checkbox checked={this.state.resultchecked1}>
                            Pending
                          </Checkbox>
                          <ListItemText primary={"Pending"} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <FormControl style={{ width: "160px" }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Result Type
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={this.state.resultType}
                        onChange={this.resultTypechange.bind(this)}
                        input={<Input />}
                      >
                        <MenuItem
                          value={"Result positive"}
                          onClick={this.onChangeCheckedType.bind(this)}
                        >
                          <Checkbox checked={this.state.patientcheckedresult}>
                            Positive
                          </Checkbox>
                          <ListItemText primary={"Positive"} />
                        </MenuItem>

                        <MenuItem
                          value={"Result negative"}
                          onClick={this.onChangeCheckedType1.bind(this)}
                        >
                          <Checkbox checked={this.state.patientcheckedresult1}>
                            Negative
                          </Checkbox>
                          <ListItemText primary={"Negative"} />
                        </MenuItem>

                        <MenuItem
                          value={"Result invalid"}
                          onClick={this.onChangeCheckedType2.bind(this)}
                        >
                          <Checkbox checked={this.state.patientcheckedresult2}>
                            Invalid
                          </Checkbox>
                          <ListItemText primary={"Invalid"} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  {/* Test Type */}
                  <div style={{ marginLeft: "20px" }}>
                    <FormControl style={{ width: "160px" }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Test Type
                      </InputLabel>
                      <Select
                        style={{ width: "200px" }}
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        value={this.state.testTypeValue}
                        onChange={this.TestTypeOnChange.bind(this)}
                        input={<Input />}
                      >
                        {this.state.testType.length > 0 ? (
                          this.state.testType.map((item, index) => (
                            <MenuItem
                              value={item.testName}
                              onClick={() =>
                                this.onChangeCheckedTestType(index)
                              }
                            >
                              <Checkbox
                                checked={this.state.TestTypeCheckedValue[index]}
                              >
                                {item.testName}
                              </Checkbox>

                              <ListItemText primary={item.testName} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div>
                  {this.state.PatientList.map((item, index) => (
                    <div
                      className="searchContainer"
                      style={{ marginTop: "20px" }}
                    >
                      <div
                        className={
                          (item.testType === "RT-PCR"
                                 || item.testType === "Primerdesign COVID-19 genesig RT-PCR assay"
                                 || item.testType === "RIDA GENE SARS-CoV-2 RT-PCR test") ? "PCR_data" : "data"
                        }
                      >
                        <div style={{ float: "right", marginLeft: "15px" }}>
                          <span
                            className="viewP"
                            onClick={this.openDetails.bind(
                              this,
                              item.patientId,
                              item.sampleId
                            )}
                          >
                            View Patient
                          </span>
                        </div>
                        <div
                          className="firstRowrecord"
                          style={{ marginTop: "35px" }}
                        >
                          <div>
                            <div>Name:</div>
                            <div
                              title={
                                item.firstName.length < 16
                                  ? ""
                                  : item.firstName + item.lastName
                              }
                            >
                              {item.firstName.length < 16
                                ? item.firstName
                                : item.firstName.slice(0, 16) + "...."}{" "}
                              {item.firstName.length < 16 ? item.lastName : ""}
                            </div>
                          </div>

                          <div>
                            <div>DOB:</div>
                            <div style={{ marginLeft: "-10px" }}>
                              {moment(item.dob).format("MMM/DD/yyyy")}
                            </div>
                          </div>

                          <div>
                            <div>Sample Collected On:</div>
                            <div>
                              {item.sampleCollectedOn != ""
                                ? moment(item.sampleCollectedOn).format(
                                  "MMM/DD/yyyy"
                                )
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div className="secondRowrecord">
                          <div>
                            <div>Sample Identifier:</div>
                            <div>{item.sampleId}</div>
                          </div>
                          <div>
                            <div>Test Type:</div>
                            <div
                              className={
                                (item.testType === "RT-PCR"
                                 || item.testType === "Primerdesign COVID-19 genesig RT-PCR assay"
                                 || item.testType === "RIDA GENE SARS-CoV-2 RT-PCR test") ? "PCRText" : ""
                              }
                            >
                              {item.testType}
                            </div>
                          </div>
                          <div>
                            <div>Result Date:</div>
                            {item.resultUpdatedOn ? (
                              <div>
                                {item.resultUpdatedOn == ""
                                  ? item.resultUpdatedOn
                                  : moment(item.resultUpdatedOn).format(
                                    "MMM/DD/yyyy"
                                  )}
                              </div>
                            ) : (
                              <div>
                                {this.state.resultUpdatedOn == ""
                                  ? this.state.resultUpdatedOn
                                  : moment(this.state.resultUpdatedOn).format(
                                    "MMM/DD/yyyy"
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="thirdRowrecord">
                          <div>
                            <div>Sample Analysis Date:</div>
                            <div>
                              <div className="dater" style={{ width: "112%" }}>
                                {/* <input type="date" placeholder="MM/DD/YYYY" /> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    clearable
                                    value={
                                      item.sampleAnalysisDate != ""
                                        ? moment(
                                          item.sampleAnalysisDate
                                        ).format("MMM/DD/yyyy")
                                        : this.state.sampleAnalysisUpdate
                                    }
                                    onChange={(date) =>
                                      this.sampleAnalysis(
                                        date,
                                        index,
                                        item.sampleCollectedOn
                                      )
                                    }
                                    disabled={
                                      item.disableResultCard == true
                                        ? true
                                        : false
                                    }
                                    format="MM/dd/yyyy"
                                    placeholder="MM/DD/YYYY"
                                    maxDate={new Date()}
                                    minDate={item.sampleCollectedOn}
                                    required
                                    autoOk
                                    onKeyUp={this.EnterKeySave.bind(this)}
                                  />
                                </MuiPickersUtilsProvider>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>Alt Sample ID:</div>
                            <div>{item.alternateSampleId}</div>
                          </div>

                          <div>
                            <div>Phone No:</div>
                            <div>
                              {formatPhoneNumberIntl(item.mobileNumber)}
                              {item.email === "N/A" &&
                                item.canReceiveText === false ? (
                                <i
                                  className="fa fa-phone"
                                  title="Call to notify"
                                ></i>
                              ) : null}
                            </div>
                            <div></div>
                          </div>
                        </div>
                        {(item.testType === "RT-PCR"
                         || item.testType === "RIDA GENE SARS-CoV-2 RT-PCR test"
                         || item.testType === "Primerdesign COVID-19 genesig RT-PCR assay") ? (
                          <>
                            <div className="ctValueRecod">
                              <div>CT Value:</div>
                              <div style={{ marginLeft: "15px", width: "210px" }}>
                                <input
                                  type="text"
                                  className="ctValueText"
                                  name="ctValue"
                                  value={
                                    item.ctValue !== "" && item.ctValue !== "null"
                                      ? item.ctValue
                                      : this.state.ctValue
                                  }
                                  onChange={(e) =>
                                    this.handleChangeCTValue(
                                      e,
                                      index,
                                      item.ctValue
                                    )
                                  }
                                />
                              </div>
                              <div>Working Number:</div>
                              <div style={{ marginLeft: "15px", width: "210px", background: "white" }}>
                                <input
                                  type="text"
                                  className="ctValueText"
                                  name="workingNumber"
                                  value={
                                    item.workingNumber !== "" && item.workingNumber !== "null"
                                      ? item.workingNumber
                                      : this.state.workingNumber
                                  }

                                  onChange={(e) =>
                                    this.handleChangeWorkingNumber(
                                      e,
                                      index,
                                      item.workingnumber
                                    )
                                  }
                                />
                              </div>                             
                            </div>
                          </>
                        ) : null}
                        <div className="fourthRow">
                          <div className="checkrecodrds">
                            <div>
                              <div className="checkinsurance checkAdj">
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={
                                      item.disableResultCard == true
                                        ? true
                                        : false
                                    }
                                    name={"RecordPos" + index}
                                    onChange={this.isChecked.bind(
                                      this,
                                      index,
                                      "Positive"
                                    )}
                                    checked={
                                      item.testResult == "Positive"
                                        ? true
                                        : false
                                    }
                                    onKeyUp={this.EnterKeySave.bind(this)}
                                  />
                                  Positive
                                </label>
                              </div>
                            </div>

                            <div>
                              <div className="checkinsurance checkAdj">
                                <label>
                                  <input
                                    type="checkbox"
                                    disabled={
                                      item.disableResultCard == true
                                        ? true
                                        : false
                                    }
                                    onChange={this.isChecked.bind(
                                      this,
                                      index,
                                      "Negative"
                                    )}
                                    onKeyUp={this.EnterKeySave.bind(this)}
                                    checked={
                                      item.testResult == "Negative"
                                        ? true
                                        : false
                                    }
                                    name={"RecordNev" + index}
                                  />
                                  Negative
                                </label>
                              </div>
                            </div>

                            <div>
                              <div className="checkinsurance checkAdj">
                                <label>
                                  <input
                                    disabled={
                                      item.disableResultCard == true
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    onKeyUp={this.EnterKeySave.bind(this)}
                                    onChange={this.isChecked.bind(
                                      this,
                                      index,
                                      "Invalid"
                                    )}
                                    checked={
                                      item.testResult == "Invalid"
                                        ? true
                                        : false
                                    }
                                    name={"RecordInter" + index}
                                  />
                                  Invalid
                                </label>
                              </div>
                            </div>
                            {(item.testType === "RT-PCR"
                             || item.testType === "RIDA GENE SARS-CoV-2 RT-PCR test"
                             || item.testType === "Primerdesign COVID-19 genesig RT-PCR assay") ?
                            <div>
                              <div className="checkinsurance checkAdj">
                                <label>
                                  <input
                                    disabled={
                                      item.disableResultCard == true
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    onKeyUp={this.EnterKeySave.bind(this)}
                                    onChange={this.isChecked.bind(
                                      this,
                                      index,
                                      "Inconclusive"
                                    )}
                                    checked={
                                      item.testResult == "Inconclusive"
                                        ? true
                                        : false
                                    }
                                    name={"RecordInter" + index}
                                  />
                                  Inconclusive
                                </label>
                              </div>
                            </div>
                            :""}
                          </div>

                          <div>
                            <div>
                              {item.samplePreSignedUrl != null ? (
                                <div>
                                  <button
                                    className="NextBtn recordAdj resBtn"
                                    onClick={this.OpenPDF.bind(
                                      this,
                                      item.samplePreSignedUrl
                                    )}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <img
                                          src={Lableimg}
                                          className="labelimager"
                                        />
                                      </div>
                                      <div>Request</div>
                                    </div>
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div>
                              {item.smallLabelPreSignedUrl != null ? (
                                <div>
                                  <button
                                    className="NextBtn recordAdj resBtn"
                                    onClick={this.OpenBarcodePDF.bind(
                                      this,
                                      item.smallLabelPreSignedUrl
                                    )}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <img
                                          src={Barcodeimg}
                                          className="labelimager"
                                          style={{marginTop: 'auto', marginBottom: 'auto'}}
                                        />
                                      </div>
                                      <div>Label</div>
                                    </div>
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div>
                              {item.preSignedUrl != null ? (
                                
                                <div>
                                  <button
                                    className="NextBtn recordAdj resBtn"
                                    onClick={this.OpenPDF.bind(
                                      this,
                                      item.preSignedUrl
                                    )}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div>
                                        <img
                                          src={Lableimg}
                                          className="labelimager"
                                        />
                                      </div>
                                      <div>Results Report</div>
                                    </div>
                                  </button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div>
                              <button
                                style={
                                  item.disableResultCard == true
                                    ? { pointerEvents: "none" }
                                    : {
                                      pointerEvents: "auto",
                                      paddingRight: "17px",
                                    }
                                }
                                className="NextBtn recordAdj"
                                onClick={this.ResultApi.bind(
                                  this,
                                  item.sampleAnalysisDate,
                                  item.testResult,
                                  item.patientId,
                                  item.resultId,
                                  this.state.ctValue !== "" ? this.state.ctValue
                                   : item.ctValue !== null ? item.ctValue : "",
                                   this.state.workingNumber !== "" ? this.state.workingNumber
                                   : item.workingNumber !== null ? item.workingNumber : "",
                                  item.testType,
                                  item.testResult
                                )}
                              >
                                Save
                              </button>
                            </div>

                            <div>
                              {item.enableSendNotificationButton === true ? (
                                <button
                                  // style={item.disableResultCard == true ? { pointerEvents: 'none' } : { pointerEvents: "auto" }}
                                  className="recordAdjresend"
                                  // disabled={true}
                                  onClick={this.Resend.bind(
                                    this,
                                    item.resultId,
                                    index
                                  )}
                                >
                                  Resend Notification
                                </button>
                              ) : null}
                            </div>

                            {/* <div>
                            {item.resultId}
                              </div> */}
                          </div>
                        </div>

                        <div className="resultUpdate">
                          {item.resultUpdated == "Updated Result" ? (
                            <div>{item.resultUpdated}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  }
}

export default index;