import React, { Component } from "react";
import "./addpatient.css";
import MaleImg from "../../Image/mars 2.png";
import FemaleImg from "../../Image/femenine.png";
import calendar from "../../Image/calendar.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Info from "../../Image/info.png";
import addPrimary from "../../Image/dailyAdd.png";
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, Paper, Grid } from '@material-ui/core';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import AddBoxIcon from '@material-ui/icons/AddBox';
export class addPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPrimaryImg: true,
      isLoaded: false,
      toogleNext: false,
      checked: false,
      Primarychecked: false,
      raceNames: [],
      EthnicityNames: [],
      firstName: "",
      lastName: "",
      middleName: "",
      dob: null,
      race: "",
      ethnicity: "",
      gender: "",
      pregnant: "",
      occupation: "",
      sampleIdentifier: "",
      mobileNumber: "",
      dialCode: "",
      email: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      country: "",
      state: localStorage.getItem("state"),
      street: "",
      zipCode: "",
      county: "",
      countySelection: [],
      prioritizedGroup: "",
      firstTest: "",
      symptomatic: "",
      hospitalized: "",
      icu: "",
      symptomaticDate: "",
      testType: "",
      primaryInsuranceCompanyName: "",
      primarySubscriberName: "",
      primaryDob: null,
      primaryMemberId: "",
      primaryGroupId: "",
      secondaryInsuranceCompanyName: "",
      secondarySubscriberName: "",
      secondaryDob: null,
      secondaryMemberId: "",
      secondaryGroupId: "",
      mediCarepolicyId: "",
      mediCareSubscriberName: "",
      secondaryInfo: false,
      NA: false,
      save: false,
      infoPatient: "",
      tooglePrimary: false,
      countyTemp: [],
      errorDate: false,
      PrimaryerrorDate: false,
      SecondaryerrorDate: false,
      Statename: [],
      Countryname: [],
      cOneNA: false,
      cOneFirstName: "",
      cOneLastName: "",
      cOneHomeNumber: "",
      cOneMobileNumber: "",
      cOneemail: "",
      cTwoNA: false,
      cTwoFirstName: "",
      cTwoLastName: "",
      cTwoHomeNumber: "",
      cTwoMobileNumber: "",
      cTwoemail: "",
      expand_one: false,
      expand_two: false,
    };

    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          showPrimaryImg: true,
          isLoaded: false,
          toogleNext: false,
          checked: false,
          Primarychecked: false,
          raceNames: [],
          EthnicityNames: [],
          firstName: "",
          lastName: "",
          middleName: "",
          dob: null,
          race: "",
          ethnicity: "",
          gender: "",
          pregnant: "",
          occupation: "",
          sampleIdentifier: "",
          mobileNumber: "",
          dialCode: "",
          email: "",
          addressOne: "",
          addressTwo: "",
          city: "",
          country: "",
          state: localStorage.getItem("state"),
          street: "",
          zipCode: "",
          county: "",
          countySelection: [],
          prioritizedGroup: "",
          firstTest: "",
          symptomatic: "",
          hospitalized: "",
          icu: "",
          symptomaticDate: "",
          testType: "",
          primaryInsuranceCompanyName: "",
          primarySubscriberName: "",
          primaryDob: null,
          primaryMemberId: "",
          primaryGroupId: "",
          secondaryInsuranceCompanyName: "",
          secondarySubscriberName: "",
          secondaryDob: null,
          secondaryMemberId: "",
          secondaryGroupId: "",
          mediCarepolicyId: "",
          mediCareSubscriberName: "",
          secondaryInfo: false,
          NA: false,
          save: false,
          infoPatient: "",
          tooglePrimary: false,
          countyTemp: [],
          errorDate: false,
          PrimaryerrorDate: false,
          SecondaryerrorDate: false,
          Statename: [],
          Countryname: [],
        });
      }
    } else {
    }
  }
  componentDidMount() {    
    // country dropdown
    api.Get_Country_List((res) => {
      if (res.statusCode === 200) {
        this.setState({
          Countryname: res.info,
        });
      } else {
        toast.error(res.errorMessage);
      }
    });

    /*     api.Get_State_County((res) => {
      let a = Object.entries(res.info.states);
      let getlocal = localStorage.getItem("state");
      let b;
      a.map((item) => {
        if (item[1] === getlocal) {
          b = item[0];
        }
      });
      this.setState({
        Statename: Object.entries(res.info.states),
      });
    }); */

    api.Get_Patient_Race((res) => {
      console.log("Get_Patient_Race", res);
      this.setState({
        raceNames: res.info.races,
        EthnicityNames: res.info.ethinicitys,
      });
    });
  }

  expandOneOnChange() {
    this.setState({ expand_one: !this.state.expand_one });
  }
  expandTwoOnChange() {
    this.setState({ expand_two: !this.state.expand_two });
  }

  NextFun() {
    const {
      firstName,
      lastName,
      middleName,
      mobileNumber,
      email,
      addressOne,
      addressTwo,
      city,
      country,
      zipCode,
      county,
      occupation,
      primaryInsuranceCompanyName,
      primarySubscriberName,
      primaryMemberId,
      primaryGroupId,
      secondaryInsuranceCompanyName,
      secondarySubscriberName,
      secondaryMemberId,
      secondaryGroupId,
      mediCarepolicyId,
      ethnicity,
      gender,
      state,
      prioritizedGroup,
      dob,
      primaryDob,
      secondaryDob,
      race,
      secondaryInfo,
      NA,
    } = this.state;

    // Validation ---------------------------------------------------------------------------------------------
    let arrFlag = 0;
    console.log("zipCode.length", zipCode.length);
    if (firstName === "") {
      arrFlag = 1;
      toast.error("Please enter First Name");
      this.setState({
        toogleNext: false,
      });
    } else if (lastName === "") {
      arrFlag = 1;
      toast.error("Please enter Last Name");
      this.setState({
        toogleNext: false,
      });
    } else if (dob === null) {
      arrFlag = 1;
      toast.error("Please select Date of Birth");
      this.setState({
        toogleNext: false,
      });
    } else if (
      dob == "Invalid Date" ||
      moment(dob).format("yyyy") <= "1899" == true ||
      dob > new Date() == true
    ) {
      arrFlag = 1;
      toast.error("Please enter valid DOB ");
      // return 0;
    } else if (gender === "") {
      arrFlag = 1;
      toast.error("Please select Gender");
      this.setState({
        toogleNext: false,
      });
    } else if (race == "") {
      arrFlag = 1;
      toast.error("Please select Race");
      this.setState({
        toogleNext: false,
      });
    } else if (ethnicity == "") {
      arrFlag = 1;
      toast.error("Please select Ethnicity");
      this.setState({
        toogleNext: false,
      });
    } else if (mobileNumber.slice(this.state.dialCode.length) === "") {
      arrFlag = 1;
      toast.error("Please enter Phone number");
      this.setState({
        toogleNext: false,
      });
    } else if (mobileNumber.slice(this.state.dialCode.length).length < 8) {
      arrFlag = 1;
      toast.error("Please enter valid Phone number");
      this.setState({
        toogleNext: false,
      });
    } else if (email === "") {
      if (NA == false) {
        arrFlag = 1;
        toast.error("Please enter Email");
        this.setState({
          toogleNext: false,
        });
      } else if (addressOne === "") {
        arrFlag = 1;
        toast.error("Please enter Address 1");
        this.setState({
          toogleNext: false,
        });
      } else if (country === "") {
        arrFlag = 1;
        toast.error("Please select country");
        this.setState({
          toogleNext: false,
        });
      } else if (state === "") {
        arrFlag = 1;
        toast.error("Please select a state");
        this.setState({
          toogleNext: false,
        });
      } else if (zipCode === "") {
        arrFlag = 1;
        toast.error("Please enter Zipcode");
        this.setState({
          toogleNext: false,
        });
      } else if (
        zipCode.length === 0 ||
        zipCode.length < 5 ||
        zipCode.length > 7
      ) {
        arrFlag = 1;
        toast.error("Please enter valid Zipcode");
        this.setState({
          toogleNext: false,
        });
      } else if (city === "" && this.state.countyTemp.length === 0) {
        arrFlag = 1;
        toast.error("Please enter city");
        this.setState({
          toogleNext: false,
        });
      }
      else if (county === "" && this.state.countyTemp.length !== 0) {
        arrFlag = 1;
        toast.error("Please select county");
        this.setState({
          toogleNext: false,
        });
      }
    } else if (email != "") {
      if (addressOne === "") {
        arrFlag = 1;
        toast.error("Please enter Address 1");
        this.setState({
          toogleNext: false,
        });
      } else if (state === "") {
        arrFlag = 1;
        toast.error("Please select a state");
        this.setState({
          toogleNext: false,
        });
      } else if (zipCode === "" || zipCode === null) {
        arrFlag = 1;
        toast.error("Please enter Zipcode");
        this.setState({
          toogleNext: false,
        });
      } else if (
        zipCode.length === 0 ||
        zipCode.length < 5 ||
        zipCode.length >= 7
      ) {
        arrFlag = 1;
        toast.error("Please enter valid Zipcode");
        this.setState({
          toogleNext: false,
        });
      } else if (city === "" && this.state.countyTemp.length === 0) {
        arrFlag = 1;
        toast.error("Please enter City");
        this.setState({
          toogleNext: false,
        });
      }
    }
    if (arrFlag == 0) {
      this.setState({
        toogleNext: true,
      });
    }
  }
  //-----------------------------------------------------------------------------------------------------------
  // Back button function
  BackFun() {
    this.setState({
      toogleNext: false,
    });
  }
  //-----------------------------------------------------------------------------------------------------------

  isChecked(event) {
    this.setState({
      checked: event.target.checked,
    });

    if (event.target.checked === false) {
      this.setState({
        secondaryInsuranceCompanyName: "",
        secondarySubscriberName: "",
        secondaryDob: null,
        secondaryMemberId: "",
        secondaryGroupId: "",
      });
    }
  }
  isCheckedPrimary(event) {
    this.setState({
      Primarychecked: event.target.checked,
    });

    if (event.target.checked === false) {
      this.setState({
        primaryInsuranceCompanyName: "",
        primarySubscriberName: "",
        primaryDob: null,
        primaryMemberId: "",
        primaryGroupId: "",
      });
    }
  }
  isParentChecked(event) {
    this.setState({
      cOneNA: event.target.checked,
    });

    if (this.state.cOneNA === true) {
      this.setState({
        cOneemail: "",
      });
    }
  }
  isParentChecked1(event) {
    this.setState({
      cTwoNA: event.target.checked,
    });

    if (this.state.cTwoNA === true) {
      this.setState({
        cTwoemail: "",
      });
    }
  }
  isChecked1(event) {
    this.setState({
      NA: event.target.checked,
    });
  }
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value.replaceAll('"',"'");
    if (val != " ") {
      this.setState({ [nam]: val.replaceAll("''","'") });
    }
  };
  // Date selection----------------------------------------------------------------------------------------------------
  handleDateChange(date) {
    this.setState({ dob: date });
  }

  handleDateChangePrimary(date) {
    // if (date > new Date() || date == "Invalid Date") {

    //   this.setState({ PrimaryerrorDate: true });
    // } else if (date != null) {
    //   this.setState({ primaryDob: date, PrimaryerrorDate: false });
    // } else if (date == null) {
    //   this.setState({ primaryDob: null, PrimaryerrorDate: false });
    // }

    this.setState({ primaryDob: date });
  }

  handleDateChangeSecondary(date) {
    // if (date > new Date() || date == "Invalid Date") {
    //   if ((date > new Date()) || (date == "Invalid Date")) {
    //   this.setState({ SecondaryerrorDate: true });
    // } else if (date != null) {
    //   this.setState({ secondaryDob: date, SecondaryerrorDate: false });
    // } else if (date == null) {
    //   this.setState({ secondaryDob: null, SecondaryerrorDate: false });
    // }

    this.setState({ secondaryDob: date });
  }
  // ------------------------------------------------------------------------------------------------------------------------
  // Checkbox selection
  setPriority(event) {
    this.setState({ prioritizedGroup: event.target.value });
  }

  setEthnicity(event) {
    this.setState({ ethnicity: event.target.value });
  }

  setGender(event) {
    this.setState({ gender: event.target.value });
  }
  // ------------------------------------------------------------------------------------------------------------------------
  CountryChange(event) {
    this.setState({
      country: event.target.value,
      countyTemp: [],
    });

    this.state.Countryname.map((item) => {
      if (item.countryCode === event.target.value) {
        let data = {
          countryId: item.id,
        };

        // siga
        api.Get_State_List(data, (res) => {
          if (res.statusCode === 200) {
            this.setState({
              state: "",
              county: "",
              city: "",
            });
            let allEntries = Object.entries(res.info.states);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            this.setState({
              Statename: sortedEntries,
            });
          } else {
            toast.error(res.errorMessage);
          }
        });
      }
    });
  }

  // State and county selection
  StateChange(event) {
    if (event.target.value === "") {
      this.setState({
        countyTemp: [],
      });
    }
    let check1 = event.target.value.split(",");
    this.setState({
      state: event.target.value,
      county: "",
      city: "",
    });

    this.state.Statename.map((item) => {
      if (item[0] == check1[1]) {
        let data = {
          stateId: item[1].id,
        };

        // siga
        api.Get_City_List(data, (res) => {
          if (res.statusCode === 200) {
            let allEntries = Object.entries(res.info.cities);
            let sortedEntries = allEntries.sort((a, b) =>
              a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
            );
            this.setState({
              countyTemp: sortedEntries,
            });
          } else {
            toast.error(res.errorMessage);
          }
        });
      }
    });
  }
  countyChange(event) {
    this.setState({
      county: event.target.value,
    });
  }
  StateChangeRace(event) {
    this.setState({ race: event.target.value });
  }
  cOneHomeNumberChange(number, e) {
    this.setState({ cOneHomeNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  cOneMobileNumberChange(number, e) {
    this.setState({ cOneMobileNumber: number });
    this.setState({ dialCode: e.dialCode });
  }

  cTwoHomeNumberChange(number, e) {
    this.setState({ cTwoHomeNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  cTwoMobileNumberChange(number, e) {
    this.setState({ cTwoMobileNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  // ------------------------------------------------------------------------------------------------------------------------
  // Mobile number onchange
  mobileNumberChange(number, e) {
    this.setState({ mobileNumber: number });
    this.setState({ dialCode: e.dialCode });
  }
  // ------------------------------------------------------------------------------------------------------------------------
  // Submit form with API call
  handleSubmit(e) {
    const {
      firstName,
      lastName,
      middleName,
      mobileNumber,
      email,
      addressOne,
      addressTwo,
      city,
      country,
      zipCode,
      county,
      occupation,
      primaryInsuranceCompanyName,
      primarySubscriberName,
      primaryMemberId,
      primaryGroupId,
      secondaryInsuranceCompanyName,
      secondarySubscriberName,
      secondaryMemberId,
      secondaryGroupId,
      mediCarepolicyId,
      secondaryInfo,
      ethnicity,
      gender,
      state,
      prioritizedGroup,
      dob,
      primaryDob,
      secondaryDob,
      race,
      checked,
      NA,
      cOneFirstName,
      cOneLastName,
      cOneHomeNumber,
      cOneMobileNumber,
      cOneemail,
      cTwoFirstName,
      cTwoLastName,
      cTwoHomeNumber,
      cTwoMobileNumber,
      cTwoemail,
      cOneNA,
      cTwoNA,
    } = this.state;
    // e.preventDefault();
    // let arrFlag = 0;
    if (prioritizedGroup === "") {
      toast.error("Please select the Prioritized Group ");
      this.setState({
        toogleNext: true,
      });

      return;
    }
    if (prioritizedGroup != "" && occupation == "") {
      toast.error("Please enter occupation ");
      this.setState({
        toogleNext: true,
      });
      return;
    }

    if (primaryDob) {
      if (
        primaryDob == "Invalid Date" ||
        moment(primaryDob).format("yyyy") <= "1899" == true ||
        primaryDob > new Date() == true
      ) {
        // arrFlag = 1;
        toast.error("Please enter valid Primary DOB ");
        return;
      }
    }
    if (secondaryDob) {
      if (
        secondaryDob == "Invalid Date" ||
        moment(secondaryDob).format("yyyy") <= "1899" == true ||
        secondaryDob > new Date() == true
      ) {
        // arrFlag = 1;
        toast.error("Please enter valid Secondary DOB ");
        return;
      }
    }
    let stateSplit = state.split(",");
    let data = {
      patientId: 0,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      middleName: middleName.trim(),
      mobileNumber: "+" + mobileNumber,
      email: NA == true ? "N/A" : email.trim(),
      addressOne: addressOne.trim(),
      addressTwo: addressTwo.trim(),
      city: this.state.countyTemp.length === 0 ? city.trim() : county,
      country: country,
      zipCode: zipCode.trim(),
      county: county.length == undefined ? null : county,
      occupation: occupation.trim(),
      phoneCode: "+" + this.state.dialCode,
      primaryInsuranceCompanyName: primaryInsuranceCompanyName.trim(),
      primarySubscriberName: primarySubscriberName.trim(),
      primaryMemberId: primaryMemberId.trim(),
      primaryGroupId: primaryGroupId.trim(),
      primaryDob:
        this.state.primaryDob != null
          ? moment(this.state.primaryDob).format("yyyy-MM-DD")
          : this.state.primaryDob,
      secondaryInsuranceCompanyName: secondaryInsuranceCompanyName.trim(),
      secondarySubscriberName: secondarySubscriberName.trim(),
      secondaryMemberId: secondaryMemberId.trim(),
      secondaryGroupId: secondaryGroupId.trim(),
      mediCarepolicyId: mediCarepolicyId.trim(),
      secondaryInfo: checked,
      secondaryDob:
        this.state.secondaryDob != null
          ? moment(this.state.secondaryDob).format("yyyy-MM-DD")
          : this.state.secondaryDob,

      //radio
      ethnicity: ethnicity,
      gender: gender,
      prioritizedGroup: prioritizedGroup,
      //select
      state: stateSplit[0].toString(),
      race: race,

      //date
      dob:
        this.state.dob != null
          ? moment(this.state.dob).format("yyyy-MM-DD")
          : this.state.dob,
      contactOneFirstName: cOneFirstName.trim(),
      contactOneLastName: cOneLastName.trim(),
      contactOneMobileNumber: cOneMobileNumber != "" ? "+" + cOneMobileNumber : "",
      contactOneHomePhone: cOneHomeNumber != "" ? "+" + cOneHomeNumber : "",
      contactOneEmail: cOneNA == true ? "N/A" : cOneemail,
      contactTwoFirstName: cTwoFirstName.trim(),
      contactTwoLastName: cTwoLastName.trim(),
      contactTwoMobileNumber: cTwoMobileNumber != "" ? "+" + cTwoMobileNumber : "",
      contactTwoHomePhone: cTwoHomeNumber != "" ? "+" + cTwoHomeNumber : "",
      contactTwoEmail: cTwoNA == true ? "N/A" : cTwoemail,
    };
    this.setState({
      isLoaded: true,
    });

    api.AddpatientAPI(data, (res) => {
      if (res.statusCode === 200) {
        this.setState({
          save: true,
          isLoaded: false,
        });
        toast.success("Patient added successfully");
        this.setState({ infoPatient: res.info.patientId });
      } else if (res.errorCode === 400) {
        toast.error(res.errorMessage);
        this.setState({ isLoaded: false });
      }
    });
  }
  // ------------------------------------------------------------------------------------------------------------------------
  // Redirect to collect sample page after API success
  sendCollectSample(e) {
    this.props.history.push({
      pathname: "/collectsample",
      state: this.state.infoPatient,
    });
  }
  // ------------------------------------------------------------------------------------------------------------------------
  // Press Enter key for submit
  EnterKeynext(evt) {
    if (evt.keyCode == 13) {
      this.NextFun();
    }
  }
  EnterKeySave(evt) {
    if (evt.keyCode == 13) {
      this.handleSubmit();
    }
  }
  // ------------------------------------------------------------------------------------------------------------------------
  render() {
    return (
      <>
        {/* Loader */}
        <ToastContainer autoClose={2000} />
        {this.state.isLoaded == true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          // Add patient form 1st page
          <div id="addPatient">
            {this.state.toogleNext === false ? (
              <>
                <div className="patientTitle">Add Patient</div>
                <div className="patientInfo">
                  Please enter the following information about the patient.
                </div>

                <div className="addInput firstRowAdd">
                  <div>
                    <label className="ethnicit">First name *</label>

                    <input
                      type="text"
                      className="PatientInput"
                      name="firstName"
                      onChange={this.myChangeHandler}
                      value={this.state.firstName}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      pattern="[^\s]+"
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Middle Initial</label>

                    <input
                      type="text"
                      className="PatientInput"
                      name="middleName"
                      onChange={this.myChangeHandler}
                      value={this.state.middleName}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      pattern="[^\s]+"
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Last Name *</label>

                    <input
                      type="text"
                      className="PatientInput"
                      name="lastName"
                      onChange={this.myChangeHandler}
                      value={this.state.lastName}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      pattern="[^\s]+"
                    />
                  </div>
                </div>

                <div className="addInput secondRowAdd">
                  <div className="datepickerstyleAdder">
                    <label className="ethnicit">
                      Date of Birth (MM/DD/YYYY)*
                    </label>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        placeholder="MM/DD/YYYY"
                        clearable
                        value={this.state.dob}
                        format="MM/dd/yyyy"
                        maxDate={new Date()}
                        required
                        autoOk
                        onKeyUp={this.EnterKeynext.bind(this)}
                        onChange={(date) => this.handleDateChange(date)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div>
                    <label className="ethnicit">Gender *</label>
                    <div>
                      <select
                        className="PatientSelect"
                        onChange={this.setGender.bind(this)}
                        onKeyUp={this.EnterKeynext.bind(this)}
                        value={this.state.gender}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Ambiguous">Non-binary</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="addInput thirdRowAdd">
                  <div>
                    <label className="ethnicit">Race *</label>
                    <div>
                      <select
                        className="PatientSelect"
                        onKeyUp={this.EnterKeynext.bind(this)}
                        onChange={this.StateChangeRace.bind(this)}
                        value={this.state.race}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {this.state.raceNames.map((index) => (
                          <option value={index.raceName}>
                            {index.raceName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="ethnicit">Ethnicity * </label>
                    <br />

                    <div>
                      <select
                        className="PatientSelect"
                        onKeyUp={this.EnterKeynext.bind(this)}
                        onChange={this.setEthnicity.bind(this)}
                        value={this.state.ethnicity}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        {this.state.EthnicityNames.map((index) => (
                          <option value={index.ethinicityName}>
                            {index.ethinicityName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="addInput fourthRowAdd">
                  <div>
                    <label className="ethnicit">Mobile Number * </label>
                    <PhoneInput
                      country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      onChange={this.mobileNumberChange.bind(this)}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.mobileNumber}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Email </label>
                    <input
                      type="text"
                      className="PatientInput"
                      name="email"
                      disabled={this.state.NA == true ? true : false}
                      onChange={this.myChangeHandler}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.NA == true ? "N/A" : this.state.email}
                    />
                  </div>
                  <div>
                    <div className="adjNa">
                      <input
                        type="checkbox"
                        onKeyUp={this.EnterKeynext.bind(this)}
                        onChange={this.isChecked1.bind(this)}
                        checked={this.state.NA}
                        id="na"
                      />
                      <label for="na">N/A - Not Applicable</label>
                    </div>
                  </div>
                </div>
                <Accordion className="accordionOne" style={{ marginTop: "20px" }}>
                  <AccordionSummary
                    onClick={this.expandOneOnChange.bind(this)}
                    expandIcon={this.state.expand_one ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ color: "#00afb9" }}>Emergency Contact 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box margin={1} style={{ width: "100%" }}>
                      <Paper elevation={4} style={{ width: "100%", padding: "20px", margin: "5px 0px" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">First Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cOneFirstName"
                              onChange={this.myChangeHandler}
                              value={this.state.cOneFirstName}
                              onKeyUp={this.EnterKeynext.bind(this)}
                              pattern="[^\s]+"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Last Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cOneLastName"
                              onChange={this.myChangeHandler}
                              value={this.state.cOneLastName}
                              onKeyUp={this.EnterKeynext.bind(this)}
                              pattern="[^\s]+"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Home Phone Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cOneHomeNumberChange.bind(this)}
                              value={this.state.cOneHomeNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Mobile Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cOneMobileNumberChange.bind(this)}
                              value={this.state.cOneMobileNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Email</label>
                            <br />
                            <input
                              type="text"
                              className="PatientInput"
                              name="cOneemail"
                              disabled={this.state.cOneNA == true ? true : false}
                              onChange={this.myChangeHandler}
                              onKeyUp={this.EnterKeynext.bind(this)}
                              value={this.state.cOneNA == true ? "N/A" : this.state.cOneemail}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            <input
                              type="checkbox"
                              onChange={this.isParentChecked.bind(this)}
                              checked={this.state.cOneNA}
                              id="cOneNA"
                            />
                            <label for="cOneNA">N/A - Not Applicable</label>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordionTwo" style={{ marginTop: "15px" }}>
                  <AccordionSummary
                    expandIcon={this.state.expand_two ? <IndeterminateCheckBoxIcon /> : <AddBoxIcon />}
                    onClick={this.expandTwoOnChange.bind(this)}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ color: "#00afb9" }}>Emergency Contact 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box margin={1} style={{ width: "100%" }}>
                      <Paper elevation={4} style={{ width: "100%", padding: "20px", margin: "5px 0px" }}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">First Name</label>
                            <input
                              type="text"
                              className="PatientInput"
                              name="cTwoFirstName"
                              onChange={this.myChangeHandler}
                              value={this.state.cTwoFirstName}
                              onKeyUp={this.EnterKeynext.bind(this)}
                              pattern="[^\s]+"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Last Name</label>

                            <input
                              type="text"
                              className="PatientInput"
                              name="cTwoLastName"
                              onChange={this.myChangeHandler}
                              value={this.state.cTwoLastName}
                              onKeyUp={this.EnterKeynext.bind(this)}
                              pattern="[^\s]+"
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Home Phone Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cTwoHomeNumberChange.bind(this)}
                              value={this.state.cTwoHomeNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Mobile Number</label>
                            <PhoneInput
                              country={"us"}
                              // onlyCountries={["us"]}
                              // disableDropdown={true}
                              disabled={this.state.disableFlag == true ? true : false}
                              onChange={this.cTwoMobileNumberChange.bind(this)}
                              value={this.state.cTwoMobileNumber}
                              inputProps={{
                                autoFormat: true,
                                countryCodeEditable: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <label className="ethnicit">Email</label>
                            <br />
                            <input
                              type="text"
                              className="PatientInput"
                              name="cTwoemail"
                              disabled={this.state.cTwoNA == true ? true : false}
                              onChange={this.myChangeHandler}
                              onKeyUp={this.EnterKeynext.bind(this)}
                              value={this.state.cTwoNA == true ? "N/A" : this.state.cTwoemail}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sm={12} >
                            <input
                              type="checkbox"
                              onChange={this.isParentChecked1.bind(this)}
                              checked={this.state.cTwoNA}
                              id="cTwoNA"
                            />
                            <label for="cTwoNA">N/A - Not Applicable</label>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <div className="addInput fifthRowAdd">
                  <div>
                    <label className="ethnicit">Address 1 *</label>

                    <input
                      type="text"
                      className="PatientInput"
                      name="addressOne"
                      onChange={this.myChangeHandler}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.addressOne}
                    />
                  </div>
                  <div>
                    <label className="ethnicit">Address 2</label>

                    <input
                      type="text"
                      className="PatientInput"
                      name="addressTwo"
                      onChange={this.myChangeHandler}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.addressTwo}
                    />
                  </div>
                </div>

                <div className="addInput sixthRowAdd">
                  <div>
                    <label className="ethnicit">Country *</label>

                    <select
                      className="PatientSelect"
                      onChange={this.CountryChange.bind(this)}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.country}
                    >
                      <option value="">Select Country</option>
                      {this.state.Countryname.map((item) => (
                        <option value={item.countryCode}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {/* select State / Territory */}
                    <label className="ethnicit">State / Territory *</label>

                    <select
                      className="PatientSelect"
                      onChange={this.StateChange.bind(this)}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.state}
                    >
                      <option value="">Select State / Territory</option>
                      {this.state.Statename.map((item) => (
                        <option value={item[1].stateCode + "," + item[0]}>
                          {item[0]}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="addInput seventhRowAdd">
                  <div>
                    <label className="ethnicit">Zip Code / Postal Code *</label>

                    <input
                      type="text"
                      className="PatientInput"
                      name="zipCode"
                      onChange={this.myChangeHandler}
                      onKeyUp={this.EnterKeynext.bind(this)}
                      value={this.state.zipCode}
                    />
                  </div>
                  {/* Select County / District */}
                  {this.state.countyTemp.length !== 0 ? (
                    <div>
                      <label className="ethnicit">County/District *</label>

                      <select
                        className="PatientSelect"
                        onKeyUp={this.EnterKeynext.bind(this)}
                        onChange={this.countyChange.bind(this)}
                        value={this.state.county}
                      >
                        <option value="">Select County/District</option>
                        {this.state.countyTemp ? (
                          this.state.countyTemp.map((item) => (
                            <option value={item[0]}>{item[0]}</option>
                          ))
                        ) : (
                          <option></option>
                        )}
                      </select>
                    </div>
                  ) : (
                    <div>
                      <label className="ethnicit">City / Estate *</label>

                      <input
                        type="text"
                        className="PatientInput"
                        name="city"
                        onChange={this.myChangeHandler}
                        onKeyUp={this.EnterKeynext.bind(this)}
                        value={this.state.city}
                      />
                    </div>
                  )}
                </div>

                <div className="addPButtonAdj">
                  <button className="NextBtn" onClick={this.NextFun.bind(this)}>
                    Next
                  </button>
                </div>
              </>
            ) : (
              // Add patient 2nd page
              <>
                <div className="patientTitle">Add Patient</div>
                <div className="patientInfo">
                  Please enter the following information about the patient.
                </div>
                {/* Patient information */}
                <div className="prioritized">Prioritized Group *</div>
                <div>
                  <div className="prioritizedList">
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="First response/Healthcare"
                          name="prioritizedGroup"
                          onChange={this.setPriority.bind(this)}
                          onKeyUp={this.EnterKeySave.bind(this)}
                          checked={
                            this.state.prioritizedGroup ===
                              "First response/Healthcare"
                              ? true
                              : false
                          }
                        />
                        First responder/Healthcare worker
                      </label>
                      <Tooltip
                        title="Employed as a firefighter, police officer, nurse,etc. ?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>

                    <div>
                      <label>
                        <input
                          type="radio"
                          value="Hospitality/Transportation"
                          name="prioritizedGroup"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          onChange={this.setPriority.bind(this)}
                          checked={
                            this.state.prioritizedGroup ===
                              "Hospitality/Transportation"
                              ? true
                              : false
                          }
                        />
                        Hospitality/Transportation worker
                      </label>
                      <Tooltip
                        title="Employed in a hotel, restaurant, bus line, etc. ?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>
                  </div>

                  <div className="prioritizedList">
                    <div>
                      <label>
                        <input
                          type="radio"
                          value="Congregate facility"
                          name="prioritizedGroup"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          onChange={this.setPriority.bind(this)}
                          checked={
                            this.state.prioritizedGroup ===
                              "Congregate facility"
                              ? true
                              : false
                          }
                        />
                        Congregate facility worker/resident
                      </label>
                      <Tooltip
                        title="Active in an assisted living facility,shelter,prison,etc.?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>

                    <div>
                      <label>
                        <input
                          type="radio"
                          value="In-person school/daycare"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="prioritizedGroup"
                          onChange={this.setPriority.bind(this)}
                          checked={
                            this.state.prioritizedGroup ===
                              "In-person school/daycare"
                              ? true
                              : false
                          }
                        />
                        In-person school/daycare worker
                      </label>
                      <Tooltip
                        title="Working in a school or childcare facility?"
                        placement="top"
                      >
                        <img src={Info} className="InfoTool" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="prioritizedList">
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="None"
                        name="prioritizedGroup"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        onChange={this.setPriority.bind(this)}
                        checked={
                          this.state.prioritizedGroup === "None" ? true : false
                        }
                      />
                      Not a priority group
                    </label>
                  </div>

                  <div>
                    <label>
                      <input
                        type="radio"
                        value="Not reported"
                        name="prioritizedGroup"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        onChange={this.setPriority.bind(this)}
                        checked={
                          this.state.prioritizedGroup === "Not reported"
                            ? true
                            : false
                        }
                      />
                      Not reported
                    </label>
                  </div>
                </div>

                <div className="prioritizedList">
                  <label>
                    <input
                      type="radio"
                      value="Others"
                      name="prioritizedGroup"
                      onKeyUp={this.EnterKeySave.bind(this)}
                      onChange={this.setPriority.bind(this)}
                      checked={
                        this.state.prioritizedGroup === "Others" ? true : false
                      }
                    />
                    Other Group (Please indicate below)
                  </label>
                </div>
                {this.state.prioritizedGroup ? (
                  <div>
                    <label className="ethnicit">Occupation/Affiliation *</label>
                    <div className="addInput">
                      <div className="setFull">
                        <input
                          type="text"
                          className="PatientInput"
                          name="occupation"
                          onChange={this.myChangeHandler}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {/* Primary insurance */}
                <div className="prioritized heighteradj">
                  Primary Insurance Company Information
                </div>
                <div className="checkinsurance">
                  <label>
                    <input
                      type="checkbox"
                      onChange={this.isCheckedPrimary.bind(this)}
                      onKeyUp={this.EnterKeySave.bind(this)}
                      checked={this.state.Primarychecked}
                    />
                    Add primary insurance information
                  </label>
                </div>

                {this.state.Primarychecked == true ? (
                  <div>
                    <div className="prioritized heighteradj">
                      Primary Insurance Company Information
                    </div>

                    <div className="addInput primaryInsuranceRow">
                      <div>
                        <label className="ethnicit">
                          Name of Insurance Company
                        </label>

                        <input
                          type="text"
                          className="PatientInput"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="primaryInsuranceCompanyName"
                          onChange={this.myChangeHandler}
                          value={this.state.primaryInsuranceCompanyName}
                        />
                      </div>
                      <div>
                        <label className="ethnicit">Subscriber’s Name</label>

                        <input
                          type="text"
                          className="PatientInput"
                          name="primarySubscriberName"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          onChange={this.myChangeHandler}
                          value={this.state.primarySubscriberName}
                        />
                      </div>
                    </div>

                    <div className="addInput primaryInsuranceRow">
                      <div className="datepickerstyleAdder">
                        <label className="ethnicit">
                          Date of Birth (MM/DD/YYYY)
                        </label>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            clearable
                            placeholder="MM/DD/YYYY"
                            value={this.state.primaryDob}
                            onChange={(date) =>
                              this.handleDateChangePrimary(date)
                            }
                            format="MM/dd/yyyy"
                            onKeyUp={this.EnterKeySave.bind(this)}
                            maxDate={new Date()}
                            autoOk
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <label className="ethnicit">Member ID#</label>

                        <input
                          type="text"
                          className="PatientInput"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="primaryMemberId"
                          onChange={this.myChangeHandler}
                          value={this.state.primaryMemberId}
                        />
                      </div>
                    </div>

                    <div className="addInput primaryInsuranceRow">
                      <div>
                        <label className="ethnicit">Group ID #</label>

                        <input
                          type="text"
                          className="PatientInput"
                          name="primaryGroupId"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          onChange={this.myChangeHandler}
                          value={this.state.primaryGroupId}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* Secondary incurance */}
                <div className="checkinsurance">
                  <label>
                    <input
                      type="checkbox"
                      onChange={this.isChecked.bind(this)}
                      onKeyUp={this.EnterKeySave.bind(this)}
                      checked={this.state.checked}
                    />
                    Add secondary insurance information
                  </label>
                </div>

                {this.state.checked === true ? (
                  <>
                    <div className="prioritized heighteradj">
                      Secondary Insurance Company Information
                    </div>

                    <div className="addInput secondaryInsuranceRow">
                      <div>
                        <label className="ethnicit">
                          Name of Insurance Company
                        </label>

                        <input
                          type="text"
                          className="PatientInput"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="secondaryInsuranceCompanyName"
                          onChange={this.myChangeHandler}
                          value={this.state.secondaryInsuranceCompanyName}
                        />
                      </div>
                      <div>
                        <label className="ethnicit">Subscriber’s Name</label>

                        <input
                          type="text"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          className="PatientInput"
                          name="secondarySubscriberName"
                          onChange={this.myChangeHandler}
                          value={this.state.secondarySubscriberName}
                        />
                      </div>
                    </div>

                    <div className="addInput secondaryInsuranceRow">
                      <div className="datepickerstyleAdder">
                        <label className="ethnicit">
                          Date of Birth (MM/DD/YYYY)
                        </label>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            clearable
                            placeholder="MM/DD/YYYY"
                            value={this.state.secondaryDob}
                            onChange={(date) =>
                              this.handleDateChangeSecondary(date)
                            }
                            autoOk
                            onKeyUp={this.EnterKeySave.bind(this)}
                            format="MM/dd/yyyy"
                            maxDate={new Date()}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <label className="ethnicit">Member ID#</label>

                        <input
                          type="text"
                          className="PatientInput"
                          name="secondaryMemberId"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          onChange={this.myChangeHandler}
                          value={this.state.secondaryMemberId}
                        />
                      </div>
                    </div>

                    <div className="addInput secondaryInsuranceRow">
                      <div>
                        <label className="ethnicit">Group ID #</label>

                        <input
                          type="text"
                          className="PatientInput"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="secondaryGroupId"
                          onChange={this.myChangeHandler}
                          value={this.state.secondaryGroupId}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="prioritized heighteradj">
                  Medicare Information
                </div>
                <div>
                  <label className="ethnicit">Policy ID #</label>
                </div>
                <div className="addInput">
                  <div className="setFull">
                    <input
                      type="text"
                      className="PatientInput"
                      onKeyUp={this.EnterKeySave.bind(this)}
                      name="mediCarepolicyId"
                      onChange={this.myChangeHandler}
                      value={this.state.mediCarepolicyId}
                    />
                  </div>
                </div>
                {/* Succuess message */}
                {/* Back & Save button  */}
                <div className="addPButtonAdja">
                  {this.state.save == false ? (
                    <>
                      <div>
                        <button
                          className="NextBtn"
                          onClick={this.BackFun.bind(this)}
                        >
                          Back
                        </button>
                      </div>
                      <div>
                        <button className="NextBtn" onClick={this.handleSubmit}>
                          Save
                        </button>
                      </div>
                    </>
                  ) : (
                    // Redirect to collect sample page
                    <button
                      className="NextBtn"
                      onClick={this.sendCollectSample.bind(this)}
                    >
                      Collect Sample
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

export default addPatient;
