import React from "react";

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";

function TableHeader({ handleSort, orderBy, order, setOrder }) {
  const createSortHandler = (id) => {
    handleSort(id);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center"></TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy.includes("slotDate")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("slotDate")}
          >
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("slotTime")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("slotTime")}
          >
            Slot
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("lastName")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("lastName")}
          >
            Last Name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("firstName")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("firstName")}
          >
            First Name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("dob")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("dob")}
          >
            DOB
          </TableSortLabel>
        </TableCell>

        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("mobileNumber")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("mobileNumber")}
          >
            Mobile Number
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("confirmationCode")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("confirmationCode")}
          >
            Confirmation Code
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("receivedCovid19Vaccine")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("receivedCovid19Vaccine")}
          >
            Previously received vaccine dose
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("createdIn")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("createdIn")}
          >
            Appointment Author
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("email")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("email")}
          >
            Email
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("sendMessageToMobile")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("sendMessageToMobile")}
          >
            Can Receive SMS?
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("createdDate")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("createdDate")}
          >
            Appointment Created
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={orderBy.includes("testType")}
            direction={order === "asc" ? "asc" : "desc"}
            onClick={() => createSortHandler("testType")}
          >
            Test Type
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
