import React, { useEffect } from "react";
// import { useForm } from '@fuse/hooks';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
// import "./TableDash.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import { useHistory } from "react-router-dom";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    // maxHeight: 440,
    width: "100%",
  },
});

export default function TableDash(props) {
  let history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(
    props.location.state.checkpageno ? props.location.state.checkpageno : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [listData, setlistData] = React.useState([]);
  const [tableerrordata, setTableErrordata] = React.useState();
  const [totalcount, setTotalCount] = React.useState();
  const [RescheduleList, SetRescheduleList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [Cancelopen, CancelsetOpen] = React.useState(false);
  const [AlertCancelopen, AlertCancelsetOpen] = React.useState(false);
  const [AlertReschduleopen, AlertReschduleSetopen] = React.useState(false);

  const [vaccinationId, SetvaccinationId] = React.useState("");

  const [uuid, setUUID] = React.useState(false);

  useEffect(() => {
    console.log("return pageno->", props.location.state.checkpageno);
    setlistData(props.location.state.info);
    setTotalCount(props.location.state.totalListCount);
  }, [page, setRowsPerPage]);

  const handleChangePage = (event, page1) => {
    window.scrollTo(0, 0);
    setPage(page1);
    searchTable(page1);
  };
  const searchTable = (pageno) => {
    const favColors = Object.keys(props.location.state.checkStatus).filter(
      (key) => props.location.state.checkStatus[key]
    );
    setIsLoaded(true);
    let data = {
      pageNo: pageno,
      lastName: props.location.state.checklname,
      dob:
        props.location.state.checkdob != ""
          ? moment(props.location.state.checkdob).format("yyyy-MM-DD")
          : props.location.state.checkdob,
      sampleCollectedDate:
        props.location.state.checksampleCollectedDate != ""
          ? moment(props.location.state.checksampleCollectedDate).format(
              "yyyy-MM-DD"
            )
          : props.location.state.checksampleCollectedDate,
      notificationStatus: favColors ? favColors : {},
    };
    api.SMSstatus(data, (response) => {
      if (response.statusCode == "200") {
        setIsLoaded(false);
        setlistData(response.info);
        setTotalCount(response.totalListCount);
      } else {
        setIsLoaded(false);
        setlistData([]);
        setTotalCount();
      }
    });
  };
  const searchagain = () => {
    history.push("/notification");
  };
  const openEdit = (patientId) => {
    setIsLoaded(true);
    let data = {
      patientId: patientId,
    };
    api.Getpatientdetails(data, (response) => {
      if (response.statusCode == "200") {
        let checkdata = {
          checklname: props.location.state.checklname
            ? props.location.state.checklname
            : "",
          checksampleCollectedDate: props.location.state
            .checksampleCollectedDate
            ? props.location.state.checksampleCollectedDate
            : "",
          checkdob: props.location.state.checkdob
            ? props.location.state.checkdob
            : "",
          checkStatus: props.location.state.checkStatus
            ? props.location.state.checkStatus
            : {},
          checkpageno: page ? page : 0,
        };
        let resposneCheck = response.info;
        var attributes = Object.keys(checkdata);

        for (var i = 0; i < attributes.length; i++) {
          resposneCheck[attributes[i]] = checkdata[attributes[i]];
        }
        history.push({
          pathname: "/details",
          state: resposneCheck,
        });
        localStorage.setItem("fromvalue", "notification");
      } else {
        setIsLoaded(false);
      }
    });
  };
  const ResendNotification = (resultId) => {
    setIsLoaded(true);
    let data = {
      resultId: resultId,
    };

    api.ResendNotification(data, (response) => {
      if (response.statusCode === 200) {
        setIsLoaded(false);
        toast.success(response.info);
      } else {
        setIsLoaded(false);
        toast.error(response.errorMessage);
      }
    });
  };

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|2|3|4|5)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };
  // ----------------------------------------------------------------------------------------------//
  return (
    <>
      <ToastContainer />

      <Grid container>
        {isLoaded === true ? (
          <Paper
            className={classes.root}
            style={{
              // marginTop: "2em",
              width: "90%",
              margin: "2em auto",
              boxShadow: "unset !important",
            }}
          >
            <div className="loaderAdj">
              <CircularProgress />
            </div>
          </Paper>
        ) : (
          <Grid item xs={12} md={12} sm={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginLeft: "30px" }}>
                <h1 style={{ paddingTop: "20px" }} className="notifi">
                  Notification​
                </h1>
              </div>
              <div
                style={{
                  paddingTop: "40px",
                  paddingRight: "68px",
                  marginRight: "36%",
                }}
              >
                <button className="searchAgain" onClick={searchagain}>
                  Search Again
                </button>
              </div>
            </div>
            <div className="gridcontent">
              <Paper
                className={classes.root}
                style={{
                  margin: "2em 2em",
                }}
              >
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Patient F.Name</TableCell>
                        <TableCell>Patient L.Name</TableCell>
                        <TableCell>DOB (MM-DD-YYYY)</TableCell>
                        <TableCell>Appointment Date</TableCell>
                        <TableCell>Notification Method</TableCell>
                        <TableCell>Phone Number</TableCell>
                        <TableCell>SMS Status</TableCell>
                        <TableCell>SMS Date</TableCell>
                        <TableCell>SMS Message Body</TableCell>
                        <TableCell>Email Address </TableCell>
                        <TableCell>Email Status</TableCell>
                        <TableCell>Email Date</TableCell>
                        <TableCell>Email Message Body</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listData.length > 0 ? (
                        listData.map((row) => (
                          <TableRow>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.firstName}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.lastName}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {moment(row.dob).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {moment(row.appointmentDate).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.notificationMethod}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {formatPhoneNumberIntl(row.toNumber)}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.hbSmsStatus} ({row.smsStatus})
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {moment(row.smsDateSent).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.smsMessageBody}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.toEmail}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.hbEmailStatus} ({row.emailStatus})
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {moment(row.emailDateSent).format("MM-DD-YYYY")}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              {row.emailBody}
                            </TableCell>
                            <TableCell style={{ verticalAlign: "baseline" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <div
                                  className="viewP"
                                  style={{
                                    marginLeft: "20px",
                                    fontSize: "15px",
                                  }}
                                  onClick={() => {
                                    openEdit(row.patientId);
                                  }}
                                >
                                  Edit
                                </div>
                                <div>
                                  <div
                                    className="resnd"
                                    style={{
                                      marginLeft: "20px",
                                      fontSize: "15px",
                                    }}
                                    onClick={() => {
                                      ResendNotification(row.testResultId);
                                    }}
                                  >
                                    Resend Notification
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableCell>No status found</TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {totalcount > 10 ? (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalcount !== undefined ? totalcount : ""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                ) : (
                  ""
                )}
              </Paper>
            </div>
          </Grid>
        )}
      </Grid>
      {/* <Footer /> */}
    </>
  );
}
