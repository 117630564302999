import React from "react";
import Login from "../src/Components/Login/index.js";
import Otp from "../src/Components/Login/OTP.js";
import RoleLogin from "../src/Components/Login/roleLogin.js";
import Menu from "./Components/Menu/Menu.js";
import PatientsDetails from "../src/Components/PatientsDetails/patientdetails.js";
import { BrowserRouter as Router } from "react-router-dom";
import { Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import Notifications from './Components/Notifications/tableindex.js'
import Pdfview from '../src/Components/pdfviewer.js'
import { Helmet } from "react-helmet";
import Tablecomponents from '../src/Components/Appointmentchanges/table.js'
import FeedbackIcon from "./Image/FeedbackIcon.png";
function App() {
  if (localStorage.getItem("isLoggedIn") === "true") {
    if (window.location.pathname == "/otp" || window.location.pathname == "/" || window.location.pathname == "/role") {
      window.location.replace("/patientsearch");
    }
  }
  const [sessionTimeOut, setsessionTimeOut] = React.useState(false);

  React.useEffect(() => {

    const x = document.cookie;
    if (x === "") {
      localStorage.clear();
    }
    if (sessionTimeOut === true) {

      localStorage.clear()
      window.location.replace("/");
    }
  }, [sessionTimeOut]);

  const handleOnIdle = (event) => {
    if (event === undefined) {
      setsessionTimeOut(true);
    }
  };

  const handleOnActive = (event) => {
  };

  const handleOnAction = (e) => {
    if (e !== undefined) {
      setsessionTimeOut(false);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        {window.location.hostname.includes('usvi.care') ?
          <title>beaconUSVI</title>
          :
          <title>beaconBVI</title>
        }

        <meta
          name="description"
          content="Hummingbird Health"
        />
      </Helmet>
      <Router>
        <Route path="/" exact component={Login} />
        <Route path="/role" exact component={RoleLogin} />
        <Route path="/otp" exact component={Otp} />
        <Route path="/patientsearch" exact component={Menu} />
        <Route path="/addpatient" exact component={Menu} />
        <Route path="/collectsample" exact component={Menu} />
        <Route path="/appointment-changes" exact component={Menu} />
        <Route path="/report" exact component={Menu} />
        <Route path="/recordresult" exact component={Menu} />
        <Route path="/dailyupdate" exact component={Menu} />
        <Route path="/resupply" exact component={Menu} />
        <Route path="/details" exact component={PatientsDetails} />
        <Route path="/testrecords" exact component={Menu} />
        <Route path="/notification" exact component={Menu} />
        <Route path="/notificationlist" exact component={Notifications} />
        <Route path="/pdf" exact component={Pdfview} />
        <Route path="/appointmentchanges/table" exact component={Tablecomponents} />

      </Router>
      <img className="feedback" id="myCustomTrigger" onClick={()=>window.showCollectorDialog()} src={FeedbackIcon}/>
    </div>
  );
}

export default App;
