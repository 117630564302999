import React, { Component } from "react";
import "./Collectsample.css";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import eye from "../../Image/eye.png";
import "react-datepicker/dist/react-datepicker.css";
import Lableimg from "../../Image/label12.png";
import Barcodeimg from "../../Image/barcode-white.png";
import calendar from "../../Image/calendar.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import api from "../../Service/API/index.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Viewer, { SpecialZoomLevel, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import Modal from "react-modal";
import Closeicon from "../../Image/close.png";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import LabelIcon from '@material-ui/icons/Label';
import printJS from 'print-js'

export class index extends Component {
  testData = "";
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      toogleSearch: true,
      toogleTable: false,
      toogleview: false,
      saveSuccess: false,
      searchFirst: "",
      searchLast: "",
      searchMobile: "",
      dob: null,
      TestOrderdate: new Date(),
      SympomaticDate: null,
      SampleCollectionDate: new Date(),
      raceNames: JSON.parse(localStorage.getItem("testTypes"))
        ? JSON.parse(localStorage.getItem("testTypes"))
        : [],
      race:
        (JSON.parse(localStorage.getItem("labdetails")).id === 79 || 
        JSON.parse(localStorage.getItem("labdetails")).id === 5)
          ? "Covid-19: BinaxNow"
          : "",
      specimen: "",
      gendercheck: "",
      sampleId: "",
      sampleIDAPI: "",
      AltersampleId: "",
      Sympomatic: "",
      Hospitalized:
        JSON.parse(localStorage.getItem("labdetails")).id === 14 ||
        JSON.parse(localStorage.getItem("labdetails")).id === 15 ||
        JSON.parse(localStorage.getItem("labdetails")).id === 31
          ? "N"
          : "N",
      Pregnancystate: "",
      Admission: "",
      SpecimenStatic: [
        { id: "Nasal Swab" },
        { id: "Nasopharyngeal Swab" },
        { id: "Throat Swab" },
      ],
      FirstTest:
        JSON.parse(localStorage.getItem("labdetails")).id === 14 ||
        JSON.parse(localStorage.getItem("labdetails")).id === 15 ||
        JSON.parse(localStorage.getItem("labdetails")).id === 31
          ? "U"
          : "",
      PatientId: "",
      checked: false,
      getTesttype: [],
      Shortness: false,
      Cough: false,
      Chills: false,
      Fever: false,
      Headache: false,
      breathing: false,
      muscle: false,
      Congestion: false,
      Fatigue: false,
      Diarrhea: false,
      Vomiting: false,
      Throat: false,
      Nausea: false,
      taste: false,

      saveFlag: false,
      arr: [],
      labelUrl: "",
      barcodeToken: "",
      barcodeBase64: "",
      errorDate: false,
      TesterrorDate: false,
      samplecollerrorDate: false,
      SympomaticerrorDate: false,
      categories: {
        "Shortness of breath": false,
        Cough: false,
        Chills: false,
        "Fever>100.4": false,
        Headache: false,
        "Difficulty breathing": false,
        "Body and or muscle aches": false,
        "Congestion and or runny nose": false,
        Fatigue: false,
        Diarrhea: false,
        Vomiting: false,
        "Sore Throat": false,
        Nausea: false,
        "Loss of taste": false,
      },
    };
  }

  componentDidMount() {
    // get Test type
    window.scrollTo(0, 0);
    if (this.props.location.state) {
      this.setState({
        sampleId: this.props.location.state,
        toogleview: true,
        toogleSearch: false,
      });
      this.Viewdetails(this.props.location.state);
    }
    if (this.props.location.backstate != null) {
      this.setState({
        isLoaded: true,
        searchFirst: this.props.location.backstate.checkfname,
        searchLast: this.props.location.backstate.checklname,
        searchMobile: this.props.location.backstate.checkmobile,
        dob: this.props.location.backstate.checkdob,
      });
      let item = {
        firstName: this.props.location.backstate.checkfname,
        lastName: this.props.location.backstate.checklname,
        dob:
          this.props.location.backstate.checkdob != ""
            ? moment(this.props.location.backstate.checkdob).format(
                "yyyy-MM-DD"
              )
            : this.props.location.backstate.checkdob,
        mobileNumber: this.props.location.backstate.checkmobile,
      };
      api.SearchpatientAPI(item, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            ListPatient: response.info,
            toogleSearch: false,
            toogleTable: true,
            isLoaded: false,
          });
        } else {
          toast.error(response.errorMessage);
          this.setState({ isLoaded: false });
        }
      });
    }
  }
  componentWillReceiveProps(prevProps) {
    if (prevProps) {
      if (prevProps.routestate == false) {
        this.setState({
          isLoaded: false,
          toogleSearch: true,
          toogleTable: false,
          toogleview: false,
          saveSuccess: false,
          searchFirst: "",
          searchLast: "",
          searchMobile: "",
          dob: null,
          TestOrderdate: new Date(),
          SympomaticDate: null,
          SampleCollectionDate: new Date(),
          raceNames: JSON.parse(localStorage.getItem("testTypes"))
            ? JSON.parse(localStorage.getItem("testTypes"))
            : [],
          race:
            JSON.parse(localStorage.getItem("labdetails")).id === 79
              ? "Covid-19: BinaxNow"
              : "",
          specimen: "",
          gendercheck: "",
          sampleId: "",
          sampleIDAPI: "",
          AltersampleId: "",
          Sympomatic: "",
          Hospitalized:
            JSON.parse(localStorage.getItem("labdetails")).id === 14 ||
            JSON.parse(localStorage.getItem("labdetails")).id === 15 ||
            JSON.parse(localStorage.getItem("labdetails")).id === 31
              ? "N"
              : "N",
          Pregnancystate: "",
          Admission: "",
          FirstTest:
            JSON.parse(localStorage.getItem("labdetails")).id === 14 ||
            JSON.parse(localStorage.getItem("labdetails")).id === 15 ||
            JSON.parse(localStorage.getItem("labdetails")).id === 31
              ? "U"
              : "",
          PatientId: "",
          checked: false,
          getTesttype: [],
          Shortness: false,
          Cough: false,
          Chills: false,
          Fever: false,
          Headache: false,
          Fatigue: false,
          Diarrhea: false,
          breathing: false,
          muscle: false,
          Congestion: false,
          Vomiting: false,
          Throat: false,
          Nausea: false,
          taste: false,
          saveFlag: false,
          arr: [],
          labelUrl: "",
          errorDate: false,
          TesterrorDate: false,
          samplecollerrorDate: false,
          SympomaticerrorDate: false,
          categories: {
            "Shortness of breath": false,
            Cough: false,
            Chills: false,
            "Fever>100.4": false,
            Headache: false,
            "Difficulty breathing": false,
            "Body and or muscle aches": false,
            "Congestion and or runny nose": false,
            Fatigue: false,
            Diarrhea: false,
            Vomiting: false,
            "Sore Throat": false,
            Nausea: false,
            "Loss of taste": false,
          },
        });
      }
    } else {
    }
  }
  SearchFun() {
    this.setState({
      toogleTable: true,
      toogleSearch: false,
      toogleview: false,
    });
  }
  StateChangeRace(event) {
    this.setState({
      race: event.target.value.replaceAll('"',"'"),
      specimen:
        event.target.value == "Covid-19: BinaxNow"
          ? "Nasal Swab"
          : event.target.value == "Sienna-Clarity COVID-19 Antigen Rapid Test Cassette"
          ? "Nasopharyngeal swab"
          : event.target.value == "Primerdesign COVID-19 genesig RT-PCR assay"
          ? "Oropharyngeal Swab"
          : event.target.value == "Sofia SARS Antigen FIA"
          ? "Direct Anterior Nasal Swab"
          : event.target.value == "Insti HIV-1/HIV-2 Antibody Test"
          ? "Fingerstick blood"
          : event.target.value == "Abbott Determine HIV–1/2 Ag/Ab Combo"
          ? "Fingerstick blood"
          : "",
      SpecimenStatic:
        event.target.value == "Innova Rapid Antigen Test"
          ? [{ id: "Nasal Swab" }, { id: "Throat Swab" }]
          : event.target.value == "Insti HIV-1/HIV-2 Antibody Test"
          ? [{ id: "Fingerstick blood" }]
          : event.target.value == "Abbott Determine HIV–1/2 Ag/Ab Combo"
          ? [{ id: "Fingerstick blood" }]
          : event.target.value == "Sofia SARS Antigen FIA"
          ? [{ id: "Direct Anterior Nasal Swab" },]
          : event.target.value === "Sienna-Clarity COVID-19 Antigen Rapid Test Cassette"
          ? [{ id: "Nasopharyngeal swab" }]
          : event.target.value === "Primerdesign COVID-19 genesig RT-PCR assay"
          ? [{ id: "Oropharyngeal Swab" }]
          : event.target.value === "RIDA GENE SARS-CoV-2 RT-PCR test"
          ? [ { id: "Nasal Swab" }, { id: "Throat Swab" },]
          : event.target.value === "Flowflex SARS-CoV-2 Antigen Rapid Test"
          ? [ { id: "Anterior Nasal Swab" }]
          : [
              { id: "Nasal Swab" },
              { id: "Nasopharyngeal Swab" },
              { id: "Throat Swab" },
            ],
    });
  }
  specimenChange(event) {
    this.setState({ specimen: event.target.value.replaceAll('"',"'") });
  }
  Viewdetails(patientId) {
    let data = {
      patientId: patientId,
    };
    this.setState({ isLoaded: true });
    api.ColectPatientId(data, (response) => {
      localStorage.setItem("checkmenu", false);
      this.setState({
        PatientId: patientId,
        sampleId: response.info.sampleId.slice(0, 10),
        sampleIDAPI: response.info.sampleId,
        gendercheck: response.info.gender,
        toogleview: true,
        toogleSearch: false,
        toogleTable: false,
        specimen: "",
      });
      this.setState({ labelUrl: response.preSignedUrl });
      this.setState({ isLoaded: false });
    });
  }
  SaveRecord() {
    const favColors = Object.keys(this.state.categories).filter(
      (key) => this.state.categories[key]
    );

    if (
      this.state.TesterrorDate == true ||
      this.state.TestOrderdate.getFullYear() <= "1899" ||
      this.state.TestOrderdate == "Invalid date"
    ) {
      toast.error("Please enter valid Test order date");
      this.setState({
        toogleTable: false,
      });
    } else if (
      this.state.samplecollerrorDate == true ||
      this.state.SampleCollectionDate.getFullYear() <= "1899" ||
      this.state.SampleCollectionDate == "Invalid date"
    ) {
      toast.error("Please enter valid Sample Collection Date");
      this.setState({
        toogleTable: false,
      });
    } else if (
      this.state.Sympomatic === "" &&
      this.state.race !== "Insti HIV-1/HIV-2 Antibody Test"
    ) {
      toast.error("Please select Sympomatic");
      this.setState({
        toogleTable: false,
      });
    } else if (this.state.Sympomatic === "Y") {
      if (this.state.SympomaticDate === null) {
        toast.error("Please select Sympomatic date");
        this.setState({
          toogleTable: false,
        });
      } else if (
        this.state.SympomaticerrorDate == true ||
        this.state.SympomaticDate.getFullYear() <= "1899" ||
        this.state.SympomaticDate == "Invalid date"
      ) {
        toast.error("Please enter valid Sympomatic date");
        this.setState({
          toogleTable: false,
        });
      } else if (favColors === "") {
        toast.error("Please select Symptoms");
        this.setState({
          toogleTable: false,
        });
      } else if (this.state.Hospitalized === "Y") {
        if (this.state.Admission == "") {
          toast.error("Please select ICU admission");
          this.setState({
            toogleTable: false,
          });
        }
        if (this.state.specimen == "") {
          toast.error(
            "Please collect the sample and select specimen type to continue."
          );
          this.setState({
            toogleTable: false,
          });
        }
      } else if (this.state.specimen == "") {
        toast.error(
          "Please collect the sample and select specimen type to continue."
        );
        this.setState({
          toogleTable: false,
        });
      }
    } else if (this.state.Hospitalized === "Y") {
      if (this.state.Admission == "") {
        toast.error("Please select ICU admission");
        this.setState({
          toogleTable: false,
        });
      } else if (this.state.specimen == "") {
        toast.error(
          "Please collect the sample and select specimen type to continue."
        );
        this.setState({
          toogleTable: false,
        });
      }
    } else if (this.state.specimen == "") {
      toast.error(
        "Please collect the sample and select specimen type to continue."
      );
      this.setState({
        toogleTable: false,
      });
    }

    if (this.state.Sympomatic != "" && this.state.specimen != "") {
      if (this.state.Sympomatic == "Y") {
        if (this.state.SympomaticDate != null) {
          this.ValidationcheckAPi();
        }
      } else if (this.state.Hospitalized == "Y") {
        if (this.state.Admission != "") {
          this.ValidationcheckAPi();
        } else {
          return 0;
        }
      } else {
        this.ValidationcheckAPi();
      }
    } else if (
      this.state.Sympomatic === "" &&
      this.state.race === "Insti HIV-1/HIV-2 Antibody Test"
    ) {
      this.ValidationcheckAPi();
    }
  }

  ValidationcheckAPi() {
    const favColors = Object.keys(this.state.categories).filter(
      (key) => this.state.categories[key]
    );
    this.setState({ isLoaded: true });
    let data = {
      patientId: this.state.PatientId,
      testType: this.state.race,
      alternateSampleId: this.state.AltersampleId.trim().replaceAll("''", "'"),
      testOrderDate: moment(this.state.TestOrderdate).format("yyyy-MM-DD"),
      sampleCollectedDate: moment(this.state.SampleCollectionDate).format(
        "yyyy-MM-DD"
      ),
      sampleId: this.state.sampleIDAPI.trim(),
      firstTest: this.state.FirstTest,
      symptomatic: this.state.Sympomatic ? this.state.Sympomatic : null,
      hospitalized: this.state.Hospitalized,
      pregnant: this.state.Pregnancystate,
      icu: this.state.Admission,
      symptomaticDate:
        this.state.SympomaticDate != null
          ? moment(this.state.SympomaticDate).format("yyyy-MM-DD")
          : this.state.SympomaticDate,
      symptoms: favColors === [""] ? [] : favColors,
      specimenSource:
        this.state.race === "Covid-19: BinaxNow"
          ? "Nasal Swab"
          : this.state.race === "Sienna-Clarity COVID-19 Antigen Rapid Test Cassette"
          ? "Nasopharyngeal swab"
          : this.state.race === "Primerdesign COVID-19 genesig RT-PCR assay"
          ? "Oropharyngeal Swab"
          : this.state.race === "Sofia SARS Antigen FIA"
          ? "Direct Anterior Nasal Swab"
          : this.state.race === "Insti HIV-1/HIV-2 Antibody Test"
          ? "Fingerstick blood"
          : this.state.race === "Abbott Determine HIV–1/2 Ag/Ab Combo"
          ? "Fingerstick blood"
          : this.state.specimen,
    };

    api.CollectsamplePrintAPI(data, (response) => {
      if (response.statusCode === 200) {
        localStorage.setItem("checkmenu", true);
        this.setState({
          saveSuccess: true,
        });
        let arr = [];
        arr.push(response.info);
        this.setState({ 
          labelUrl: response.preSignedUrl,
          barcodeToken: response.smallLabelToken,
          barcodeBase64: response.smallLabelBase64,
        });
        this.setState({
          saveFlag: true,
          // TestOrderdate: null,
          // SampleCollectionDate: null,
        });
        this.setState({ arr: arr });
        this.setState({
          isLoaded: false,
        });
      } else {
        toast.error(
          response.errorMessage ? response.errorMessage : response.error
        );
        this.setState({ isLoaded: false });
      }
    });
  }
  // isChecked(event) {
  //   this.setState({
  //     checked: event.target.checked,
  //   });
  // }
  SearchAgain() {
    this.setState({
      toogleSearch: true,
      searchFirst: "",
      searchLast: "",
      searchMobile: "",
      dob: null,
    });
  }

  TestOrderdateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ TesterrorDate: true });
    } else if (date != null) {
      this.setState({ TestOrderdate: date, TesterrorDate: false });
    } else if (date == null) {
      this.setState({ TestOrderdate: null, TesterrorDate: false });
    }
  }
  SampleCollectionDateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ samplecollerrorDate: true });
    } else if (date != null) {
      this.setState({ SampleCollectionDate: date, samplecollerrorDate: false });
    } else if (date == null) {
      this.setState({ SampleCollectionDate: null, samplecollerrorDate: false });
    }
  }
  handleDateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ errorDate: true });
    } else if (date != null) {
      this.setState({ dob: date, errorDate: false });
    } else if (date == null) {
      this.setState({ dob: null, errorDate: false });
    }
  }
  SympomaticDateChange(date) {
    if (date > new Date() || date == "Invalid Date") {
      this.setState({ SympomaticerrorDate: true });
    } else if (date != null) {
      this.setState({ SympomaticDate: date, SympomaticerrorDate: false });
    } else if (date == null) {
      this.setState({ SympomaticDate: null, SympomaticerrorDate: false });
    }
  }
  mobileNumberChange(number) {
    this.setState({ searchMobile: number });
  }

  Searchtable() {
    if (this.state.errorDate == true) {
      toast.error("Please enter valid DOB");
      return 0;
    } else if (
      this.state.dob != null &&
      this.state.dob.getFullYear() <= "1899"
    ) {
      toast.error("Please enter valid DOB");
      return 0;
    } else if (this.state.dob === null && this.state.searchLast === "") {
      toast.error("Please enter either DOB, lastname ");
      this.setState({
        toogleTable: false,
      });
    } else {
      let data = {
        firstName: this.state.searchFirst.trim().replaceAll("''", "'"),
        lastName: this.state.searchLast.trim().replaceAll("''", "'"),
        dob:
          this.state.dob != null
            ? moment(this.state.dob).format("yyyy-MM-DD")
            : this.state.dob,
        mobileNumber:
          this.state.searchMobile === ""
            ? null
            : "+" + this.state.searchMobile.trim(),
      };

      this.setState({
        isLoaded: true,
      });
      api.SearchpatientAPI(data, (response) => {
        if (response.statusCode === 200) {
          this.setState({
            ListPatient: response.info,
            toogleSearch: false,
            toogleTable: true,
            isLoaded: false,
          });
        } else {
          setTimeout(() => {
            toast.error(response.errorMessage);
          }, 500);
          this.setState({
            isLoaded: false,
          });
        }
      });
    }
  }
  FirstTestChange(event) {
    this.setState({
      FirstTest: event.target.value.replaceAll('"',"'"),
    });
  }
  SympomaticChange(event) {
    this.setState({
      Sympomatic: event.target.value.replaceAll('"',"'"),
    });
    if (event.target.value == "N" || event.target.value == "U") {
      this.setState({
        SympomaticDate: null,
        categories: {
          "Shortness of breath": false,
          Cough: false,
          Chills: false,
          "Fever>100.4": false,
          Headache: false,
          "Difficulty breathing": false,
          "Body and or muscle aches": false,
          "Congestion and or runny nose": false,
          Fatigue: false,
          Diarrhea: false,
          Vomiting: false,
          "Sore Throat": false,
          Nausea: false,
          "Loss of taste": false,
        },
      });
    }
  }
  HospitalizedChange(event) {
    this.setState({
      Hospitalized: event.target.value.replaceAll('"',"'"),
    });
  }
  PregnancyChange(event) {
    this.setState({
      Pregnancystate: event.target.value,
    });
  }
  AdmissionChange(event) {
    this.setState({
      Admission: event.target.value,
    });
  }
  Opendetails(patientId) {
    this.setState({
      isLoaded: true,
    });
    let data = {
      patientId: patientId,
    };
    api.Getpatientdetails(data, (response) => {
      if (response.statusCode == "200") {
        let checkdata = {
          checkfname: this.state.searchFirst ? this.state.searchFirst : "",
          checklname: this.state.searchLast ? this.state.searchLast : "",
          checkmobile: this.state.searchMobile ? this.state.searchMobile : "",
          checkdob: this.state.dob ? this.state.dob : "",
        };
        let resposneCheck = response.info;
        var attributes = Object.keys(checkdata);

        for (var i = 0; i < attributes.length; i++) {
          resposneCheck[attributes[i]] = checkdata[attributes[i]];
        }
        this.props.history.push({
          pathname: "/details",
          state: resposneCheck,
        });
        localStorage.setItem("fromvalue", "collectSample");
      } else {
      }
    });
  }

  onChangeShortness = (event) => {
    const { name, checked } = event.target;

    this.setState((prevState) => {
      const categories = prevState.categories;
      categories[name] = checked;
      return categories;
    });
  };

  onChangeCough = () => {
    this.setState((initialState) => ({
      Cough: !initialState.Cough,
    }));
  };

  onChangeChills = () => {
    this.setState((initialState) => ({
      Chills: !initialState.Chills,
    }));
  };
  onChangeFever = () => {
    this.setState((initialState) => ({
      Fever: !initialState.Fever,
    }));
  };
  onChangeHeadache = () => {
    this.setState((initialState) => ({
      Headache: !initialState.Headache,
    }));
  };
  onChangeFatigue = () => {
    this.setState((initialState) => ({
      Fatigue: !initialState.Fatigue,
    }));
  };
  onChangeDiarrhea = () => {
    this.setState((initialState) => ({
      Diarrhea: !initialState.Diarrhea,
    }));
  };
  onChangeVomitting = () => {
    this.setState((initialState) => ({
      Vomiting: !initialState.Vomiting,
    }));
  };
  onChangeThroat = () => {
    this.setState((initialState) => ({
      Throat: !initialState.Throat,
    }));
  };

  onChangeNausea = () => {
    this.setState((initialState) => ({
      Nausea: !initialState.Nausea,
    }));
  };
  onChangetaste = () => {
    this.setState((initialState) => ({
      taste: !initialState.taste,
    }));
  };
  OpenPDF() {
    window.open("/pdf", "_blank");
    localStorage.setItem("pdf", this.state.labelUrl);
    // this.setState({
    //   openPDFstate: true,
    // });
  }
  OpenBarcodePDF() {
    // window.open("/pdf", "_blank");
    // localStorage.setItem("pdf", this.state.barcodeToken);
    // this.setState({
    //   openPDFstate: true,
    // });
    printJS({printable: this.state.barcodeBase64, type: 'pdf', base64: true});
  }
  RecordResult() {
    this.props.history.push({
      pathname: "/recordresult",
      state: this.state.arr,
    });
  }
  handleClose(e) {
    this.setState({
      openPDFstate: false,
    });
  }
  backPage() {
    if (localStorage.getItem("redirect") == "true") {
      this.props.history.push({
        pathname: "/patientsearch",
        backstate: this.props.location.redirect,
      });
    } else {
      this.Searchtable();
      console.log("Backz")
      this.setState({AltersampleId:"",FirstTest:"",Sympomatic:""})
      this.setState({
        toogleSearch: true,
        race: "",
        checked: false,
        saveFlag: false,
        specimen: "",
        saveSuccess: false,
      });
    }
  }
  EnterKeySearch(evt) {
    if (evt.keyCode == 13) {
      this.Searchtable();
    }
  }
  EnterKeySave(evt) {
    if (evt.keyCode == 13) {
      this.SaveRecord();
    }
  }

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|2|3|4|5)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    } else {
      return phoneNumberString;
    }
    // return null;
  }

  render() {
    // Modal styles----------------------------------------------------------------------------------------------
    const customStylesDelete = {
      content: {
        top: "40%",
        height: "24%",
        backgroundColor: "white",
        border: "none",
        outline: "none",
        width: "90%",
        marginLeft: "5%",
      },
    };
    // --------------------------------------------------------------------------------------------------------

    return (
      <>
        {/* Loader */}
        <ToastContainer autoClose={2000} />
        {/* PDF modal */}
        {this.state.openPDFstate == true ? (
          <Modal
            isOpen={this.state.openPDFstate}
            style={customStylesDelete}
            contentLabel="Example Modal"
            className="shedule"
          >
            <div
              className="container-fluid"
              style={{
                backgroundColor: "#6fe2e9",
                padding: "2%",
                marginTop: "5%",
                borderRadius: "10px",
                boxShadow:
                  "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
              }}
            >
              <div
                // className="close"
                onClick={this.handleClose.bind(this)}
                className="pdfclose"
              >
                <img src={Closeicon} className="closeicon" />
              </div>
              <div className="Pdfhead">COVID - 19 Lab Request Form</div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                <div className="pdfbody">
                  <Viewer zoom={1} fileUrl={this.state.labelUrl} />
                </div>
              </Worker>
            </div>
          </Modal>
        ) : (
          ""
        )}
        {/* Loader state */}
        {this.state.isLoaded === true ? (
          <div className="loaderAdj">
            <CircularProgress />
          </div>
        ) : (
          // Search patient div
          <div className="collectContainer">
            <div className="searcher adjSearchMargin">
              {this.state.toogleTable === true ||
              this.state.toogleSearch === true ? (
                <div>
                  <h1> Search for Patient</h1>
                </div>
              ) : (
                <div></div>
              )}

              {this.state.toogleTable === true ? (
                <div>
                  <button
                    className="searchAgain"
                    onClick={this.SearchAgain.bind(this)}
                  >
                    Search Again
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            {this.state.toogleSearch === true ? (
              <div className="">
                <div className="DisplayIte">
                  <div
                    className="dater datepickerstyle1"
                    style={{ marginTop: "18px" }}
                  >
                    <label className="ethnicit">
                      Date of Birth (MM/DD/YYYY)
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        clearable
                        error={true}
                        value={this.state.dob}
                        onChange={(date) => this.handleDateChange(date)}
                        label=""
                        maxDate={new Date()}
                        placeholder="MM/DD/YYYY"
                        format="MM/dd/yyyy"
                        required
                        autoOk
                        onKeyUp={this.EnterKeySearch.bind(this)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div style={{ marginTop: "20px", width: "50%" }}>
                    <label className="ethnicit">Phone number</label>

                    <PhoneInput
                      country={"us"}
                      // onlyCountries={["us"]}
                      // disableDropdown={true}
                      onChange={this.mobileNumberChange.bind(this)}
                      value={this.state.searchMobile}
                      onKeyUp={this.EnterKeySearch.bind(this)}
                      inputProps={{
                        autoFormat: true,
                        countryCodeEditable: true,
                      }}
                    />
                  </div>
                </div>

                <div className="DisplayIte">
                  <div className="patient_name">
                    <label className="ethnicit">First Name</label>

                    <input
                      type="text"
                      style={{ marginTop: "5px" }}
                      value={this.state.searchFirst}
                      onKeyUp={this.EnterKeySearch.bind(this)}
                      onChange={(e) => {
                        let value = e.target.value.replaceAll('"', "'")
                        this.setState({ searchFirst: value.replaceAll("''","'") });
                      }}
                    />
                  </div>

                  <div className="patient_lname">
                    <label className="ethnicit">Last Name</label>
                    <input
                      type="text"
                      value={this.state.searchLast}
                      style={{ marginTop: "5px" }}
                      onKeyUp={this.EnterKeySearch.bind(this)}
                      onChange={(e) => {
                        let value = e.target.value.replaceAll('"', "'")
                        this.setState({ searchLast: value.replaceAll("''","'") });
                      }}
                    />
                  </div>
                </div>
                <div className="btnFix">
                  <button
                    className="subBtn1"
                    onClick={this.Searchtable.bind(this)}
                  >
                    Search
                  </button>
                </div>
              </div>
            ) : // Patient Table list--------------------------------------------------------------------------

            this.state.toogleTable === true && this.state.ListPatient ? (
              <div>
                {this.state.ListPatient.map((item) => (
                  <div className="searchContainer">
                    <div className="data">
                      <div className="firstRow">
                        <div>
                          <div>Name:</div>
                          <div
                            style={{ marginLeft: "-25px" }}
                            title={
                              item.firstName.length < 16
                                ? ""
                                : item.firstName + item.lastName
                            }
                          >
                            {item.firstName.length < 16
                              ? item.firstName
                              : item.firstName.slice(0, 16) + "...."}{" "}
                            {item.firstName.length < 16 ? item.lastName : ""}
                          </div>
                        </div>

                        <div>
                          <div>Email:</div>
                          <div style={{ marginLeft: "-28px" }}>
                            {item.email}
                          </div>
                        </div>

                        <div>
                          <div>Phone No:</div>
                          <div style={{ marginLeft: "0px" }}>
                            {formatPhoneNumberIntl(item.mobileNumber)}
                          </div>
                        </div>

                        <div>
                          <span
                            className="viewP"
                            onClick={this.Opendetails.bind(
                              this,
                              item.patientId
                            )}
                          >
                            View Patient
                          </span>
                        </div>
                      </div>

                      <div className="secondRow">
                        <div>
                          <div>DOB:</div>
                          <div style={{ marginLeft: "-25px" }}>
                            {" "}
                            {moment(item.dob).format("MM/DD/yyyy")}
                          </div>
                        </div>

                        {item.confirmationCode != null ? (
                          <div className="confcodelist">
                            <div>Confirmation code:</div>
                            <div>{item.confirmationCode} </div>
                          </div>
                        ) : (
                          <div></div>
                        )}

                        <div className="collectSample">
                          <div
                            onClick={this.Viewdetails.bind(
                              this,
                              item.patientId
                            )}
                          >
                            Collect Sample
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : this.state.toogleview === true ? (
              // Collect sample form----------------------------------------------------------------------------------------------
              <div>
                <div>
                  <h1> Collect Sample</h1>
                  <div
                    className="collectbtn"
                    onClick={this.backPage.bind(this)}
                  >
                    {" "}
                    Back
                  </div>
                </div>
                <div className="addInput">
                  <div className="Collcetlists">
                    <label className="ethnicit">Test Type *</label>

                    <select
                      className="PatientSelect"
                      onChange={this.StateChangeRace.bind(this)}
                      value={this.state.race}
                    >
                      <option value="" disabled>
                        Please select test type{" "}
                      </option>
                      {this.state.raceNames.length > 0 ? (
                        this.state.raceNames.map((index) => (
                          <option value={index.testName}>
                            {index.testName}
                          </option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                  <div className="Collcetlists">
                    <label className="ethnicit">Sample ID *</label>

                    <input
                      style={{ paddingTop: "32px" }}
                      type="text"
                      className="PatientInput"
                      disabled
                      value={this.state.sampleId}
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"', "'")
                        this.setState({ sampleId: value.replaceAll("''","'") })
                      }}
                      onKeyUp={this.EnterKeySave.bind(this)}
                    />
                  </div>
                </div>
                <div className="addInput">
                  <div className="Collcetlists">
                    <label className="ethnicit">Alternative Sample ID</label>

                    <input
                      type="text"
                      className="PatientInput"
                      onKeyUp={this.EnterKeySave.bind(this)}
                      value={this.state.AltersampleId}
                      onChange={(e) =>{
                        let value = e.target.value.replaceAll('"', "'")
                        this.setState({ AltersampleId: value.replaceAll("''","'") })
                      }}
                    />
                  </div>
                  <div className="Collcetlists">
                    <label className="ethnicit">
                      Test Order Date (MM/DD/YYYY)
                    </label>

                    <div className="dater">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          placeholder="MM/DD/YYYY"
                          value={this.state.TestOrderdate}
                          onChange={(date) => this.TestOrderdateChange(date)}
                          format="MM/dd/yyyy"
                          maxDate={new Date()}
                          required
                          onKeyUp={this.EnterKeySave.bind(this)}
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>

                <div className="addInput">
                  <div className="Collcetlists">
                    <label className="ethnicit">
                      Sample Collection Date (MM/DD/YYYY)
                    </label>

                    <div className="dater">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          placeholder="MM/DD/YYYY"
                          value={this.state.SampleCollectionDate}
                          onChange={(date) =>
                            this.SampleCollectionDateChange(date)
                          }
                          format="MM/dd/yyyy"
                          maxDate={new Date()}
                          required
                          onKeyUp={this.EnterKeySave.bind(this)}
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <div className="Collcetlists">
                    <label className="ethnicit">First Test? *</label>
                    <br />
                    <div className="checkfirst">
                      <input
                        name="FirstTest"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        type="radio"
                        value="Y"
                        onChange={this.FirstTestChange.bind(this)}
                        checked={this.state.FirstTest == "Y" ? true : false}
                        id="Yes"
                      />{" "}
                      <label for="Yes">Yes</label>
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="N"
                        name="FirstTest"
                        className="checkAlign"
                        onChange={this.FirstTestChange.bind(this)}
                        checked={this.state.FirstTest == "N" ? true : false}
                        id="No"
                      />{" "}
                      <label for="No">No</label>
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="U"
                        name="FirstTest"
                        className="checkAlign"
                        onChange={this.FirstTestChange.bind(this)}
                        checked={this.state.FirstTest == "U" ? true : false}
                        id="Unknown"
                      />{" "}
                      <label for="Unknown">Unknown</label>
                    </div>
                  </div>
                </div>

                <div className="addInput">
                  {this.state.race !== "Insti HIV-1/HIV-2 Antibody Test" ? (
                    <div className="Collcetlists">
                      <label className="ethnicit">Symptomatic? *</label>
                      <br />
                      <div className="checkfirst">
                        <input
                          type="radio"
                          value="Y"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="Sympomatic"
                          onChange={this.SympomaticChange.bind(this)}
                          checked={this.state.Sympomatic == "Y" ? true : false}
                          id="Yes1"
                        />{" "}
                        <label for="Yes1">Yes</label>
                        <input
                          type="radio"
                          value="N"
                          name="Sympomatic"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          className="checkAlign"
                          onChange={this.SympomaticChange.bind(this)}
                          checked={this.state.Sympomatic == "N" ? true : false}
                          id="No1"
                        />{" "}
                        <label for="No1">No</label>
                        <input
                          type="radio"
                          value="U"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="Sympomatic"
                          className="checkAlign"
                          onChange={this.SympomaticChange.bind(this)}
                          checked={this.state.Sympomatic == "U" ? true : false}
                          id="Unknown1"
                        />{" "}
                        <label for="Unknown1">Unknown</label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="Collcetlists">
                    <label className="ethnicit">Hospitalized? *</label>
                    <br />
                    <div className="checkfirst">
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="Y"
                        name="Hospitalized"
                        onChange={this.HospitalizedChange.bind(this)}
                        checked={this.state.Hospitalized == "Y" ? true : false}
                        id="Yes2"
                      />{" "}
                      <label for="Yes2">Yes</label>
                      <input
                        type="radio"
                        value="N"
                        className="checkAlign"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        name="Hospitalized"
                        defaultChecked
                        onChange={this.HospitalizedChange.bind(this)}
                        checked={this.state.Hospitalized == "N" ? true : false}
                        id="No2"
                      />{" "}
                      <label for="No2">No</label>
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="U"
                        name="Hospitalized"
                        className="checkAlign"
                        onChange={this.HospitalizedChange.bind(this)}
                        checked={this.state.Hospitalized == "U" ? true : false}
                        id="Unknown2"
                      />{" "}
                      <label for="Unknown2">Unknown</label>
                    </div>
                  </div>
                </div>

                {this.state.Hospitalized === "Y" ? (
                  <div
                    style={{
                      padding: "23px",
                      width: "46%",
                      float: "right",
                      marginTop: "6px",
                    }}
                  >
                    <label className="ethnicit">ICU Admission</label>
                    <br />
                    <div className="checkfirst">
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="Y"
                        name="AdmissionChange"
                        onChange={this.AdmissionChange.bind(this)}
                        checked={this.state.Admission == "Y" ? true : false}
                        id="yes"
                      />{" "}
                      <label for="yes"> Yes</label>
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="N"
                        className="checkAlign"
                        name="AdmissionChange"
                        onChange={this.AdmissionChange.bind(this)}
                        checked={this.state.Admission == "N" ? true : false}
                        id="no"
                      />{" "}
                      <label for="no">No</label>
                      <input
                        type="radio"
                        onKeyUp={this.EnterKeySave.bind(this)}
                        value="U"
                        className="checkAlign"
                        name="AdmissionChange"
                        onChange={this.AdmissionChange.bind(this)}
                        checked={this.state.Admission == "U" ? true : false}
                        id="ukn"
                      />{" "}
                      <label for="ukn">Unknown</label>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {this.state.Sympomatic === "Y" ? (
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "20px",
                      marginTop: "2%",
                    }}
                  >
                    <label className="ethnicit">
                      Symptomatic Date (MM/DD/YYYY)?*
                    </label>
                    <div className="dater" style={{ paddingLeft: "10px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          placeholder="MM/DD/YYYY"
                          value={this.state.SympomaticDate}
                          onChange={(date) => this.SympomaticDateChange(date)}
                          format="MM/dd/yyyy"
                          maxDate={new Date()}
                          required
                          onKeyUp={this.EnterKeySave.bind(this)}
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                      <label className="ethnicit">
                        Have you been experiencing any Covid-19 symptoms?
                      </label>
                      <div
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          marginTop: "15px",
                          width: "119%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <label>
                              <input
                                checked={this.state.categories.Shortness}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                onKeyUp={this.EnterKeySave.bind(this)}
                                name="Shortness of breath"
                              />
                              Shortness of breath
                            </label>
                          </div>

                          <div>
                            <label>
                              <input
                                checked={this.state.categories.Cough}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                onKeyUp={this.EnterKeySave.bind(this)}
                                name="Cough"
                              />
                              Cough
                            </label>
                          </div>

                          <div style={{ marginRight: "165px" }}>
                            <label>
                              <input
                                checked={this.state.categories.Chills}
                                onKeyUp={this.EnterKeySave.bind(this)}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Chills"
                              />
                              Chills
                            </label>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <label>
                              <input
                                checked={this.state.categories.breathing}
                                onKeyUp={this.EnterKeySave.bind(this)}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Difficulty breathing"
                              />
                              Difficulty breathing
                            </label>
                          </div>

                          <div style={{ marginLeft: "56px" }}>
                            <label>
                              <input
                                checked={this.state.categories.Fever}
                                onKeyUp={this.EnterKeySave.bind(this)}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name=" Fever>100.4"
                              />
                              Fever {">"} 100.4
                            </label>
                          </div>
                          <div style={{ marginRight: "132px" }}>
                            <label>
                              <input
                                checked={this.state.categories.Headache}
                                onChange={this.onChangeShortness}
                                onKeyUp={this.EnterKeySave.bind(this)}
                                type="checkbox"
                                name="Headache"
                              />
                              Headache
                            </label>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <label>
                              <input
                                onKeyUp={this.EnterKeySave.bind(this)}
                                checked={this.state.categories.Fatigue}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Fatigue"
                              />
                              Fatigue
                            </label>
                          </div>

                          <div style={{ marginLeft: "103px" }}>
                            <label>
                              <input
                                checked={this.state.categories.Diarrhea}
                                onKeyUp={this.EnterKeySave.bind(this)}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Diarrhea"
                              />
                              Diarrhea
                            </label>
                          </div>
                          <div style={{ marginRight: "134px" }}>
                            <label>
                              <input
                                onKeyUp={this.EnterKeySave.bind(this)}
                                checked={this.state.categories.Vomiting}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Vomiting"
                              />
                              Vomiting
                            </label>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <label>
                              <input
                                onKeyUp={this.EnterKeySave.bind(this)}
                                checked={this.state.categories.Throat}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Sore Throat"
                              />
                              Sore Throat
                            </label>
                          </div>
                          <div style={{ marginLeft: "64px" }}>
                            <label>
                              <input
                                onKeyUp={this.EnterKeySave.bind(this)}
                                checked={this.state.categories.Nausea}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Nausea"
                              />
                              Nausea
                            </label>
                          </div>
                          <div style={{ marginRight: "9px" }}>
                            <label>
                              <input
                                onKeyUp={this.EnterKeySave.bind(this)}
                                checked={this.state.categories.taste}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Loss of taste"
                              />
                              Loss of taste and / or smell
                            </label>
                          </div>
                        </div>

                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <div>
                            <label>
                              <input
                                checked={this.state.categories.muscle}
                                onChange={this.onChangeShortness}
                                onKeyUp={this.EnterKeySave.bind(this)}
                                type="checkbox"
                                name="Body and or muscle aches"
                              />
                              Body and / or muscle aches
                            </label>
                          </div>
                          <div style={{ marginLeft: "8px" }}>
                            <label>
                              <input
                                onKeyUp={this.EnterKeySave.bind(this)}
                                checked={this.state.categories.Congestion}
                                onChange={this.onChangeShortness}
                                type="checkbox"
                                name="Congestion and or runny nose"
                              />
                              Congestion and / or runny nose
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {this.state.gendercheck == "Female" ? (
                  <div className="addInput">
                    <div className="Collcetlists">
                      <label className="ethnicit">Pregnancy Status?</label>
                      <br />
                      <div className="checkfirst">
                        <input
                          type="radio"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          value="Y"
                          name="Pregnancystate"
                          onChange={this.PregnancyChange.bind(this)}
                          checked={
                            this.state.Pregnancystate == "Y" ? true : false
                          }
                          id="yesp"
                        />{" "}
                        <label for="yesp">Yes</label>
                        <input
                          type="radio"
                          value="N"
                          className="checkAlign"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          name="Pregnancystate"
                          defaultChecked
                          onChange={this.PregnancyChange.bind(this)}
                          checked={
                            this.state.Pregnancystate == "N" ? true : false
                          }
                          id="nop"
                        />{" "}
                        <label for="nop">No</label>
                        <input
                          type="radio"
                          onKeyUp={this.EnterKeySave.bind(this)}
                          value="U"
                          name="Pregnancystate"
                          className="checkAlign"
                          onChange={this.PregnancyChange.bind(this)}
                          checked={
                            this.state.Pregnancystate == "U" ? true : false
                          }
                          id="unp"
                        />{" "}
                        <label for="unp">Unknown</label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div style={{ margin: "40px 25px" }}>
                  <label className="ethnicit">
                    Please collect the sample and select specimen type to
                    continue.
                  </label>
                </div>

                <div className="addInput" style={{ marginTop: "-35px" }}>
                  <div style={{ width: "100%" }}>
                    <select
                      className="PatientSelect"
                      onChange={this.specimenChange.bind(this)}
                      disabled={
                        this.state.race === "Covid-19: BinaxNow"
                          ? true
                          : this.state.race === ""
                          ? true
                          : false
                      }
                      value={
                        this.state.race === "Covid-19: BinaxNow"
                          ? (this.state.specimen = "Nasal Swab")
                          : this.state.race === "Sofia SARS Antigen FIA"
                          ? (this.state.specimen = "Direct Anterior Nasal Swab")
                          : this.state.race ===
                            "Insti HIV-1/HIV-2 Antibody Test"
                          ? (this.state.specimen = "Fingerstick blood")
                          : this.state.race ===
                            "Abbott Determine HIV–1/2 Ag/Ab Combo"
                          ? (this.state.specimen = "Fingerstick blood")
                          : this.state.race ===
                            "Sienna-Clarity COVID-19 Antigen Rapid Test Cassette"
                          ? (this.state.specimen = "Nasopharyngeal swab")
                          : this.state.race ===
                            "Primerdesign COVID-19 genesig RT-PCR assay"
                          ? (this.state.specimen = "Oropharyngeal Swab")
                          : this.state.race ===
                            "Flowflex SARS-CoV-2 Antigen Rapid Test"
                          ? (this.state.specimen =  "Anterior Nasal Swab" )
                          : this.state.specimen
                      }
                      style={{ width: "96%" }}
                    >
                      <option hidden disabled="disabled" value=""></option>

                      {this.state.SpecimenStatic.length > 0 ? (
                        this.state.SpecimenStatic.map((index) => (
                          <option value={index.id}>{index.id}</option>
                        ))
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                </div>

                <div>
                  <div className="CollectionLabel"></div>
                </div>

                {/* <div className="checkinsurance">
                      <label>
                        Sample Collection Complete*
                    <input
                          type="checkbox"
                          onChange={this.isChecked.bind(this)}
                          onKeyUp={this.EnterKeySave.bind(this)}
                          checked={this.state.checked}
                        />
                      </label>
                    </div> */}
                {/* Success message */}
                {this.state.saveSuccess === true ? (
                  <div className="sampleSuccessDiv">
                    <label className="sampleSuccess">
                      Sample collected successfully
                    </label>
                  </div>
                ) : (
                  <div></div>
                )}
                {/* PDF label and save button */}
                <div style={{ textAlign: "center" }}>
                  {this.state.saveFlag == false ? (
                    <div style={{ display: "flex", flexDirection:"row", justifyContent: "center" }}>
                      <div style={{ flex: 1}}>
                        <button
                          className="NextBtn"
                          style={{
                            opacity: "0.5",
                            padding: "9px",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <img src={Lableimg} className="Lableimg" />
                            </div>
                            <div style={{ margin: "2px 0px 0px 12px" }}>
                              Test Request
                            </div>
                          </div>
                        </button>
                      </div>
                      <div style={{ flex: 1}}>
                        <button
                          className="NextBtn"
                          style={{
                            opacity: "0.5",
                            padding: "9px",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <img src={Barcodeimg} className="Lableimg" />
                            </div>
                            <div style={{ margin: "2px 0px 0px 12px" }}>
                              Sample Label
                            </div>
                          </div>
                        </button>
                      </div>
                      <div style={{ flex: 1}}>
                        <button
                          className="NextBtn"
                          onClick={this.SaveRecord.bind(this)}
                          style={{ width: "80%" }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", flexDirection:"row", justifyContent: "center" }}>
                      <div style={{ flex: 1}}>
                        {/* <Link
                            to={{
                              pathname: "/pdf",
                              state: this.state.labelUrl
                            }}
                            target="_blank"
                          >Label
                          </Link> */}

                        <button
                          className="NextBtn"
                          style={{ width: "80%" }}
                          onClick={this.OpenPDF.bind(this)}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <img src={Lableimg} className="Lableimg" />
                            </div>
                            <div style={{ margin: "2px 0px 0px 12px" }}>
                              Test Request
                            </div>
                          </div>
                        </button>
                      </div>

                      <div style={{ flex: 1}}>
                        <button
                          className="NextBtn"
                          style={{ width: "80%" }}
                          onClick={this.OpenBarcodePDF.bind(this)}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <img src={Barcodeimg} className="Lableimg" />
                            </div>
                            <div style={{ margin: "2px 0px 0px 12px" }}>
                              Sample Label
                            </div>
                          </div>
                        </button>
                      </div>

                      <div style={{ flex: 1}}>
                        <button
                          className="NextBtn"
                          onClick={this.RecordResult.bind(this)}
                          style={{ width: "80%" }}
                        >
                          Record Result
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </>
    );
  }
}

export default index;
