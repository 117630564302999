import React, { Component } from "react";
import Viewer, { SpecialZoomLevel, Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import {DOWNLOAD_PDF} from "../Service/API-URL/index.js";

class Pdfview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setpdfurl:''
        }
    }

    render() {
        let url = localStorage.getItem('pdf');
        if (!url.startsWith("http")) {
            url = DOWNLOAD_PDF + '?token=' + encodeURIComponent(url.substring('token://'.length)) + '&dt=' + new Date().getTime();
        } 
    
        return (
            <div className="example-modal">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                    <div className="pdfbody" >
                        <Viewer zoom={1} fileUrl={url} />
                    </div>
                </Worker>
            </div>
        );
    }
}

export default Pdfview;


