import axios from "axios";
import * as API from "../API-URL/index.js";
let testType;
export default {
  getTesttype() {
    return testType;
  },
  setTesttype(testObject) {
    testType = testObject;
  },
  // Login api
  LoginAPI(data, res) {
    axios
      .post(API.LOGIN_URL, data)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  // OTP
  OTPAPI(data, res) {
    axios
      .get(API.OTP_URL + "?otp=" + data.otp + "&loggedInAt=Web")
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // resend OTP
  ResendOTPAPI(data, res) {
    axios
      .post(API.LOGIN_URL, data)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  //post -otp -lab select

  PostOTPAPI(data, res) {
    axios
      .post(API.POST_OTP, data)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // new resend OTP
  newResendOTPAPI(data, res) {
    axios
      .post(
        API.NEW_POST_OTP +
          "?messageId=" +
          data.messageId +
          "&messageCode=" +
          data.messageCode,
        data,
        data
      )
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // Search patient

  SearchpatientAPI(data, res) {
    axios
      .post(API.SEARCH_PATIENT, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  //Add Patient

  AddpatientAPI(data, res) {
    axios
      .post(API.ADD_PATIENT, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // get the country list
  Get_Country_List(res) {
    axios
      .get(
        API.GET_COUNTRY_LIST
        // {
        //     headers: {
        //         'Authorization': localStorage.getItem('accessToken'),
        //         'Content-Type': 'application/json'
        //     }
        // }
      )
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },
  //get the state list based on country id
  Get_State_List(data, res) {
    axios
      .post(API.GET_STATE_LIST + "?countryId=" + data.countryId)
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  Get_StateByCode_List(data, res) {
    axios
      .post(API.GET_STATE_LIST + "?countryCode=" + data.countryCode)
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  //get the city list based on state id
  Get_City_List(data, res) {
    axios
      .post(API.GET_CITY_LIST + "?stateId=" + data.stateId)
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  Get_State_County(res) {
    axios
      .get(API.GET_STATE_COUNTY, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  Get_Patient_Race(res) {
    axios
      .get(API.GET_PATIENT_RACE + "?labId=" + localStorage.getItem("id"), {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  // Collect sample
  CollectsamplePrintAPI(data, res) {
    axios
      .post(API.ADD_SAMPLE_PRINT, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  // collect sample patient id
  ColectPatientId(data, res) {
    axios
      .get(API.COLLECT_PATIENT_ID + "?patientId=" + data.patientId, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  // Record result search
  RecordResultSearchAPI(data, res) {
    axios
      .post(API.RECORD_RESULT_SEARCH, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  RecordUpdateAPI(data, res) {
    if (data.ctValue !== "" && data.workingNumber !=="") {
      axios
        .post(
          API.RECORD_UPDATE +
            "?resultId=" +
            data.resultId +
            "&patientId=" +
            data.patientId +
            "&result=" +
            data.result +
            "&analysisDate=" +
            data.analysisDate +
            "&ctValue=" +
            data.ctValue +
            "&workingNumber=" +
            data.workingNumber,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response) {
            res(response.data);
          }
        })
        .catch((error) => {
          if (error.response.data.errorMessage == "Token expired.") {
            RefreshTokenapi(true);
          }
        });
    }else if (data.ctValue !== "" && data.workingNumber =="") {
      axios
        .post(
          API.RECORD_UPDATE +
            "?resultId=" +
            data.resultId +
            "&patientId=" +
            data.patientId +
            "&result=" +
            data.result +
            "&analysisDate=" +
            data.analysisDate +
            "&ctValue=" +
            data.ctValue,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response) {
            res(response.data);
          }
        })
        .catch((error) => {
          if (error.response.data.errorMessage == "Token expired.") {
            RefreshTokenapi(true);
          }
        });
    }else if (data.ctValue == "" && data.workingNumber !=="") {
      axios
        .post(
          API.RECORD_UPDATE +
            "?resultId=" +
            data.resultId +
            "&patientId=" +
            data.patientId +
            "&result=" +
            data.result +
            "&analysisDate=" +
            data.analysisDate +
            "&workingNumber=" +
            data.workingNumber,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response) {
            res(response.data);
          }
        })
        .catch((error) => {
          if (error.response.data.errorMessage == "Token expired.") {
            RefreshTokenapi(true);
          }
        });
    }
     else {
      axios
        .post(
          API.RECORD_UPDATE +
            "?resultId=" +
            data.resultId +
            "&patientId=" +
            data.patientId +
            "&result=" +
            data.result +
            "&analysisDate=" +
            data.analysisDate,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response) {
            res(response.data);
          }
        })
        .catch((error) => {
          if (error.response.data.errorMessage == "Token expired.") {
            RefreshTokenapi(true);
          }
        });
    }
  },
  //daily update
  GetDailyUpdate(res) {
    axios
      .get(API.GET_DAILY_UPDATE, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  //daily update - Post DailyUpdate

  PostDailyUpdate(data, res) {
    axios
      .post(API.POST_DAILY_UPDATE, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  //daily update - Edit DailyUpdate

  EditDailyUpdate(data, res) {
    axios
      .post(API.EDIT_DAILY_UPDATE, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  getTestRequest(res) {
    axios
      .get(API.GET_TEST_HISTORY, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  REPORTAPI(data, res) {
    axios
      .post(API.REPORT_URL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },
  EXPORTAPI(data, res) {
    axios
      .post(API.EXPORT_URL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },
  REPORTPOSTAPI(data, res) {
    axios
      .post(API.REPORTPOST_URL, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },
  CheckDate(data, res) {
    axios
      .get(API.CHECK_DATE + "?date=" + data.date, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  postResupplyRequest(data, res) {
    axios
      .post(API.POST_TEST_HISTORY, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // Test Records - Confirmation by code
  TestRecordsSamplePrintAPI(data, res) {
    axios
      .post(API.SAVE_TEST_RECORD, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  SearchRecordAPI(data, res) {
    axios
      .post(API.SEARCHAPP_RECORD, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error.response.data);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
  // Test Records - Confirmation by code testResultId id
  //data.patientId- test result Id - done
  TestRecordsTestResultId(data, res) {
    axios
      .get(API.TESTRESULT_ID + "?testResultId=" + data.testResultId, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // Test Records - search - done

  TestRecordsSearchpatientAPI(data, res) {
    axios
      .post(API.SEARCH_CONFIRMATION_PATIENT, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // Shipment details
  GetShipmentData(data, res) {
    axios
      .get(
        API.GET_SHIPMENT +
          "?lotNumber=" +
          data.lotNumber +
          "&pageNo=" +
          data.pageNo,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  GetLotnumber(data, res) {
    axios
      .get(API.GET_SHIPMENT_DETAILS + "?lotNumber=" + data.lotNumber, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  ResendNotification(data, res) {
    axios
      .post(
        API.RESEND_NOTIFICATION + "?resultId=" + data.resultId,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },

  // SMS
  SMSstatus(data, res) {
    axios
      .post(API.NOTIFICATION_STATUS + "?pageNo=" + data.pageNo, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {});
  },
  Getpatientdetails(data, res) {
    axios
      .get(API.GET_PATIENTDETAILS + "?patientId=" + data.patientId, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        // if (error.response.data.errorMessage == "Token expired.") {
        //     RefreshTokenapi(true)
        // }
      });
  },

  SortByAPI(data, res) {
    axios
      .post(API.SORT_BY, data, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response) {
          res(response.data);
        }
      })
      .catch((error) => {
        res(error);
        if (error.response.data.errorMessage == "Token expired.") {
          RefreshTokenapi(true);
        }
      });
  },
};

function RefreshTokenapi(data) {
  if (data == true) {
    axios
      .get(
        API.REFRESH_TOKEN +
          localStorage.getItem("refreshToken") +
          "&loggedInAt=Web",
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response) {
          window.location.reload();
          localStorage.setItem("accessToken", response.data.accessToken);
        }
      })
      .catch((error) => {});
  }
}
